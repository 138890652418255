export default {
  JOB: {
    JOB: "Job",
    JOBSTATUS: "Job Status",
    POSITION: "Position",
    POSITION1: "Enter Your Position In Company",
    EMPTYPE: "Type",
    EMPTYPE1: "Enter Job Type",
    EFFDATE: "Effective Date",
    EFFDATE1: "Enter Effective Date",
    EMPGROUP: "Group",
    EMPGROUP1: "Enter Employee Group",
    PROBATION: "End of Probation",
    REMARK: "Remark",
    REMARK1: "Maximum 2000 Characters",
    EMPSTATUS: "Employment Status",
    STATUS: "Status",
    STATUS1: "Enter Employment Status",
    POSITIONS: "Job Positions",
    CREATE: "Create Job Positions",
    CODE: "Code",
  },
};
