import state from "./states";
import actions from "./actions";
import mutations from "./mutations";

const getters = {
  getStaffDashboardDailyAttendanceList: (state) => state.items,
  getStaffDashboardDailyAttendancePagination: (state) => state.pagination,
  getStaffDashboardDailyAttendanceBusy: (state) => state.isBusy,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
