export default {
  /**
   * Set Staff Monthly Attendance under School's view
   *
   * @param state
   * @param items
   */
  setSchoolStaffMonthlyAttendanceList(state, items) {
    console.log("Monthly Items:", items);
    state.items = items;
  },

  /**
   * Set Staff Pagination
   *
   * @param state
   * @param pagination
   */
  setSchoolStaffMonthlyAttendancePagination(state, pagination) {
    console.log("Pagination", pagination);
    state.pagination = pagination;
  },

  /**
   * Set Staff Pagination current page
   *
   * @param state
   * @param currentPage
   */
  setSchoolStaffMonthlyAttendancePage(state, currentPage) {
    console.log("Current Page", currentPage);
    state.pagination.current_page = currentPage;
  },

  /**
   *
   * @param state
   * @param selectedDate
   */
  setSchoolStaffMonthlyAttendanceDate(state, selectedDate) {
    console.log("Selected date", selectedDate);
    state.filter.date = selectedDate;
  },

  /**
   * Change filters.
   *
   * @param state
   * @param filters
   */
  setSchoolStaffMonthlyAttendanceFilters(state, filters) {
    console.log(filters);
    state.filter = filters;
  },

  /**
   * Set monthly busy
   *
   * @param state
   * @param isBusy
   */
  setSchoolStaffMonthlyAttendanceBusy(state, isBusy) {
    console.log(isBusy);
    state.isBusy = isBusy;
  },

  /**
   *
   * @param state
   * @param type
   */
  setSchoolStaffMonthlyAttendanceDateType(state, type) {
    console.log(type);
    state.date_type = type;
  },
};
