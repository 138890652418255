import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getTeacherIndividualAttendanceList: (state) => state.items,
  getTeacherIndividualAttendancePagination: (state) => state.pagination,
  getTeacherIndividualAttendanceBusy: (state) => state.isBusy,
};

export default {
  getters,
  actions,
  state,
  mutations,
};
