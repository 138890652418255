import ApiService from "@/core/services/api.service";

const actions = {
  /**
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  registerSchoolAwayDetail(context, payload) {
    ApiService.setHeader();
    return ApiService.post("school/away/management", payload).then(
      (response) => response
    );
  },
};

export default {
  actions,
};
