import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getTeacherAdminList: (state) => state.items,
  getTeacherAdminPagination: (state) => state.pagination,
  getTeacherAdminListPage: (state) => state.pagination.current_page,
  getTeacherAdminListFilters: (state) => state.filters,
  getTeacherAdminListRoleFilters: (state) => state.filters.role,
  getTeacherAdminListBusy: (state) => state.isBusy,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
