import ApiService from "@/core/services/api.service";

const state = {
  form: {
    name: "",
    description: "",
    time_in: "",
    cut_off_time_in: "",
    time_out: "",
    cut_off_time_out: "",
  },
  isBusy: false,
};

const actions = {
  /**
   * Save staff group.
   *
   * @returns {Promise<*>}
   */
  async saveStaffGroup(context, payload) {
    state.isBusy = true;
    ApiService.setHeader();
    return ApiService.post("school/group/management", payload.newForm)
      .then((response) => {
        console.log("Registered group", response);
      })
      .finally(() => {
        state.isBusy = false;
      });
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
};
