import Page from "./page/teacher_page.store";
import TeacherGroupStore from "./group/teacher_group.store";
import Admin from "./admin/teacher_admin.store";
import Archived from "./archived/teacher_archived.store";

export default {
  modules: {
    Page,
    TeacherGroupStore,
    Admin,
    Archived,
  },
};
