import ApiService from "@/core/services/api.service";
import Vue from "vue";

const state = {
  list: [],
  meta: {},
  pagination: {
    current_page: 1,
  },
  currentPage: 2,
  isBusy: false,
  isSuccess: true,
  group: {},
};

function extracted() {
  console.log("List", state.list);

  let employees = [];
  state.list.forEach((em) => {
    console.log(em);
    if (em.selected) {
      employees.push(em.id);
    }
  });
  console.log(employees);
  return employees;
}

const actions = {
  /**
   * Fetch the current group members.
   * @param context
   * @returns {Promise<boolean>}
   */
  fetchGroupMembers(context) {
    ApiService.setHeader();
    state.isBusy = true;
    return Vue.axios
      .get("group/employees", {
        params: {
          "page[number]": state.meta.currentPage ?? 1,
          group_id: state.group.id,
        },
      })
      .then((res) => {
        context.commit("commitGroupMembers", res);
      })
      .finally(() => (state.isBusy = false));
  },

  /**
   * Get the available employees not yet saved in a group.
   * @param context
   * @returns {Promise<boolean>}
   */
  fetchAvailableGroupMembers(context) {
    ApiService.setHeader();
    state.isBusy = true;
    console.log("current page", state.pagination.current_page);
    return Vue.axios
      .get("group/employees/not", {
        params: {
          "page[number]": state.pagination.current_page ?? 1,
          group_id: state.group.id,
        },
      })
      .then((res) => {
        context.commit("commitAvailableGroupMembers", res);
      })
      .finally(() => (state.isBusy = false));
  },

  /**
   * Save the selected group members.
   * @returns {*}
   */
  saveEmployeeGroupMembers(context) {
    const employees = extracted();
    ApiService.setHeader();

    return ApiService.post("group/employees", {
      employees,
      group_id: state.group.id,
    }).then(() => context.commit("setGroupMembershipSuccess"));
  },
  /**
   * Delete the selected employee members
   * @returns {*}
   */
  deleteEmployeeGroupMembers(context) {
    const employees = extracted();

    ApiService.setHeader();
    return ApiService.delete("group/employees", {
      employees,
      group_id: state.group.id,
    }).then(() => context.commit("setGroupMembershipSuccess"));
  },
};

const mutations = {
  commitGroupMembers(state, res) {
    const members = res.data.data;
    console.log("Commit", members);
    state.list = members;
  },
  commitAvailableGroupMembers(state, res) {
    const members = res.data.data;
    console.log("Commit", members);
    state.list = members;
    state.pagination = res.data.meta.pagination;
    console.log(state.pagination);
  },
  setGroup(state, group) {
    state.group = group;
    console.log(state.group);
  },
  setGroupMembershipSuccess(state) {
    state.isSuccess = true;
  },
};

const getters = {
  getMembershipListBusy: (state) => {
    console.log("BUSY TAK?", state.isBusy);
    return state.isBusy;
  },
  getMembershipList: (state) => state.list,
  getMembershipPagination: (state) => state.pagination,
  getMembershipEmpty: (state) => {
    return state.list.length <= 0;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
