import ApiService from "@/core/services/api.service";
import state from "@/modules/personal/stores/leave/entitlement/state";

export default {
  /**
   * Fetch personal leave entitlement.
   *
   * @param context
   * @returns {*}
   */
  fetchPersonalLeaveEntitlementList(context) {
    ApiService.setHeader();

    context.commit("setPersonalLeaveEntitlementListBusy", true);
    return ApiService.query("school/personal/leave/entitlement", {
      params: {
        "page[size]": 15,
        "page[number]": state.pagination.current_page ?? 1,
      },
    })
      .then((response) => {
        context.commit("setPersonalLeaveEntitlementList", response.data.data);
        context.commit(
          "setPersonalLeaveEntitlementListPagination",
          response.data.meta.pagination
        );
      })
      .finally(() =>
        context.commit("setPersonalLeaveEntitlementListBusy", true)
      );
  },

  /**
   * Submit employee personal leave application form.
   *
   * @param context
   * @param payload
   */
  submitPersonalLeaveApplicationForm(context, payload) {
    ApiService.setHeader();

    return ApiService.post(
      "school/personal/leave/application",
      payload.form
    ).then((res) => res);
  },

  /**
   * Fetch personal leave entitlement form.
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  fetchPersonalLeaveEntitlementForm(context, payload) {
    ApiService.setHeader();

    return ApiService.query("school/personal/leave/entitlement/form", {
      params: {
        id: payload.id,
      },
    }).then((res) => {
      context.commit("setPersonalLeaveEntitlementForm", res.data);
      return res;
    });
  },
};
