import ApiService from "@/core/services/api.service";

const state = {
  employeeId: null,
  relationships: [],
  states: [],
  imageUrl: "",
};

const actions = {
  assignEmployeeId(context, employeeId) {
    console.log("Employee Id", employeeId);
    context.commit("setEmployeeId", employeeId);
  },
  fetchContactRelations(context) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("ref/employee/contact")
        .then((response) => {
          console.log(response);
          context.commit("setContactRelations", response);
          resolve(response);
        })
        .catch((reason) => {
          console.error(reason);
          reject(reason);
        });
    });
  },

  fetchContactDetailForm() {
    console.log("Fetching employee contact detail", state.employeeId);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(
        "employee/detail/contact",
        "?employee_id=" + state.employeeId
      )
        .then((response) => {
          console.log(response);
          // context.commit("setEmployeeContactDetailsForm", response);
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  fetchPersonalDetailForm() {
    console.log("Fetching employee personal detail", state.employeeId);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(
        "employee/detail/personal",
        "?employee_id=" + state.employeeId
      )
        .then((response) => {
          console.log(response);
          // context.commit("setEmployeePersonalDetailsForm", response);
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  fetchMartialStatus() {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("ref/marital")
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  submitEmployeeContactDetails(context, form) {
    form.employee_id = state.employeeId;
    console.log("Employee Id", state.employeeId);

    let url = "";
    if (form.id === null) url = "employee/detail/contact";
    else url = "employee/detail/contact/update";

    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(url, form)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  submitEmployeePersonalDetails(context, form) {
    form.employee_id = state.employeeId;
    console.log("Employee Id", state.employeeId);

    let url = "";
    if (form.id === null) url = "employee/detail/personal";
    else url = "employee/detail/personal/update";

    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(url, form)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  uploadEmployeeImage(context, image) {
    ApiService.setHeader();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const fd = new FormData();
    fd.append("image", image);
    fd.append("employee_id", state.employeeId);
    return ApiService.post("employee/profile/image", fd, config).then((res) => {
      context.commit("setUploadEmployeeImage", res.data.url);
      return res;
    });
  },
};

const mutations = {
  setContactRelations(state, response) {
    console.log("The relationships", response.data.relation);
    state.relationships = response.data.relation;
    console.log(state.relationships);
  },
  setContactState(state, response) {
    console.log("The state", response.data.relation);
    state.states = response.data.relation;
    console.log(state.states);
  },
  setEmployeeId(state, employeeId) {
    state.employeeId = employeeId;
  },
  setUploadEmployeeImage(state, imageUrl) {
    state.imageUrl = imageUrl;
  },
};

const getters = {
  getUploadEmployeeImage: (state) => state.imageUrl,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
