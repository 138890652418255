import state from "./state";
import ApiService from "@/core/services/api.service";

export default {
  /**
   * Fetch individual entitlement list
   *
   * @param context
   * @param payload
   */
  fetchTeacherAwayIndividualEntitlementList(context, payload) {
    const id = payload.id;
    console.log("Teacher id", id);

    ApiService.setHeader();
    return ApiService.query("school/away/teacher/entitlement", {
      params: {
        id: id,
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": state.size,
      },
    }).then((res) => {
      context.commit("setTeacherAwayIndividualEntitlementList", res.data.data);
      context.commit(
        "setTeacherAwayIndividualEntitlementListPagination",
        res.data.meta.pagination
      );
    });
  },

  /**
   * Get the individual teacher entitlement form.
   *
   * @param context
   * @param payload
   */
  fetchTeacherAwayIndividualEntitlementForm(context, payload) {
    ApiService.setHeader();
    return ApiService.query("school/away/teacher/entitlement/form", {
      params: {
        id: payload.id,
      },
    }).then((res) => {
      context.commit("setTeacherAwayIndividualEntitlementForm", res.data);
    });
  },

  /**
   * Fetch individual entitlement list
   *
   * @param context
   * @param payload
   */
  fetchStaffAwayIndividualEntitlementList(context, payload) {
    const id = payload.id;
    console.log("Teacher id", id);

    ApiService.setHeader();
    return ApiService.query("school/away/staff/entitlement", {
      params: {
        id: id,
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": state.size,
      },
    }).then((res) => {
      context.commit("setStaffAwayIndividualEntitlementList", res.data.data);
      context.commit(
        "setStaffAwayIndividualEntitlementListPagination",
        res.data.meta.pagination
      );
    });
  },

  /**
   * Get the individual staff entitlement form.
   *
   * @param context
   * @param payload
   */
  fetchStaffAwayIndividualEntitlementForm(context, payload) {
    ApiService.setHeader();
    return ApiService.query("school/away/staff/entitlement/form", {
      params: {
        id: payload.id,
      },
    }).then((res) => {
      context.commit("setStaffAwayIndividualEntitlementForm", res.data);
    });
  },
};
