export default {
  FACIAL: {
    PAGE: {
      HEADER: "Facial Devices",
      HEADERFORM: "Create Facial Devices",
      SUBTITLE: "Facial Devices List",
      BUTTON: "Register",
      DEVICE: "Device",
      MODEL: "Model",
      SERIALNUMBER: "Serial Number",
      ACTIONS: "Actions",
      BEACON: "Beacon",
      DEVICENAME: "Device Name",
      DEVICENAME1: "Enter Device Name",
    },
  },
};
