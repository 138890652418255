import ApiService from "@/core/services/api.service";
import state from "./state";

export default {
  /**
   * Fetch fd list.
   *
   * @param context
   * @returns {*}
   */
  async fetchFDList(context) {
    ApiService.setHeader();
    const res = await ApiService.query("school/device/facial", {
      params: {
        "page[size]": 10,
        "page[number]": state.pagination.current_page ?? 1,
        "filter[name]": state.filters?.name,
        "filter[purchase_date]": state.filters?.purchase_date,
      },
    });

    context.commit("setFDList", res.data.data);
    context.commit("setFDListPagination", res.data.meta.pagination);
    return res;
  },

  /**
   * Change name filter.
   *
   * @param context
   * @param payload
   */
  changeFDListNameFilter(context, payload) {
    let filters = state.filters;
    filters.name = payload.name;

    context.commit("setFDListFilters", filters);
  },

  /**
   * Change date
   *
   * @param context
   * @param payload
   */
  changeFDListPurchaseDateFilter(context, payload) {
    let filters = state.filters;
    filters.purchase_date = payload.date;

    context.commit("setFDListFilters", filters);
  },
};
