import ApiService from "@/core/services/api.service";
import state from "./states";
import dayjs from "dayjs";
import AppReportDownloadService from "@/modules/core/services/file/file_download.service";

export default {
  /**
   *
   * @param context
   */
  async fetchStaffDailyAttendance(context) {
    try {
      ApiService.setHeader();
      context.commit("setStaffDailyAttendanceBusy", true);

      console.log("Daily Date ", dayjs().format());

      const response = await ApiService.query("school/attendance/staff", {
        params: {
          "page[size]": state.size,
          "page[number]": state.pagination.current_page ?? 1,
          "filter[base]": state.filers?.first_name,
          date: state.filers.date,
          status: state.filers.status,
        },
      });

      context.commit("setStaffDailyAttendance", response.data.data);
      context.commit(
        "setStaffDailyAttendancePagination",
        response.data.meta.pagination
      );
    } catch (e) {
      console.error(e);
      throw Error("Failed");
    } finally {
      context.commit("setStaffDailyAttendanceBusy", false);
    }
  },

  /**
   *
   * @param context
   * @param payload
   * @returns {Promise<*>}
   */
  async downloadStaffDailyAttendanceReport(context, payload) {
    try {
      ApiService.setHeader();

      console.log("report date", state.filers?.date);

      const response = await ApiService.query(
        "school/attendance/staff/report",
        {
          responseType: "blob",
          params: {
            type: payload.type ?? 1,
            date: state.filers.date,
            status: state.filers.status,
          },
        }
      );

      AppReportDownloadService.processDownload(
        response.data,
        "Staff Daily Report"
      );

      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },

  /**
   * Change daily attendance name filter.
   *
   * @param context
   * @param payload
   */
  changeStaffDailyAttendanceNameFilter(context, payload) {
    const filters = state.filers;

    filters.first_name = payload.name;

    context.commit("setStaffDailyAttendanceFilters", filters);
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeStaffDailyAttendanceDateFilter(context, payload) {
    const filters = state.filers;

    filters.date = payload.date;
    context.commit("setStaffDailyAttendanceFilters", filters);
  },

  /**
   * Change daily att status filter.
   *
   * @param context
   * @param payload
   */
  changeStaffDailyAttendanceStatusFilter(context, payload) {
    const filters = state.filers;

    filters.status = payload.status;
    context.commit("setStaffDailyAttendanceFilters", filters);
  },
};
