import ApiService from "@/core/services/api.service";

const state = {
  isSaved: false,
};

const actions = {
  /**
   * Submit the save company leave type form
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  saveCompanyLeaveType(context, payload) {
    ApiService.setHeader();
    return ApiService.post("company/leave", payload).then(
      (response) => response
    );
  },

  /**
   * Modify the company leave type form
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  modifyCompanyLeaveType(context, payload) {
    ApiService.setHeader();
    return ApiService.post("company/leave/update", payload).then(
      (response) => response
    );
  },

  /**
   * Delete the company leave type
   *
   * @param context
   * @param payload is Company Leave id
   */
  deleteCompanyLeaveType(context, payload) {
    ApiService.setHeader();
    return ApiService.delete("company/leave", {
      params: {
        id: payload,
      },
    }).then((response) => response);
  },
};

const mutations = {
  setUpdateCompanyLeaveTypeForm(state, saveState) {
    state.isSaved = saveState;
  },
};

const getters = {
  getUpdateCompanyLeaveTypeForm: (state) => state.isSaved,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
