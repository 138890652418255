import ApiService from "@/core/services/api.service";
import state from "./state";
import dayjs from "dayjs";
import AppReportDownloadService from "@/modules/core/services/file/file_download.service";

export default {
  /**
   * Fetch Teacher Monthly Attendance
   *
   * @param context
   */
  async fetchTeacherMonthlyAttendanceList(context) {
    try {
      ApiService.setHeader();

      context.commit("setTeacherMonthlyAttendanceBusy", true);
      const response = await ApiService.query(
        "school/attendance/teacher/monthly",
        {
          params: {
            "page[size]": state.size,
            "page[number]": state.pagination.current_page ?? 1,
            "filter[base]": state.filter?.first_name,
            date: state.filter.date,
          },
        }
      );
      console.log("Received ", response);
      context.commit("setTeacherMonthlyAttendanceList", response.data.data);
      context.commit(
        "setTeacherMonthlyAttendancePagination",
        response.data.meta.pagination
      );

      return response;
    } catch (error) {
      console.error(error);

      throw Error("Failed.");
    } finally {
      context.commit("setTeacherMonthlyAttendanceBusy", false);
    }
  },

  /**
   *
   * @param context
   * @returns {Promise<*>}
   */
  async fetchTeacherMonthlyRangeAttendance(context) {
    try {
      ApiService.setHeader();
      context.commit("setTeacherMonthlyAttendanceBusy", true);

      const response = await ApiService.query(
        "school/attendance/teacher/monthly/range",
        {
          params: {
            "page[size]": state.size,
            "page[number]": state.pagination.current_page ?? 1,
            "filter[base]": state.filter?.first_name,
            from_date: state.filter?.from_date,
            to_date: state.filter?.to_date,
          },
        }
      );

      console.log("Received ", response);
      context.commit("setTeacherMonthlyAttendanceList", response.data.data);
      context.commit(
        "setTeacherMonthlyAttendancePagination",
        response.data.meta.pagination
      );

      return response;
    } catch (error) {
      console.error(error);

      throw Error("Failed.");
    } finally {
      context.commit("setTeacherMonthlyAttendanceBusy", false);
    }
  },

  /**
   *
   * @param context
   * @param payload
   * @returns {Promise<*>}
   */
  async downloadTeacherMonthlyAttendanceReport(context, payload) {
    try {
      ApiService.setHeader();

      let dateType;
      if (state.date_type === 1) {
        dateType = {
          date: dayjs(state.filter.date).format(),
        };
      } else {
        dateType = {
          from_date: state.filter?.from_date,
          to_date: state.filter?.to_date,
        };
      }

      const response = await ApiService.query(
        "school/attendance/teacher/monthly/report",
        {
          responseType: "blob",
          params: {
            type: payload.type ?? 1,
            date_type: state.date_type ?? 1,
            ...dateType,
          },
        }
      );

      AppReportDownloadService.processDownload(
        response.data,
        "Teacher Monthly Report"
      );

      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },

  /**
   * Change list filter date
   *
   * @param context
   * @param payload
   */
  changeTeacherMonthlyAttendanceListDate(context, payload) {
    const date = payload.date;
    if (date !== null) {
      context.commit("setTeacherMonthlyAttendanceDate", date);
    }
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeTeacherMonthlyRangeFilters(context, payload) {
    const filters = state.filter;

    filters.from_date = payload.from_date;
    filters.to_date = payload.to_date;

    context.commit("setSchoolStaffMonthlyAttendanceFilters", filters);
  },

  /**
   * Change name filter.
   *
   * @param context
   * @param payload
   */
  changeTeacherMonthlyNameFilter(context, payload) {
    const filters = state.filter;

    filters.first_name = payload.name;

    context.commit("setTeacherMonthlyAttendanceFilters", filters);
  },
  /**
   *
   * @param context
   * @param payload
   */
  changeTeacherMonthlyDateType(context, payload) {
    console.log(payload);
    context.commit("setTeacherMonthlyAttendanceDateType", payload.type);
  },
};
