//import DEPARTMENT from "./department.en";
import DETAILS from "./details.en";
import FORM from "./form.en";
import GROUP from "./group.en";
import LEAVE from "./leave.en";
import GENERAL from "./general.en";

export default {
  TEACHER: {
    HEADER: "Teachers",
    SUBTITLE: "List of teachers",
    BUTTON: "Create",
    EMP: "Teacher Name",
    TOOLTIP: {
      EDIT: "Edit Teacher Details",
      DELETE: "Hapus {teacher}",
    },
  },
  STAFF: {
    HEADER: "Staff",
    SUBTITLE: "List of staffs",
    BUTTON: "Create",
    EMP: "Staff Name",
    TOOLTIP: {
      EDIT: "Edit Staff Details",
      DELETE: "Padam {teacher}",
    },
  },
  USERS: {
    DETAILS: {
      CONTACT: "Contact",
      GENERAL: "General",
      ADDRESS: "Address",
      RESET: "Reset",
      SUBMIT: "Submit",
    },
  },
  EDUTABLE: {
    FULLNAME: "Full Name",
    DEPARTMENTNAME: "Name",
    ACTIONS: "Actions",
  },
  SUMMARY: {
    SUMMARY: "Summary",
    SUMMARY1: "Enter User's Summary",
  },
  POSITIONS: {
    NAME: "Enter Position Name",
    CODE: "Enter Code",
    DETAILS: "Position Details",
  },
  SCHOOLDETAILS:
  {
        DETAILS:
        {
          CODE : "Code",
          NAME : "School Name",
          EMAIL : "Email",
          CONTACTNO : "Contact Number",
          RESET: "Reset",
          SUBMIT: "Submit",
        },
        MESSAGE:
        {
          SCHOOLDETAILS : "School Details",
          MES1: "School information can be changed here",
          MES2: "Changes may take a while to be processed. We thank you for you patience. ",

        },
  },

  //...DEPARTMENT,
  ...DETAILS,
  ...FORM,
  ...GROUP,
  ...LEAVE,
  ...GENERAL,
};
