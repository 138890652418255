import state from "./states";
import actions from "./actions";
import mutations from "./mutations";

const getters = {
  getStaffDailyAttendanceList: (state) => state.items,
  getStaffDailyAttendancePagination: (state) => state.pagination,
  getStaffDailyAttendanceBusy: (state) => state.isBusy,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
