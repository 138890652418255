import Form from "@/modules/school/store/teacher/group/schedule/form/teacher_group_schedule_form.store";
import List from "@/modules/school/store/teacher/group/schedule/list/teacher_group_schedule_list.store";
import Delete from "./delete/delete.store";

export default {
    modules: {
        Form,
        List,
        Delete,
    },
};
