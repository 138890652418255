import ApiService from "@/core/services/api.service";
import { default as groupService } from "@/modules/school/services/group/emp_group.service";

const state = {
  toSave: 0,
  group: {},
  isBusy: false,
};

const actions = {
  engageTeacherGroupFormSubmission(context) {
    let count = state.toSave;
    console.log(count);
    context.commit("setTeacherGroupFormSubmission", ++count);
  },

  saveTeacherGroupSchedule(context, form) {
    context.commit("holdTeacherGroupSchedule");
    ApiService.setHeader();

    const days = groupService.convertGroupForm(form);
    console.log(days);

    const payload = {
      id: state.group.id,
      days,
    };
    return ApiService.post("school/group/schedule", payload).finally(() =>
      context.commit("holdTeacherGroupSchedule")
    );
  },
};

const mutations = {
  setTeacherGroupFormSubmission(state, saveState) {
    state.toSave = saveState;
  },
  setTeacherGroupEdit(state, group) {
    console.log("Selected group", group);
    state.group = group;
  },
  holdTeacherGroupSchedule(state) {
    state.isBusy = !state.isBusy;
    console.log("Hold Teacher Group State is", state.isBusy);
  },
};

const getters = {
  checkTeacherGroupScheduleEvent: (state) => state.toSave,
  getHeldTeacherGroupSchedule: (state) => state.isBusy,
  getSelectedTeacherGroup: (state) => state.group,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
