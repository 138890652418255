const TeacherActive = () =>
  import("../../../components/teacher/list/TeacherListPage");

const TeacherArchived = () =>
  import("../../../components/teacher/archived/TeacherArchivedPage");

export default [
  {
    path: "active",
    component: TeacherActive,
    name: "teacherActiveList",
  },
  {
    path: "archived",
    component: TeacherArchived,
    name: "teacherArchivedList",
  },
];
