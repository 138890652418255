import ApiService from "@/core/services/api.service";
import state from "./state";
import AppReportDownloadService from "@/modules/core/services/file/file_download.service";

export default {
  /**
   * Fetch Employee Yearly Attendance
   *
   * @param context
   */
  async fetchStaffYearlyAttendanceList(context) {
    try {
      ApiService.setHeader();
      context.commit("setStaffYearlyAttendanceBusy", true);
      const response = await ApiService.query(
        "school/attendance/staff/yearly",
        {
          params: {
            "page[size]": state.size,
            "page[number]": state.pagination.current_page ?? 1,
            "filter[base]": state.filter?.first_name,
            date: state.filter.date,
          },
        }
      );
      console.log("Received ", response);
      context.commit("setStaffYearlyAttendanceList", response.data.data);
      context.commit(
        "setStaffYearlyAttendancePagination",
        response.data.meta.pagination
      );

      return response;
    } catch (error) {
      console.error(error);

      throw Error("Failed.");
    } finally {
      context.commit("setStaffYearlyAttendanceBusy", false);
    }
  },

  /**
   * Change list filter date
   *
   * @param context
   * @param payload
   */
  changeStaffYearlyAttendanceListDate(context, payload) {
    const date = payload.date;
    if (date !== null) {
      context.commit("setStaffYearlyAttendanceDate", date);
    }
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeStaffYearlyAttendanceNameFilter(context, payload) {
    const filters = state.filter;

    filters.first_name = payload.name;

    context.commit("setStaffYearlyAttendanceFilters", filters);
  },

  /**
   * Download yearly report
   *
   * @param context
   * @param payload
   * @returns {Promise<AxiosResponse<*>>}
   */
  async downloadStaffYearlyAttendanceReport(context, payload) {
    console.log(payload);

    try {
      ApiService.setHeader();

      const response = await ApiService.query(
        "school/attendance/staff/yearly/report",
        {
          responseType: "blob",
          params: {
            type: payload.type ?? 1,
            date: state.filter?.date ?? 1,
          },
        }
      );

      AppReportDownloadService.processDownload(
        response.data,
        `Staff Yearly Report - ${payload.date}`
      );

      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
};
