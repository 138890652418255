export default {
  /**
   * Set the items.
   *
   * @param state
   * @param items
   */
  setTeacherIndividualAttendanceList(state, items) {
    console.log("Items ", items);
    state.items = items;

    console.log("State now: ", state);
  },

  /**
   * Teacher individual list pagination
   *
   * @param state
   * @param pagination
   */
  setTeacherIndividualAttendancePagination(state, pagination) {
    console.log("Pagination", pagination);

    state.pagination = pagination;
  },

  /**
   * Busy State
   *
   * @param state
   * @param isBusy
   */
  setTeacherIndividualAttendanceBusy(state, isBusy) {
    state.isBusy = isBusy;

    console.log("State now", state);
  },

  setTeacherIndividualAttendancePage(state, currentPage) {
    console.log("Page to change to", currentPage);

    state.pagination.current_page = currentPage;
  },
};
