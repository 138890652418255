import JOB from "./details/job.en";
import SALARY from "./details/salary.en";
import FAMILY from "./details/family.en";
import EDUCATION from "./details/education.en";

export default {
  ...JOB,
  ...SALARY,
  ...FAMILY,
  ...EDUCATION,
  EMPLOYEESGENERAL: {
    MESSAGE: {
      FIRSTNAME: "First Name",
      LASTNAME: "Last Name",
      EMAIL: "Email",
      UNIQUE: "Unique Code",
      UNIQUE_VALIDATION:
        "Code Status cannot be used. Already taken by other user",
      EMPLOYEMENT: "Employment Date",
      MANAGE: "Manage {employee}",
      TITLE_STAFF: "Add Staff",
      TITLE_TEACHER: "Tambah pekerja - Guru",
      SUBTITLE: "Employee Details",
    },
  },
  EMPLOYEESCONTACT: {
    MESSAGE: {
      CONTACT: "Employee Contact Number",
      EMERGENCY: "Emergency Name",
      RELATIONSHIP: "Relationship",
      ADDRESS1: "Address 1",
      ADDRESS2: "Address 2",
      ADDRESS3: "Address 3",
      LOCALITY: "Locality",
      CITY: "City Town",
      POSTALCODE: "Postal Code",
      STATE: "State",
    },
  },
  EMPLOYEESPERSONAL: {
    MESSAGE: {
      DOB: "Birth Date",
      DOB1: "Enter Birth Date",
      GENDER: "Gender",
      MARITALID: "Marital ID",
      ADDRESS1: "Address 1",
      ADDRESS2: "Address 2",
      ADDRESS3: "Address 3",
      LOCALITY: "Locality",
      CITY: "City Town",
      POSTALCODE: "Postal Code",
      STATE: "State",
    },
  },
};
