import ApiService from "@/core/services/api.service";
import state from "./state";

export default {
  fetchTeacherClockRecordList(context, payload) {
    console.log("Payload", payload);

    ApiService.setHeader();
    return ApiService.query("school/attendance/clock/teacher", {
      params: {
        "page[size]": state.size,
        "page[number]": state.pagination.current_page ?? 1,
        id: payload.id,
        date: payload.date,
      },
    }).then((res) => {
      console.log("Response", res);
      context.commit("setTeacherClockRecordList", res.data.data);
      context.commit(
        "setTeacherClockRecordPagination",
        res.data.meta.pagination
      );

      return res;
    });
  },
};
