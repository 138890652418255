export default [
  {
    path: "settings/company",
    name: "company",
    component: () =>
      import(
        "@/modules/company/components/registration/CompanyRegistrationPage"
      ),
    children: [
      {
        path: "register",
        name: "register_company",
        component: () =>
          import(
            "@/modules/company/components/registration/CompanyRegistrationForm"
          ),
      },
    ],
  },
];
