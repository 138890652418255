import ApiService from "@/core/services/api.service";
import state from "./state";

export default {
  /**
   * Fetch Teacher Leave application list.
   *
   * @param context
   */
  async fetchTeacherAwayApplicationApprovalList(context) {
    try {
      context.commit("setTeacherAwayApplicationApprovalListBusy", true);
      ApiService.setHeader();

      const response = await ApiService.query(
        "school/away/application/teacher",
        {
          params: {
            "page[number]": state.pagination.current_page ?? 1,
            "page[size]": state.size,
          },
        }
      );

      context.commit(
        "setTeacherAwayApplicationApprovalList",
        response.data.data
      );
      context.commit(
        "setTeacherAwayApplicationApprovalListPagination",
        response.data.meta.pagination
      );

      return response;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      context.commit("setTeacherAwayApplicationApprovalListBusy", false);
    }
  },
};
