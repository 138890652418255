import ApiService from "@/core/services/api.service";

const state = {
  responses: "",
  errors: null,
  formSuccess: false,
};

const actions = {
  /**
   * Add Device to Server.
   *
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  registerDevice(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("device/facial/register", payload)
        .then((response) => {
          console.log(response);
          context.commit("setRegisterDeviceFormSuccess", true);
          resolve(response);
        })
        .catch((reason) => {
          console.error(reason);
          reject(reason);
        });
    });
  },

  /**
   * Check existing Device Serial Number
   *
   * @param context
   * @param payload
   */
  checkExistingDeviceSerialNumber(context, payload) {
    ApiService.setHeader();
    return ApiService.query("device/code", {
      params: {
        code: payload.code,
        id: payload.id,
      },
    }).then((response) => response);
  },
};

const mutations = {
  /**
   *
   * @param state
   * @param success
   */
  setRegisterDeviceFormSuccess(state, success) {
    console.log("Set Form success", state.formSuccess, success);
    state.formSuccess = success;
  },
};

const getters = {
  getRegisterDeviceFormSuccess: (state) => state.formSuccess,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
