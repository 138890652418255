import CompanyLeaveListStore from "./company_leave_type_list.store";
import CompanyLeaveTypeStore from "./company_leave_type_form.store";
import CompanyLeaveSelectStore from "./company_leave_select.store";

export default {
  modules: {
    CompanyLeaveListStore,
    CompanyLeaveTypeStore,
    CompanyLeaveSelectStore,
  },
};
