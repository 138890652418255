const database = {
  name: "smartid_corp",
  version: 1,
};

/**
 * @author Amsyar
 * @returns {IDBOpenDBRequest}
 *
 * Opens indexDB request.
 */
export const init = () => {
  return window.indexedDB.open(database.name, database.version);
};

export default {
  database,
  init,
};
