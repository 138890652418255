import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getStaffGroupMemberInList: (state) => state.items,
  getStaffGroupMemberInListPagination: (state) => state.pagination,
  getStaffGroupMemberInListPage: (state) => state.pagination.current_page,
  getStaffGroupMemberInListFilters: (state) => state.filters,
  getStaffGroupMemberInListEmpty: (state) => {
    return state.list.length <= 0;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
