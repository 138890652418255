import ApiService from "@/core/services/api.service";
import state from "./state";
import SchoolReportDownloadService from "@/modules/core/services/file/file_download.service";
import dayjs from "dayjs";

export default {
  /**
   * Fetch Staff list
   *
   * @param context
   * @returns {*}
   */
  async fetchStaffList(context) {
    try {
      context.commit("setStaffListBusy", true);
      ApiService.setHeader();

      const response = await ApiService.query("school/employee/staff", {
        params: {
          "page[size]": 10,
          "page[number]": state.pagination.current_page ?? 1,
          "filter[base]": state.filters?.name,
        },
      });

      context.commit("setStaffList", response.data.data);
      context.commit("setStaffListPagination", response.data.meta.pagination);
      return response;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      context.commit("setStaffListBusy", false);
    }
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeStaffListNameFilter(context, payload) {
    console.log("Name", payload.name);

    let curFilters = state.filters;
    curFilters.name = payload.name;

    context.commit("setStaffListFilter", curFilters);
  },

  /**
   * Download staff list report
   *
   * @param context
   * @param payload
   * @returns {Promise<void>}
   */
  async downloadStaffListReport(context, payload) {
    try {
      ApiService.setHeader();
      const response = await ApiService.query("school/employee/staff/report", {
        responseType: "blob",
        params: {
          type: payload.type,
        },
      });

      SchoolReportDownloadService.processDownload(
        response.data,
        `Staff List - ${dayjs().format("DD-MM-YYYY h:mm")}`
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
};
