import CORE from "./ms/core/core.ms";
import AUTH from "./ms/auth/auth.ms";
import COMPANY from "./ms/company/company.ms";
import DEVICE from "./ms/device/device.ms";
import EMPLOYEES from "./ms/employees/employees.ms";
import USER from "./ms/user/user.ms";
import EDU from "./ms/school/school.ms";
import ATTENDANCE from "./ms/attendance/attendance.ms";
import DASHBOARD from "./ms/dashboard/dashboard.ms";
import COMMON from "./ms/common/common.ms";

export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language",
  },
  ...AUTH,
  ...CORE,
  ...COMPANY,
  ...DEVICE,
  ...EMPLOYEES,
  ...USER,
  ...EDU,
  ...ATTENDANCE,
  ...DASHBOARD,
  ...COMMON,
  MENU: {
    NEW: "baharu",
    ACTIONS: "Tindakan",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
  },
};
