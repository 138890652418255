export default [
  {
    path: "school/leave/teacher/assignment",
    name: "teacherLeaveAssignment",
    component: () =>
      import(
        "../../../../components/management/leave/assignment/teacher/TeacherIndividualLeaveAssignmentPage"
      ),
  },
];
