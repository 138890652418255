import StaffRoutes from "./staff_attendance.router";
import TeacherRoutes from "./teacher/teacher_attendance.router";

const StaffAttendance = () =>
  import("../../../components/management/attendance/SchoolStaffAttendancePage");

const TeacherAttendance = () =>
  import(
    "../../../components/management/attendance/SchoolTeacherAttendancePage"
  );

export default [
  {
    path: "school/management/attendance/teacher",
    component: TeacherAttendance,
    children: [...TeacherRoutes],
  },
  {
    path: "school/management/attendance/teacher/export",
    name: "teacherAttendanceExport",
    component: () =>
      import(
        "../../../components/management/attendance/teacher/TeacherExportMonthlyAttendancePage"
      ),
  },
  {
    path: "school/management/attendance/teacher/export/yearly",
    name: "teacherYearlyAttendanceExport",
    component: () =>
      import(
        "../../../components/management/attendance/teacher/TeacherExportYearlyAttendancePage"
      ),
  },
  {
    path: "school/management/attendance/teacher/yearly/individual",
    name: "individualTeacherYearlyAttendance",
    component: () =>
      import(
        "../../../components/management/attendance/teacher/TeacherIndividualYearlyAttendancePage"
      ),
  },
  {
    path: "school/management/attendance/staff",
    component: StaffAttendance,
    children: [...StaffRoutes],
  },
  {
    path: "school/management/attendance/staff/export/monthly",
    name: "staffAttendanceExport",
    component: () =>
      import(
        "../../../components/management/attendance/staff/StaffExportMonthlyAttendancePage"
      ),
  },
  {
    path: "school/management/attendance/staff/export/yearly",
    name: "staffYearlyAttendanceExport",
    component: () =>
      import(
        "../../../components/management/attendance/staff/StaffExportYearlyAttendancePage"
      ),
  },
  {
    path: "school/management/attendance/staff/yearly/individual",
    name: "individualStaffYearlyAttendance",
    component: () =>
      import(
        "../../../components/management/attendance/staff/StaffIndividualYearlyAttendancePage"
      ),
  },
];
