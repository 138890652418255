/**
 * Department List store
 *
 * @author Amsyar
 * @version 1.0.0
 */
import ApiService from "@/core/services/api.service";

const state = {
  items: "",
  errors: null,
};

const actions = {
  getDepartment(context, payload = { page_number: 1 }) {
    console.log("Payload", payload);
    context.commit("clearDepartment");
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("department/record", "?page_number=" + payload.page_number)
        .then((response) => {
          context.commit("setDepartment", response.data);
          resolve(response);
        })
        .catch((error) => {
          context.commit("setDepartmentError", error);
          reject(error);
        });
    });
  },
};

const mutations = {
  setDepartment(state, response) {
    console.log(response);
    state.items = response;

    console.log(state.items);
  },
  setDepartmentError(state, error) {
    console.error(error);
    state.errors = error;
  },
  clearDepartment(state) {
    state.items = null;
  },
};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
