import BaseRoute from "./base/base.router";
import AttendanceRoute from "./attendance/attendance.router";
import SchoolLeaveRoute from "./leave/school_leave.router";
import SchoolAwayRoute from "./away/school_away.router";

export default [
  ...BaseRoute,
  ...AttendanceRoute,
  ...SchoolLeaveRoute,
  ...SchoolAwayRoute,
];
