import ApiService from "@/core/services/api.service";

export default {
  submitTeacherIndividualAssignmentForm(context, payload) {
    ApiService.setHeader();
    return ApiService.post(
      "school/leave/assignment/teacher",
      payload.form
    ).then((res) => res);
  },
};
