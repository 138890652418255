import ApiService from "@/core/services/api.service";

const states = {
  errors: null,
  response: null,
};

const actions = {
  create(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("department/record/register", payload)
        .then((response) => {
          context.commit("setDepartmentForm", response.data);
          resolve(response);
        })
        .catch((error) => {
          context.commit("setDepartmentFormError", error);
          reject(error);
        });
    });
  },
};

const mutations = {
  setDepartmentForm(state, response) {
    this.state.response = response;
  },
  setDepartmentError(state, error) {
    this.state.errors = error;
  },
};

const getters = {};

export default {
  states,
  actions,
  mutations,
  getters,
};
