import LEAVE from "./leave/leave.en";

export default {
  ATTENDANCE: {
    IN: "In",
    OUT: "Last Seen",
    ACTIONS: "Actions",
    HEADER: "Employee Attendances",
    SUBTITLE: "Your company employee attendance record",
    BUTTON: "Refresh",
    TEMPERATURE: "Temp.",
    TODAY: "Today",
    PUNCHIN: "Punch In",
    PUNCHOUT: "Punch Out",
    DAILY: "Daily",
    MONTHLY: "Monthly",
    MONTH: "Your Monthly Attendance",
    DATE: "Date",
  },
  DAILYATT: {
    IN: "Time In",
    SEEN: "Last Seen",
    TEMP: "Temperature",
    STATUS: "Status",
  },
  ...LEAVE,
  CLOCKPAGE: {
    HEADER: "Employee Clock Record",
    RECORDS: "No attendance records",
    RECORDS2:
      "The employee has to check-in first for attendance and clock records to appear.",
  },
};
