import ApiService from "@/core/services/api.service";

const state = {
  id: null,
  employee_id: null,
  leave: {},
  isSaved: false,
  form: {}, // Note, this is not the component's form!
};

const actions = {
  /**
   * Submit the employee's entitlement form
   *
   * @param context
   * @param form
   */
  submitEntitlementForm(context, form) {
    ApiService.setHeader();
    return ApiService.post("company/leave/employee/entitlement", form);
  },

  /**
   * Get the form from API.
   *
   * @param context
   * @param payload is the id Employee Assigned Leave
   */
  fetchEntitlementForm(context, payload) {
    ApiService.setHeader();
    return ApiService.query("company/leave/employee/entitlement/form", {
      params: {
        id: payload,
      },
    }).then((response) => context.commit("setEntitlementForm", response.data));
  },

  /**
   *
   * @param context
   * @param isSaved
   */
  assignEntitlementFormIsSaved(context, isSaved) {
    context.commit("setAssignEntitlementFormIsSaved", isSaved);
  },

  /**
   *
   * @param context
   * @param payload
   */
  assignEmployeeToLeaveEntitlement(context, payload) {
    context.commit("setAssignEmployeeToLeaveEntitlement", payload);
  },
};

const mutations = {
  /**
   *
   * @param state
   * @param form
   */
  setEntitlementForm(state, form) {
    console.log("Current Form vs New Form", state.form, form);
    state.form = form;
  },

  /**
   *
   * @param state
   * @param payload
   */
  setAssignEmployeeToLeaveEntitlement(state, payload) {
    state.id = payload.id;
    state.employee_id = payload.employee_id;
  },

  /**
   *
   * @param state
   * @param isSaved
   */
  setAssignEntitlementFormIsSaved(state, isSaved) {
    state.isSaved = isSaved;
  },
};

const getters = {
  getAssignEmployeeToLeaveEntitlement: (state) => {
    return {
      id: state.id,
      employee_id: state.employee_id,
    };
  },
  getAssignEntitlementFormIsSaved: (state) => state.isSaved,
  getEntitlementForm: (state) => state.form,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
