export default {
  ACCOUNT: {
    CHANGEEMAIL: "Tukar Alamat Emel",
    CHANGEEMAIL1: "Alamat Emel Baru",
    CHANGEEMAIL2: "Masukkan Alamat Emel Baru",
    CHANGEPASS: "Tukar Kata Laluan",
    ENTERPASS1: "Masukkan Kata Laluan",
    ENTERPASS2: "Masukkan Kata Laluan Baru",
    PASS1: "Kata Laluan",
    PASS2: "Sahkan Kata Laluan",
    PASS3: "Kata Laluan Baru",
    ALERT: "Untuk mengubah kata laluan, masukkan kata laluan baru dan klik ",
    ALERT2: "Untuk mengubah emel, masukkan emel baru dan klik ",
  },
};
