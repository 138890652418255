import ApiService from "@/core/services/api.service";
import state from "./state";
import AppReportDownloadService from "@/modules/core/services/file/file_download.service";

export default {
  /**
   * [Action]
   *
   * Fetch teacher daily attendance list.
   *
   * @param context
   * @returns {*}
   */
  async fetchTeacherDailyAttendanceList(context) {
    try {
      ApiService.setHeader();
      context.commit("setTeacherDailyAttendanceBusy", true);

      console.log("Daily Date ", state.filter?.date);

      const res = await ApiService.query("school/attendance/teacher", {
        params: {
          "page[size]": state.size,
          "page[number]": state.pagination.current_page ?? 1,
          "filter[base]": state.filters?.first_name,
          date: state.filters.date,
          status: state.filters.status,
        },
      });

      context.commit("setTeacherDailyAttendanceList", res.data.data);
      context.commit(
        "setTeacherDailyAttendancePagination",
        res.data.meta.pagination
      );
    } catch (e) {
      console.error(e);
    } finally {
      context.commit("setTeacherDailyAttendanceBusy", false);
    }
  },

  async downloadTeacherDailyAttendanceReport(context, payload) {
    try {
      ApiService.setHeader();

      console.log("report date", state.filters?.date);

      const response = await ApiService.query(
        "school/attendance/teacher/report",
        {
          responseType: "blob",
          params: {
            type: payload.type ?? 1,
            date: state.filters.date,
            status: state.filters.status,
          },
        }
      );

      AppReportDownloadService.processDownload(
        response.data,
        "Teacher Daily Report"
      );

      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },

  /**
   * Change name filter.
   *
   * @param context
   * @param payload
   */
  changeTeacherDailyAttendanceNameFilter(context, payload) {
    const filters = state.filters;

    filters.first_name = payload.name;
    context.commit("setTeacherDailyAttendanceFilters", filters);
  },

  /**
   * Change range filter.
   *
   * @param context
   * @param payload
   */
  changeTeacherDailyAttendanceRangeFilter(context, payload) {
    console.log("Payload : ", payload);

    const filters = state.filters;
    filters.from_date = payload.from_date;
    filters.to_date = payload.to_date;

    context.commit("setTeacherDailyAttendanceFilters", filters);
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeTeacherDailyAttendanceDateFilter(context, payload) {
    const filters = state.filters;

    filters.date = payload.date;
    context.commit("setTeacherDailyAttendanceFilters", filters);
  },

  /**
   * Change status filter action.
   *
   * @param context
   * @param payload
   */
  changeTeacherDailyAttendanceStatusFilter(context, payload) {
    const filters = state.filters;

    filters.status = payload.status;
    context.commit("setTeacherDailyAttendanceFilters", filters);
  },
};
