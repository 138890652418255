import state from "./state";
import ApiService from "@/core/services/api.service";

export default {
  /**
   *
   * @param context
   * @returns {*}
   */
  async fetchStaffArchivedList(context) {
    try {
      ApiService.setHeader();
      context.commit("setStaffArchivedListBusy", true);
      const res = await ApiService.query("school/employee/staff/archived", {
        params: {
          "page[size]": 30,
          "page[number]": state.pagination.current_page ?? 1,
          "filter[base]": state.filters.all,
        },
      });

      context.commit("setStaffArchivedList", res.data.data);
      context.commit(
        "setStaffArchivedListPagination",
        res.data.meta.pagination
      );
      return res;
    } catch (error) {
      console.error(error);

      throw Error("Failed.");
    } finally {
      context.commit("setStaffArchivedListBusy", false);
    }
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeStaffArchivedListBaseFilters(context, payload) {
    console.log("Name", payload.all);

    let curFilters = state.filters;
    curFilters.all = payload.all;

    context.commit("setStaffArchivedListFilters", curFilters);
  },
};
