import StaffGroupForm from "@/modules/school/store/staff/group/staff_group_form.store";
import StaffGroupList from "@/modules/school/store/staff/group/staff_group_list.store";
import StaffGroupEdit from "@/modules/school/store/staff/group/staff_group_edit.store";
import OldMemberShipStore from "@/modules/school/store/staff/group/oldmember/group_membership.store";
import StaffGroupScheduleStore from "@/modules/school/store/staff/group/schedule/staff_group_schedule.store";
import StaffGroupMembership from "./membership/membership_store";

const state = {
  items: [],
};

const actions = {};

const getters = {};

export default {
  state,
  actions,
  getters,
  modules: {
    StaffGroupForm,
    StaffGroupList,
    OldMemberShipStore,
    StaffGroupEdit,
    StaffGroupScheduleStore,
    StaffGroupMembership
  },
};
