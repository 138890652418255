import ApiService from "@/core/services/api.service";
import dayjs from "dayjs";
import state from "./state";

export default {
  /**
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  fetchPersonalClockRecord(context, payload) {
    ApiService.setHeader();
    return ApiService.query("school/personal/attendance/clock", {
      params: {
        date: payload.date ?? dayjs().format(),
        "page[size]": 30,
        "page[number]": state.pagination.current_page ?? 1,
      },
    }).then((res) => {
      console.log(res);

      context.commit("setPersonalClockRecordList", res.data.data);
      context.commit(
        "setPersonalClockRecordListPagination",
        res.data.meta.pagination
      );

      return res;
    });
  },
};
