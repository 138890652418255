import actions from "./actions";
import state from "./state";

const getters = {
  getUploadedStaffImage: (state) => state.image,
};

export default {
  state,
  getters,
  actions,
};
