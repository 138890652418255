export default {
  /**
   * Set individual staff leave list items.
   *
   * @param state
   * @param items
   */
  setIndividualStaffLeaveList(state, items) {
    console.log("Items : ", items);

    state.items = items;
  },

  /**
   * Set individual staff filters.
   *
   * @param state
   * @param filters
   */
  setIndividualStaffLeaveListFilters(state, filters) {
    console.log("Filters", filters);

    state.filter = filters;
  },

  /**
   * Set individual staff leave list pagination.
   *
   * @param state
   * @param pagination
   */
  setIndividualStaffLeaveListPagination(state, pagination) {
    console.log("Items : ", pagination);

    state.pagination = pagination;
  },

  /**
   * Set individual staff leave list page.
   *
   * @param state
   * @param page
   */
  setIndividualStaffLeaveListPage(state, page) {
    console.log("Page : ", page);

    state.pagination.current_page = page;
  },
};
