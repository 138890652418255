export default {
  HOLIDAY: {
    HOLIDAY: "Holiday",
    HOLIDAY1: "Enter Holiday",
    DAY: "Day",
    DATE: "Date",
    CREATE: "Create Holidays",
    START: "Start",
    END: "End",

    TOOLTIP: {
      Edit: "Modify this holiday",
      Delete: "Delete this holiday",
    },

    Delete: {
      Title: "",
      Subtitle: "This will delete {holiday}",
      Success: "{holiday} successfully deleted",
    },

    Empty: {
      Title: "No holidays",
      Subtitle_Primary:
        "You have no created Holiday. To add new holiday for your school, please click on the CREATE button",
    },

    Edit: {
      Success: "Successfully updated holiday",
      Failure: "Failed to update holiday",
    },

    Filter: {
      Name: "Search holiday name",
    },
  },
};
