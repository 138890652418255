import i18n from "@/core/plugins/vue-i18n";

export default [
  {
    text: i18n.t("SIDEBAR.MANAGEMENT"),
    type: "menu-section",
    url: {},
    guard: {
      permissions: ["view.manage.school"],
    },
  },

  {
    text: i18n.t("SIDEBAR.SCHOOL"),
    type: "menu-nav",
    url: {
      name: "managementSchool",
    },
    icon: "/media/svg/icons/Home/Cupboard.svg",
    guard: {
      permissions: ["view.manage.school"],
    },
  },

  {
    text: i18n.t("SIDEBAR.HOLIDAY"),
    type: "menu-nav",
    url: {
      name: "holidayList",
    },
    icon: "/media/svg/icons/Communication/Adress-book1.svg",
    guard: {
      permissions: ["view.manage.school"],
    },
  },

  {
    text: i18n.t("SIDEBAR.ATTENDANCE"),
    type: "menu-nav-parent",
    url: {
      name: "employeeAttendance",
    },
    icon: "/media/svg/icons/Home/Clock.svg",
    guard: {
      permissions: ["view.manage.school"],
    },
    children: [
      {
        text: i18n.t("SIDEBAR.TEACHER"),
        type: "menu-nav",
        url: {
          name: "attendanceTeacher",
        },
        guard: {
          permissions: ["view.manage.school"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
      {
        text: i18n.t("SIDEBAR.STAFF"),
        type: "menu-nav",
        url: {
          name: "attendanceStaff",
        },
        guard: {
          permissions: ["view.manage.school"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
    ],
  },

  {
    text: i18n.t("SIDEBAR.LEAVE"),
    type: "menu-nav-parent",
    url: {
      path: "school/leave",
    },
    icon: "/media/svg/icons/Communication/Mail-box.svg",
    guard: {
      permissions: ["view.manage.school"],
    },
    children: [
      {
        text: i18n.t("SIDEBAR.DETAIL"),
        type: "menu-nav",
        url: {
          name: "schoolLeaveDetailPage",
        },
        guard: {
          permissions: ["view.manage.school"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
      {
        text: i18n.t("SIDEBAR.ENTITLEMENT"),
        type: "menu-nav",
        url: {
          name: "schoolLeaveEntitlement",
        },
        guard: {
          permissions: ["view.manage.school"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
      {
        text: i18n.t("SIDEBAR.ASSIGNMENT"),
        type: "menu-nav",
        url: {
          name: "schoolLeaveAssignment",
        },
        guard: {
          permissions: ["view.manage.school"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
      {
        text: i18n.t("SIDEBAR.APPROVAL"),
        type: "menu-nav",
        url: {
          name: "schoolLeaveApplication",
        },
        guard: {
          permissions: ["view.manage.school"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
    ],
  },
  {
    text: i18n.t("SIDEBAR.AWAY"),
    type: "menu-nav-parent",
    url: {
      path: "school/away",
    },
    icon: "/media/svg/icons/Communication/Mail-box.svg",
    guard: {
      permissions: ["view.manage.school"],
    },
    children: [
      {
        text: i18n.t("SIDEBAR.DETAIL"),
        type: "menu-nav",
        url: {
          name: "schoolAwayDetailPage",
        },
        guard: {
          permissions: ["view.manage.school"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
      {
        text: i18n.t("SIDEBAR.ASSIGNMENT"),
        type: "menu-nav",
        url: {
          name: "schoolAwayAssignment",
        },
        guard: {
          permissions: ["view.manage.school"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
      {
        text: i18n.t("SIDEBAR.APPROVAL"),
        type: "menu-nav",
        url: {
          name: "schoolAwayApplication",
        },
        guard: {
          permissions: ["view.manage.school"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
    ],
  },
];
