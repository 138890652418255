import Form from "./form/create_form.store";
import Delete from "./delete/delete.store";
import List from "./list/list.store";
import Edit from "./edit/edit.store";

export default {
  modules: {
    Delete,
    Form,
    List,
    Edit,
  },
};
