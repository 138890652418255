import CompanyLeaveRoute from "@/modules/company/routers/leave/company_leave.router";
import SchoolStaffListRoute from "./list/staff_list_router";

const StaffList = () =>
  import("@/modules/school/components/staff/SchoolStaffPage");
const StaffGroup = () =>
  import("@/modules/school/components/staff/group/StaffGroupPage");

const StaffAdmin = () =>
  import("@/modules/school/components/staff/admin/StaffAdminPage");

export default [
  {
    path: "/school/staff/list",
    component: StaffList,
    children: [...SchoolStaffListRoute],
  },
  {
    path: "/school/staff/group",
    name: "staffGroup",
    component: StaffGroup,
  },
  {
    path: "/school/staff/role",
    name: "staffAdmin",
    component: StaffAdmin,
  },
  ...CompanyLeaveRoute,
];
