const state = {
  items: [
    {
      id: 1,
      name: "Jannatul Aisyah",
      beacon: "BEACON1",
      serialnum: "ACC1P2",
    },
    {
      id: 1,
      name: "Intan Shafiza",
      beacon: "BEACON2",
      serialnum: "ACC1P2",
    },
    {
      id: 1,
      name: "Siti Zulaikha",
      beacon: "BEACON3",
      serialnum: "ACC1P3",
    },
    {
      id: 1,
      name: "No available employees",
      beacon: "BEACON4",
      serialnum: "ACC1P3",
    },
    {
      id: 1,
      name: "No available employees",
      beacon: "BEACON5",
      serialnum: "1ACC1P34",
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getDeviceBeacon: (state) => state.items,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
