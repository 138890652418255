export default {
  /**
   *
   * @param state
   * @param count
   */
  setStaffDashboardCount(state, count) {
    console.log(count);

    state.staffCount = count;
  },
};
