import registration from "@/modules/company/routers/registration/registration";
import PositionRoute from "./positions";

export default [
  ...registration,
  {
    path: "settings/holiday",
    name: "settingsHoliday",
    component: () => import("../components/holiday/HolidaySettingsPage"),
  },
  ...PositionRoute,
];
