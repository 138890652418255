export default {
  /**
   * @param state
   * @param items
   */
  setTeacherMonthlyAttendanceList(state, items) {
    console.log("Items", items);

    state.items = items;
    console.log("Current State ", state);
  },

  /**
   * @param state
   * @param pagination
   */
  setTeacherMonthlyAttendancePagination(state, pagination) {
    console.log("Pagination", pagination);

    state.pagination = pagination;
    console.log("Current State ", state);
  },

  /**
   * Set the current page
   *
   * @param state
   * @param currentPage
   */
  setTeacherMonthlyAttendancePage(state, currentPage) {
    console.log("Page to change to", currentPage);

    state.pagination.current_page = currentPage;
  },

  /**
   * Change date filter
   *
   * @param state
   * @param currentDate
   */
  setTeacherMonthlyAttendanceDate(state, currentDate) {
    console.log("Current Date", currentDate);
    state.filter.date = currentDate;
  },
  /**
   * Change filters.
   *
   * @param state
   * @param filters
   */
  setTeacherMonthlyAttendanceFilters(state, filters) {
    console.log(filters);
    state.filter = filters;
  },

  /**
   * Set monthly busy
   *
   * @param state
   * @param isBusy
   */
  setTeacherMonthlyAttendanceBusy(state, isBusy) {
    console.log(isBusy);
    state.isBusy = isBusy;
  },

  /**
   * Set the date type.
   *
   * @param state
   * @param type
   */
  setTeacherMonthlyAttendanceDateType(state, type) {
    console.log(type);
    state.date_type = type;
  },
};
