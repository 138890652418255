export default {
  /**
   * Set List
   *
   * @param state
   * @param items
   */
  setTeacherYearlyAttendanceExportList(state, items) {
    console.log("Items: ", items);
    state.items = items;
  },

  /**
   * Set List Pagination.
   *
   * @param state
   * @param pagination
   */
  setTeacherYearlyAttendanceExportListPagination(state, pagination) {
    console.log("Pagination: ", pagination);
    state.pagination = pagination;
  },

  /**
   * Set List Page.
   *
   * @param state
   * @param page
   */
  setTeacherYearlyAttendanceExportListPage(state, page) {
    console.log("Page: ", page);
    state.pagination.current_page = page;
  },

  /**
   * Set date filter.
   *
   * @param state
   * @param date
   */
  setTeacherYearlyAttendanceExportDateFilter(state, date) {
    console.log("Date: ", date);
    state.filter.date = date;
  },
};
