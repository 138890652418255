export default {
  /**
   * Set the FD Staff List items
   *
   * @param state
   * @param items
   */
  setFDStaffList(state, items) {
    state.items = items;
  },

  /**
   * Set FD Staff List pagination meta
   *
   * @param state
   * @param pagination
   */
  setFDStaffListPagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   * Set FD Staff List current page
   *
   * @param state
   * @param currentPage
   */
  setFDStaffListPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   *
   * @param state
   * @param currentState
   * @param index
   */
  setStaffSelectedState(state, { currentState, index }) {
    state.items[index].selected = currentState;
  },
};
