import Teacher from "./assignment/teacher_assignment.router";
import Staff from "./assignment/staff_assignment.router";

export default [
  {
    path: "school/away/assignment",
    component: () =>
      import(
        "../../../components/management/away/assignment/SchoolAwayAssignmentPage"
      ),
    children: [
      {
        path: "teacher",
        name: "schoolAwayAssignment",
        component: () =>
          import(
            "../../../components/management/away/assignment/teacher/TeacherAwayAssignment"
          ),
      },
      {
        path: "staff",
        name: "staffAwayAssignment",
        component: () =>
          import(
            "../../../components/management/away/assignment/staff/StaffAwayAssignment"
          ),
      },
    ],
  },
  ...Teacher,
  ...Staff,
];
