import EmployeeGroupForm from "@/modules/company/stores/group/employee_group_form.store";
import EmployeeGroupList from "@/modules/company/stores/group/employee_group_list.store";
import EmployeeGroupEdit from "@/modules/company/stores/group/employee_group_edit.store";
import GroupMemberShipStore from "@/modules/company/stores/group/membership/group_membership.store";
import EmployeeGroupScheduleStore from "@/modules/company/stores/group/schedule/emp_group_schedule.store";

const state = {
  items: [],
};

const actions = {};

const getters = {};

export default {
  state,
  actions,
  getters,
  modules: {
    EmployeeGroupForm,
    EmployeeGroupList,
    GroupMemberShipStore,
    EmployeeGroupEdit,
    EmployeeGroupScheduleStore,
  },
};
