import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getTeacherMonthlyAttendanceList: (state) => state.items,
  getTeacherMonthlyAttendancePagination: (state) => state.pagination,
  getTeacherMonthlyAttendanceListDate: (state) => state.filter.date,
  getTeacherMonthlyListAttendanceBusy: (state) => state.isBusy,
  getTeacherMonthlyListAttendanceFilter: (state) => state.filter,
  getTeacherMonthlyAttendanceDateType: (state) => state.date_type,
};

export default {
  actions,
  state,
  mutations,
  getters,
};
