import ApiService from "@/core/services/api.service";

const state = {
  list: [],
  group: {},
  dayReferences: [
    {
      id: 1,
      name: "monday",
    },
    {
      id: 2,
      name: "tuesday",
    },
    {
      id: 3,
      name: "wednesday",
    },
    {
      id: 4,
      name: "thursday",
    },
    {
      id: 5,
      name: "friday",
    },
    {
      id: 6,
      name: "saturday",
    },
    {
      id: 7,
      name: "sunday",
    },
  ],
};

const actions = {
  fetchEmployeeGroupSchedule(context) {
    ApiService.setHeader();
    return ApiService.get("group/schedule", "?id=" + state.group.id).then(
      (res) => context.commit("setEmployeeGroupScheduleList", res.data)
    );
  },
};

const mutations = {
  setEmpGroupEdit(state, group) {
    console.log("Selected group", group);
    state.group = group;
  },

  setEmployeeGroupScheduleList(state, data) {
    console.log("Received schedule data", data);
    state.list = data.data.schedule;
    console.log("Current schedule list", state.list);
  },
};

const getters = {
  getEmployeeGroupSchedule: (state) => state.list,
  getEmployeeGroupScheduleForm: (state) => {
    if (state.list == null) return null;

    let form = {};

    // Set similar days
    state.list.forEach((list) => {
      state.dayReferences.some((day) => {
        if (day.id === list.id) {
          form[day.name] = {
            day_type: list.day_status_id,
            time_in: list.time_in,
            time_out: list.time_out,
          };
        }
      });
    });

    // Then set different days compared to fetched form
    state.dayReferences.forEach((day) => {
      state.list.some((list) => {
        console.log(day.id, list.id);
        if (day.id !== list.id && form[day.name] == null) {
          console.log("Form is empty for this day");
          form[day.name] = {
            day_type: null,
            time_in: "",
            time_out: "",
          };
        }
      });
    });

    return form;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
