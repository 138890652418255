import ApiService from "@/core/services/api.service";

export const REGISTER = "registerEmployer";

const state = {
  errors: null,
};

const actions = {
  [REGISTER](context, user) {
    return new Promise((resolve, reject) => {
      ApiService.post("school/register", user)
        .then((response) => {
          context.commit("setSuccess", response.data);
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          context.commit("setError", error.message);
          reject(error);
        });
    });
  },
};

const mutations = {
  setSuccess(state, response) {
    console.log(response);
  },
  setError(state, error) {
    console.error(error);
  },
};

export default {
  state,
  actions,
  mutations,
};
