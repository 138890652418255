export default {
  /**
   * Set the Staff List items
   *
   * @param state
   * @param items
   */
  setStaffList(state, items) {
    state.items = items;
  },

  /**
   * Set Staff List pagination meta
   *
   * @param state
   * @param pagination
   */
  setStaffListPagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   * Set Staff List current page
   *
   * @param state
   * @param currentPage
   */
  setStaffListPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   *
   * @param state
   * @param filters
   */
  setStaffListFilter(state, filters) {
    console.log(state);

    state.filters = filters;
  },

  /**
   * Set busy
   *
   * @param state
   * @param isBusy
   */
  setStaffListBusy(state, isBusy) {
    console.log(isBusy);

    state.isBusy = isBusy;
  },
};
