const ImageUploadChunkService = {
  /**
   * Get api config
   * @returns {{headers: {"Content-Type": string}}}
   */
  getConfig() {
    return {
      headers: {
        "Content-Type": "application/octet-stream",
      },
    };
  },

  /**
   * Image to the chunked.
   *
   * @param image
   */
  generateChunks(image) {
    let size = 800000,
      subs = Math.ceil(image.size / size);
    let chunks = [];

    for (let i = 0; i < subs; i++) {
      chunks.push(image.slice(i * size, Math.min(i * size + size, image.size)));
    }

    return chunks;
  },
};

export default ImageUploadChunkService;
