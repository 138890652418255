import ApiService from "@/core/services/api.service";

const state = {
  verificationCode: null,
  phaseLogin: 0,
  phaseVerify: 0,
};

const actions = {
  completeLoginPhase(context) {
    context.commit("setLoginPhase", 1);
  },
  completeVerificationPhase(context) {
    context.commit("setLoginVerificationPhase", 1);
    ApiService.setHeader();
    return ApiService.post("employee/verify", {
      verification_code: state.verificationCode,
    });
  },
};

const mutations = {
  setVerificationCode(state, code) {
    state.verificationCode = code;
    console.log("Finish assigning verification %s code", code);
  },
  setLoginPhase(state, phase) {
    state.phaseLogin = phase;
  },
  setLoginVerificationPhase(state, phase) {
    state.phaseVerify = phase;
  },
};

const getters = {
  getVerificationPhaseLogin: (state) => state.phaseLogin,
  getVerificationPhaseVerify: (state) => state.phaseVerify,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
