import ApiService from "@/core/services/api.service";

const state = {};

const actions = {
  submitEmployeeVerification(context, form) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("auth/verification/register/employee", form)
        .then((res) => {
          console.log("Response", res);
          resolve(res);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
};

export default {
  state,
  actions,
};
