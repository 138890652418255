export default {
  SALARY: {
    SALARY: "Payroll",
    SALARY1: "Payroll Details",
    SALARY2: "Enter Payroll",
    SALARY3: "Basic Payroll",
    SALARY4: "Enter Basic Payroll",
    CURRENCY: "Currency",
    CURRENCY1: "Enter Currency",
    PAYMENT: "Payment Details",
    PAYMENT1: "Payment Type",
    PAYMENT2: "Enter Payment Type",
    BANK: "Bank",
    BANK1: "Enter Bank Name",
    ACCOUNT: "Bank Account",
    ACCOUNT1: "Enter Bank Account Number",
    METHOD: "Method",
    METHOD1: "Enter Method Type",
    STATUTORY: "Satutory Details",
    REMARK: "Remark",
    EPF: "Employer EPF Rate",
    EPF1: "Enter Employer EPF Rate",
    EPF2: "EPF Membership Number",
    EPF3: "Enter EPF Membership Number",
    EPF4: "Employee EPF Rate",
    EPF5: "Enter Employee EPF Rate",
    TAX: "Tax Reference Number",
    TAX1: "Enter Tax Reference Number",
    CHILD: "Child Relief",
    CHILD1: "Enter Child Relief",
    EIS: "EIS Contribution",
    EIS1: "Enter EIA Contribution",
    SOCSO: "SOCSO Category",
    SOCSO1: "Enter SOCSO Category",
    TAX2: "Tax Status",
    TAX3: "Enter Tax Status",
    ZAKAT: "Muslim Zakat Fund",
    ZAKAT1: "Enter Muslim Zakat Fund",
  },
};
