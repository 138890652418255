import ApiService from "@/core/services/api.service";

const state = {
  toSave: 0,
  isBusy: false,
  id: null,
  group: {
    id: null,
    name: null,
    description: null,
    mode: null,
  },
};

const actions = {
  /**
   * Specify.
   *
   * @param context
   * @param group
   */
  specifyStaffGroupEdit(context, group) {
    context.commit("setStaffGroupEdit", group);
  },

  /**
   * Update.
   *
   * @param context
   * @param form
   * @returns {*}
   */
  updateStaffGroupForm(context, form) {
    context.commit("holdStaffGroupEditBusy");
    ApiService.setHeader();
    return ApiService.post("school/group/management/update", form)
      .then(() => {
        console.log("Updated ", form);

        const group = {
          id: form.id,
          name: form.name,
          description: form.description,
        };

        context.commit("setStaffGroupEdit", group);
        context.dispatch("getStaffGroup").then(console.log);
      })
      .finally(() => context.commit("holdStaffGroupEditBusy"));
  },

  /**
   * Tell the form to submit.
   *
   * @param context
   */
  engageUpdateStaffGroupForm(context) {
    let count = state.toSave;
    console.log(count);
    context.commit("setUpdateStaffGroupFormSubmission", ++count);
  },
};

const mutations = {
  setStaffGroupEdit(state, group) {
    state.group = group;
  },
  setUpdateStaffGroupFormSubmission(context, saveState) {
    state.toSave = saveState;
  },
  holdStaffGroupEditBusy(state) {
    state.isBusy = !state.isBusy;
  },
};

const getters = {
  getStaffGroupInformation: (state) => state.group,
  getHeldStaffEditGroup: (state) => state.isBusy,
  checkUpdateStaffGroupScheduleEvent: (state) => state.toSave,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
