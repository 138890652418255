export default {
  COMMON: {
    TOOLTIP: {
      REFRESH: "Segar semula",
      Search: "Carian",
      Config: "Tetapan tambahan",
    },

    Table: {
      Actions: {
        Base: "Tindakan",
        Reset_Filters: "Tetapkan Semula Filters",
        Export: "Export - {type}",
        Export_Excel: "Eksport - Excel",
        Export_PDF: "Eksport - Excel",
      },
    },
  },
};
