import dayjs from "dayjs";

export default {
  items: [],
  pagination: {},
  isBusy: false,
  filers: {
    date: dayjs().format(),
    teacherId: null,
  },
};
