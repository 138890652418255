import ApiService from "@/core/services/api.service";

export default {
  updateSchoolManagementForm(context, payload) {
    ApiService.setHeader();

    return ApiService.post("school/management", payload).then(
      (res) => res
    );
  },

  fetchSchoolManagementForm(context) {
    ApiService.setHeader();

    return ApiService.query("school/management/form", {}).then((res) => {
      context.commit("setSchoolManagementForm", res.data);
      return res;
    });
  },
};
