export default {
  /**
   *
   * @param state
   * @param res
   */
  setFacialEmployeeList(state, res) {
    console.log("Facial Employee List: ", res);
    state.items = res.data;
    state.pagination = res.meta.pagination ?? {};
  },

  /**
   *
   * @param state
   * @param currentPage
   */
  setFacialEmployeeListCurrentPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   *
   * @param state
   */
  clearFacialEmployeeList(state) {
    state.items = [];
    state.response = {};
  },

  /**
   *
   * @param state
   */
  clearNotIn(state) {
    state.items = [];
    state.response = {};
  },

  /**
   *
   * @param state
   * @param data
   */
  setFacialEmployeeNotInList(state, data) {
    console.log("Facial Employee List: ", data);
    state.items = data.data;
    state.pagination = data.meta.pagination ?? {};
  },

  /**
   *
   * @param state
   * @param employees
   */
  setSelectedEmployees(state, employees) {
    console.log("set selected employees", employees);
    state.afterSelected = employees;
  },
};
