import CreateForm from "./form/create_form.store";
import List from "./list/list.store";
import Delete from "./delete/delete.store";
import Edit from "./edit/edit_form.store";
import Sync from "./sync/sync";

export default {
  modules: {
    CreateForm,
    List,
    Delete,
    Edit,
    Sync,
  },
};
