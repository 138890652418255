import ApiService from "@/core/services/api.service";
import Vue from "vue";
import state from "@/modules/device/store/facial/employee/state";

export default {
  /**
   *
   * @param context
   * @param id
   * @returns {Promise<unknown>}
   */
  getFacialEmployeeList(context, id) {
    console.log("Calling Facial Emp List", id);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      Vue.axios
        .get("device/facial/employees", {
          params: {
            "page[number]": state.pagination.current_page ?? 1,
            "page[size]": state.size ?? 10,
            id: id,
          },
        })
        .then((res) => {
          context.commit("setFacialEmployeeList", res.data);
          resolve(res);
        })
        .catch((error) => {
          console.log("Error", error);
          reject(error);
        });
    });
  },

  /**
   *
   * @param context
   * @param id
   * @returns {Promise<unknown>}
   */
  getFacialEmployeeNotInList(context, id) {
    console.log("Calling Facial Emp List", id);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      Vue.axios
        .get("device/facial/employees/not", {
          params: {
            "page[number]": state.items.current_page ?? 1,
            "page[size]": state.size ?? 10,
            id: id,
          },
        })
        .then((res) => {
          context.commit("setFacialEmployeeNotInList", res.data);
          resolve(res);
        })
        .catch((error) => {
          console.log("Error", error);
          reject(error);
        });
    });
  },

  /**
   *
   * @param context
   */
  emptyFacialDeviceEmployees(context) {
    context.commit("clearFacialEmployeeList");
  },

  /**
   *
   * @param context
   */
  emptyFacialDeviceEmployeesNotIn(context) {
    context.commit("clearNotIn");
  },

  /**
   *
   * @param context
   * @param deviceId
   * @returns {Promise<unknown>}
   */
  saveToDevice(context, deviceId) {
    console.log("Save to device.", state.items);
    let employees = [];
    state.items.forEach((em) => {
      console.log(em);
      if (em.selected) {
        employees.push(em.id);
      }
    });
    console.log(deviceId);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("device/facial/employees", {
        employee: employees,
        device_id: deviceId,
      })
        .then((res) => {
          context.commit("setSelectedEmployees", employees);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *
   * @param context
   * @param deviceId
   * @returns {Promise<unknown>}
   */
  synchronizeFacialDevice(context, deviceId) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("/device/facial/synchronize", {
        device_id: deviceId,
      })
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  },

  /**
   *
   * @param context
   * @param deviceId
   * @returns {Promise<unknown>}
   */
  removeFromDevice(context, deviceId) {
    let employees = [];
    state.items.forEach((em) => {
      console.log(em);
      if (em.selected) {
        employees.push(em.id);
      }
    });
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("device/facial/employees/delete", {
        device_id: deviceId,
        employee: employees,
      })
        .then((res) => {
          context.commit("setSelectedEmployees", employees);
          resolve(res);
        })
        .catch((error) => reject(error));
    });
  },
};
