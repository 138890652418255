import GENERAL from "./general.en";
import REGISTER from "./register.en";
import LOGIN from "./login.en";

export default {
  AUTH: {
    ...GENERAL,
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
    },
  },
  ...REGISTER,
  ...LOGIN,
};
