import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

const getters = {
  getSchoolStaffMonthlyAttendanceList: (state) => state.items,
  getSchoolStaffMonthlyAttendancePagination: (state) => state.pagination,
  getSchoolStaffMonthlyAttendancePage: (state) => state.pagination.current_page,
  getSchoolStaffMonthlyListDate: (state) => state.filter.date,
  getSchoolStaffMonthlyListBusy: (state) => state.isBusy,
  getSchoolStaffMonthlyListFilter: (state) => state.filter,
  getSchoolStaffMonthlyListDateType: (state) => state.date_type,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
