export default {
  DASHBOARD: {
    TEACHER: {
      SUBTITLE: "Jumlah",
    },
    STAFF: {
      SUBTITLE: "Jumlah",
    },
  },
};
