import store from "@/core/services/store";
import EmployeeVerificationRoute from "@/modules/auth/router/verification/employee_verification.router";
import PasswordResetRoute from "@/modules/auth/router/password/password.router";

export default [
  {
    path: "/",
    beforeEnter: (to, from, next) => {
      console.log(store.getters.isAuthenticated);
      if (store.getters.isAuthenticated) {
        console.log("Going to dashboard.");
        next("dashboard");
      } else {
        next();
      }
    },
    redirect: "/login",
    component: () => import("@/modules/auth/components/Auth"),
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/modules/auth/components/Login"),
      },
      {
        name: "register",
        path: "/register",
        component: () => import("@/modules/auth/components/Register"),
      },
      {
        name: "forgotPassword",
        path: "/forgot/password",
        component: () =>
          import("@/modules/auth/components/password/ForgotPassword"),
      },
    ],
  },
  ...EmployeeVerificationRoute,
  ...PasswordResetRoute,
];
