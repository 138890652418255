export default {
  /**
   * Set the List items
   *
   * @param state
   * @param items
   */
  setStaffGroupMemberInList(state, items) {
    state.items = items;
  },

  /**
   * Set List pagination meta
   *
   * @param state
   * @param pagination
   */
  setStaffGroupMemberInListPagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   * Set List current page
   *
   * @param state
   * @param currentPage
   */
  setStaffGroupMemberInListPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   *
   * @param state
   * @param filters
   */
  setStaffGroupMemberInListFilters(state, filters) {
    console.log("Filters : ", filters);

    state.filters = filters;
  },

  /**
   * Flip switch.
   *
   * @param state
   * @param employeeId
   */
  switchStaffGroupMemberSelectionDelete(state, employeeId) {
    console.log("Info: ", state, employeeId);

    const index = state.items.findIndex((e) => e.id === employeeId);
    console.log("Matched row: ", state.items[index]);

    state.items[index].selected = !state.items[index].selected;
    console.log("Changed row: ", state.items[index]);
  },
};
