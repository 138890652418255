export default {
  PROFILE: {
    USERPROFILE: "User Profile",
    SIGNOUT: "Sign Out",
    HI: "Hi, ",
    PROFILE: "Profile",
    ACCSETTINGS: "Account Settings",
    DEBUG: "DEBUG Mode",
  },
  PERSONAL: {
    PERSONAL: "Personal",
    ACCOUNT: "Account",
    PERSONALPROFILE: "Personal Profile",
    GENERALDETAILS: "General Details",
    PERSONALDETAILS: "Personal Details",
    ACCOUNTDETAILS: "Account Details",
    EMERGENCYCONT: "Emergency Contacts",
    EDUDETAILS: "Education Details",
    PERSONALACC: "Personal Account",
  },
};
