import ApiService from "@/core/services/api.service";

export default {
  /**
   * Fetch teacher dashboard count.
   * @param context
   * @returns {*}
   */
  fetchTeacherDashboardCount(context) {
    ApiService.setHeader();

    return ApiService.query("school/dashboard/teacher").then((res) => {
      console.log(res);
      context.commit("setTeacherDashboardCount", res.data.count);
    });
  },
};
