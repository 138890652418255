import LEAVE from "./leave/leave.ms";

export default {
  ...LEAVE,
  ATTENDANCE: {
    HEADER: "Kehadiran Pekerja",
    SUBTITLE: "Rekod Kehadiran Pekerja Syarikat Anda",
    BUTTON: "Muat Semula",
    IN: "Masuk",
    OUT: "Keluar",
    ACTIONS: "Tindakan",
    TEMPERATURE: "Suhu",
    TODAY: "Kehadiran Hari Ini",
    PUNCHIN: "Waktu Masuk",
    PUNCHOUT: "Waktu Keluar",
    DAILY: "Harian",
    MONTHLY: "Bulanan",
    MONTH: "Kehadiran Bulanan Anda",
    DATE: "Tarikh",
  },
  DAILYATT: {
    IN: "Waktu Masuk",
    SEEN: "Kali Terakhir Dilihat",
    TEMP: "Suhu",
    STATUS: "Status",
  },
  CLOCKPAGE: {
    HEADER: "Rekod Waktu Bekerja",
    RECORDS: "Tiada Rekod Kehadiran",
    RECORDS2:
      "Pekerja perlu daftar masuk terlebih dahulu untuk rekod kehadiran.",
    CLOSE: "Batal",
  },
};
