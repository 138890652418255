export default {
  /**
   * Set the List items
   *
   * @param state
   * @param items
   */
  setTeacherAdminList(state, items) {
    state.items = items;
  },

  /**
   * Set List pagination meta
   *
   * @param state
   * @param pagination
   */
  setTeacherAdminListPagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   * Set List current page
   *
   * @param state
   * @param currentPage
   */
  setTeacherAdminListPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   *
   * @param state
   * @param filters
   */
  setTeacherAdminListFilters(state, filters) {
    console.log("Filters : ", filters);

    state.filters = filters;
  },

  /**
   *
   * @param state
   * @param isBusy
   */
  setTeacherAdminListBusy(state, isBusy) {
    console.log("Busy : ", isBusy);
    state.isBusy = isBusy;
  },
};
