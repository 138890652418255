import state from "./state";
import ApiService from "@/core/services/api.service";

export default {
  /**
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  fetchStaffGroupMemberInList(context, payload) {
    ApiService.setHeader();
    return ApiService.query("school/group/employees", {
      params: {
        "page[size]": 30,
        "page[number]": state.pagination.current_page ?? 1,
        "filter[first_name]": state.filters.name,
        id: payload.id,
        employee_type: 2,
      },
    }).then((res) => {
      context.commit("setStaffGroupMemberInList", res.data.data);
      context.commit(
        "setStaffGroupMemberInListPagination",
        res.data.meta.pagination
      );
      return res;
    });
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeStaffGroupMemberInListNameFilters(context, payload) {
    console.log("Name", payload.name);

    let curFilters = state.filters;
    curFilters.name = payload.name;

    context.commit("setStaffGroupMemberInListFilters", curFilters);
  },

  /**
   *
   * @param context
   * @param payload
   */
  selectStaffGroupMemberDeleteModification(context, payload) {
    console.log("Info : ", context, payload);

    context.commit("switchStaffGroupMemberSelectionDelete", payload.id);
  },
};
