const SchoolAwayDetailPage = () =>
  import(
    "@/modules/school/components/management/away/detail/SchoolAwayDetailPage"
  );

export default [
  {
    path: "school/away/detail",
    component: SchoolAwayDetailPage,
    name: "schoolAwayDetailPage",
  },
];
