import ApiService from "@/core/services/api.service";

const actions = {
  /**
   * Add Device to Server.
   *
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  registerFacialDevice(context, payload) {
    ApiService.setHeader();
    return ApiService.post("school/device/facial", payload).then(
      (response) => response
    );
  },

  /**
   * Check existing Device Serial Number
   *
   * @param context
   * @param payload
   */
  checkExistingFacialDeviceSerialNumber(context, payload) {
    ApiService.setHeader();
    return ApiService.query("school/device/facial/code", {
      params: {
        code: payload.code,
        id: payload.id,
      },
    }).then((response) => response);
  },
};

export default {
  actions,
};
