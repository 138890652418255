export default {
  /**
   * Set Staff individual attendance items.
   *
   * @param state
   * @param items
   */
  setStaffIndividualAttendance(state, items) {
    state.items = items;
  },

  /**
   * Set pagination.
   *
   * @param state
   * @param pagination
   */
  setStaffIndividualAttendancePagination(state, pagination) {
    console.log("Pagination", pagination);
    state.pagination = pagination;

    console.log("Current State", state);
  },

  /**
   * Holding state.
   *
   * @param state
   * @param busy
   */
  setStaffIndividualAttendanceBusy(state, busy) {
    console.log("Busy? ", busy);
    state.isBusy = busy;

    console.log("Current state", state);
  },

  setStaffIndividualAttendancePage(state, currentPage) {
    console.log("Page to change to", currentPage);

    state.pagination.current_page = currentPage;
  },
};
