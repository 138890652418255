import state from "./state";
import ApiService from "@/core/services/api.service";

export default {
  /**
   *
   * @param context
   * @returns {*}
   */
  fetchSchoolAwayDetailList(context) {
    ApiService.setHeader();
    return ApiService.query("school/away/management", {
      params: {
        "page[size]": 10,
        "page[number]": state.pagination.current_page ?? 1,
        "filter[name]": state.filters?.name,
      },
    }).then((res) => {
      context.commit("setSchoolAwayDetailList", res.data.data);
      context.commit(
        "setSchoolAwayDetailListPagination",
        res.data.meta.pagination
      );
      return res;
    });
  },

  /**
   * Change filters.
   *
   * @param context
   * @param payload
   */
  changeSchoolAwayDetailListBaseFilter(context, payload) {
    const filters = state.filters;

    filters.name = payload.query;

    context.commit("setSchoolAwayListFilters", filters);
  },
};
