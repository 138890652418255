import ApiService from "@/core/services/api.service";

const state = {
  leave: {},
  leaveForm: {},
  isBusy: {
    form: false,
  },
};

const actions = {
  /**
   * Designate the selected leave to be selected
   *
   * @param context
   * @param leave
   */
  selectCompanyLeave(context, leave) {
    context.commit("setSelectedCompanyLeave", leave);
  },

  /**
   *
   * @param context
   * @param payload just the ID of Company Leave
   */
  fetchCompanyLeaveForm(context, payload) {
    context.commit("setCompanyLeaveFormBusy", true);
    context.commit("setCompanyLeaveForm", {});
    ApiService.setHeader();
    return ApiService.query("company/leave/form", {
      params: {
        id: payload,
      },
    }).then((response) => {
      context.commit("setCompanyLeaveForm", response.data);
      context.commit("setCompanyLeaveFormBusy", false);
      return response;
    });
  },
};

const mutations = {
  /**
   * Set the designated Leave
   *
   * @param state
   * @param leave
   */
  setSelectedCompanyLeave(state, leave) {
    state.leave = leave;
  },

  /**
   * Set the fetched company leave form
   *
   * @param state
   * @param form
   */
  setCompanyLeaveForm(state, form) {
    console.log("Fetched Form :", form);
    console.log("State Form: ", state.leaveForm);
    state.leaveForm = form;
  },

  /**
   *
   * @param state
   * @param busy
   */
  setCompanyLeaveFormBusy(state, busy) {
    state.isBusy.form = busy;
  },
};

const getters = {
  // Select leave data
  getSelectedCompanyLeave: (state) => state.leave,
  // Form
  getCompanyLeaveForm: (state) => state.leaveForm,
  getCompanyLeaveFormBusy: (state) => state.isBusy.form,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
