import ApiService from "@/core/services/api.service";
import state from "./state";
import SchoolReportDownloadService from "@/modules/core/services/file/file_download.service";
import dayjs from "dayjs";

export default {
  /**
   * Fetch teacher list.
   *
   * @param context
   * @returns {*}
   */
  async fetchTeacherList(context) {
    try {
      context.commit("setTeacherListBusy", true);
      ApiService.setHeader();

      const response = await ApiService.query("school/employee/teacher", {
        params: {
          "page[size]": 30,
          "page[number]": state.pagination.current_page ?? 1,
          "filter[base]": state.filters?.name,
        },
      });

      context.commit("setTeacherList", response.data.data);
      context.commit("setTeacherListPagination", response.data.meta.pagination);
      return response;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      context.commit("setTeacherListBusy", false);
    }
  },

  /**
   * Change the name filter.
   *
   * @param context
   * @param payload
   */
  changeTeacherListNameFilter(context, payload) {
    console.log("Name", payload.name);

    let curFilters = state.filters;
    curFilters.name = payload.name;

    context.commit("setTeacherListFilter", curFilters);
  },

  /**
   * Download Teacher List Report.
   *
   * @param context
   * @param payload
   */
  async downloadTeacherListReport(context, payload) {
    try {
      ApiService.setHeader();

      const response = await ApiService.query(
        "school/employee/teacher/report",
        {
          responseType: "blob",
          params: {
            type: payload.type,
          },
        }
      );

      SchoolReportDownloadService.processDownload(
        response.data,
        `Teacher List - ${dayjs().format("DD-MM-YYYY h:mm")}`
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
};
