/**
 * @type {({name: string, id: number}|{name: string, id: number})[]}
 */
const template = [
  { id: 1, name: "monday" },
  { id: 2, name: "tuesday" },
];

/**
 * Convert Schedule form to days array
 * @param form
 * @returns {[]}
 */
function convertGroupForm(form) {
  console.log("Converting", form);

  let days = [];
  template.forEach((value) => {
    console.log(value);
    days.push({
      id: value.id,
      type: form[value.name].day_type,
      time_in: form[value.name].time_in,
      time_out: form[value.name].time_out,
    });
  });

  return days;
}

export default {
  convertGroupForm,
};
