import Page from "./page/staff_page.store";
import StaffGroupStore from "./group/staff_group.store";
import Admin from "./admin/staff_admin.store";
import Archived from "./archived/staff_archived.store";

export default {
  modules: {
    Page,
    StaffGroupStore,
    Admin,
    Archived,
  },
};
