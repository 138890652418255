

export default {
  /**
   * Download staff daily attendance excel report
   *
   * @param context
   * @param payload
   */
  // async downloadStaffDailyAttendanceReport(context, payload) {
  //   const date = payload.date ?? dayjs().format();
  //
  //   try {
  //     ApiService.setHeader();
  //
  //     const response = await ApiService.query(
  //       "school/attendance/staff/report",
  //       {
  //         responseType: "blob",
  //         params: {
  //           date: date,
  //           type: payload.type,
  //         },
  //       }
  //     );
  //
  //     SchoolReportDownloadService.processDownload(
  //       response.data,
  //       "Staff Daily Attendance"
  //     );
  //
  //     return response;
  //   } catch (e) {
  //     console.error(e);
  //     throw e;
  //   }
  // },
};
