export default [
  {
    path: "school/away/staff/assignment",
    name: "staffAwayAssignmentPage",
    component: () =>
      import(
        "../../../../components/management/away/assignment/staff/StaffIndividualAwayAssignmentPage"
      ),
  },
];
