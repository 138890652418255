import ApiService from "@/core/services/api.service";
import state from "./state";
import dayjs from "dayjs";

export default {
  /**
   *
   * @param context
   * @param payload
   * @returns {Promise<void>}
   */
  async fetchTeacherDashboardDailyAttendanceList(context, payload) {
    try {
      ApiService.setHeader();
      context.commit("setTeacherDashboardDailyAttendanceBusy", true);

      console.log("Daily Date ", dayjs().format());

      const res = await ApiService.query(
        "school/dashboard/teacher/attendance/daily/detailed",
        {
          params: {
            "page[size]": state.size,
            "page[number]": state.pagination.current_page ?? 1,
            "filter[first_name]": state.filters?.first_name,
            date: dayjs().format(),
            id: payload.id,
          },
        }
      );

      context.commit("setTeacherDashboardDailyAttendanceList", res.data.data);
      context.commit(
        "setTeacherDashboardDailyAttendancePagination",
        res.data.meta.pagination
      );
    } catch (e) {
      console.error(e);
    } finally {
      context.commit("setTeacherDashboardDailyAttendanceBusy", false);
    }
  },

  /**
   * Change name filter.
   *
   * @param context
   * @param payload
   */
  changeTeacherDashboardDailyAttendanceNameFilter(context, payload) {
    const filters = state.filters;

    filters.first_name = payload.name;
    context.commit("setTeacherDailyDashboardAttendanceFilters", filters);
  },
};
