import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import UserService from "@/modules/auth/services/user.service";
import Employer from "@/modules/auth/store/register/employer_registration";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const RESET_ERROR = "resetAuthError";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const CLEAR_ERROR = "clearAuthError";

const state = {
  errors: null,
  access_token: "",
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.access_token;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/login", credentials)
        .then((response) => {
          console.log("was");
          context.commit(SET_AUTH, response.data);
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/register", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          context.commit(SET_ERROR, error.response.data);
          reject(error);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      return true;
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  },
  [RESET_ERROR](context) {
    context.commit(CLEAR_ERROR);
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    console.log(error);
    state.errors = error;
  },
  [SET_AUTH](state, data) {
    // Save Auth Details first before committing to auth setup.
    UserService.saveAuth(data)
      .then(() => {
        state.isAuthenticated = true;
        state.access_token = data.access_token;
        state.errors = {};
        JwtService.saveToken(state.access_token);
      })
      .catch((reason) => {
        // Revert everything when error thrown
        state.isAuthenticated = false;
        state.access_token = "";
        state.errors = reason;
        JwtService.destroyToken();
      });
  },

  [PURGE_AUTH](state) {
    UserService.clearAuth()
      .then(() => {
        state.isAuthenticated = false;
        state.access_token = "";
        state.errors = {};
        JwtService.destroyToken();
      })
      .catch((e) => {
        console.error(e);
      });
  },

  [CLEAR_ERROR](state) {
    state.errors = {};
  },
};

export default {
  modules: {
    Employer,
  },
  state,
  actions,
  mutations,
  getters,
};
