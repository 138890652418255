export default [
  {
    path: "school/away/application",
    name: "schoolAwayApplication",
    component: () =>
      import(
        "../../../components/management/away/application/SchoolEmployeeAwayApplicationPage"
      ),
    children: [
      {
        path: "teacher",
        name: "schoolAwayApplication",
        component: () =>
          import(
            "../../../components/management/away/application/teacher/SchoolTeacherAwayApplication"
          ),
      },
      {
        path: "staff",
        name: "staffAwayApplication",
        component: () =>
          import(
            "../../../components/management/away/application/staff/SchoolStaffAwayApplication"
          ),
      },
    ],
  },
];
