export default {
  /**
   * Set List
   *
   * @param state
   * @param items
   */
  setStaffMonthlyAttendanceExportList(state, items) {
    console.log("Items : ", items);

    state.items = items;
  },

  /**
   * Set List Pagination
   *
   * @param state
   * @param pagination
   */
  setStaffMonthlyAttendanceExportListPagination(state, pagination) {
    console.log("Pagination : ", pagination);
    state.pagination = pagination;
  },

  /**
   * Set List Page
   *
   * @param state
   * @param page
   */
  setStaffMonthlyAttendanceExportListPage(state, page) {
    console.log("Current Page : ", page);
    state.pagination.current_page = page;
  },
};
