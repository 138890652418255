import ApiService from "@/core/services/api.service";

const actions = {
  /**
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  updateSchoolHoliday(context, payload) {
    ApiService.setHeader();
    return ApiService.post("school/holiday/management/update", payload).then(
      (res) => {
        return res;
      }
    );
  },
};

export default {
  actions,
};
