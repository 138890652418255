import ApiService from "@/core/services/api.service";
import state from "./state";

export default {
  /**
   * Fetch Teacher Leave application list.
   *
   * @param context
   */
  async fetchStaffLeaveApplicationApprovalList(context) {
    try {
      context.commit("setStaffLeaveApplicationListBusy", true);
      ApiService.setHeader();

      const res = await ApiService.query("school/leave/application/staff", {
        params: {
          "page[number]": state.pagination?.current_page ?? 1,
          "page[size]": state.size,
          "filter[base]": state.filters?.base,
        },
      });

      context.commit("setStaffLeaveApplicationApprovalList", res.data.data);
      context.commit(
        "setStaffLeaveApplicationApprovalListPagination",
        res.data.meta.pagination
      );

      return res;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      context.commit("setStaffLeaveApplicationListBusy", false);
    }
  },

  /**
   * Change 'All' filter.
   *
   * @param context
   * @param payload
   */
  changeStaffLeaveApplicationApprovalAllFilter(context, payload) {
    console.log(payload);

    const filters = state.filters;
    filters.base = payload.query;

    context.commit("setStaffLeaveApplicationListFilter", filters);
  },
};
