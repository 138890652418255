const ManagementDetails = () =>
  import("@/modules/company/components/leave/LeavePageDetails");
const ManagementEntitle = () =>
  import("@/modules/company/components/leave/entitlement/LeaveEntitlementPage");
const ManagementApplication = () =>
  import("@/modules/company/components/leave/application/LeavePageApplication");
const ManagementStatistics = () =>
  import("@/modules/company/components/leave/statistics/LeavePageStatistics");
const LeavePageEntitlementTable = () =>
  import(
    "@/modules/company/components/leave/entitlement/employee/LeaveEmployeeEntitlementPage"
  );
const LeavePageStatisticsList = () =>
  import(
    "@/modules/company/components/leave/statistics/LeavePageStatisticsList"
  );

export default [
  {
    path: "/company/leave/details",
    name: "managementDetails",
    component: ManagementDetails,
  },
  {
    path: "/company/leave/entitlement/employee",
    name: "LeavePageEntitlementTable",
    component: LeavePageEntitlementTable,
  },
  {
    path: "/company/components/leave/entitlement/employee",
    name: "LeavePageStatisticsList",
    component: LeavePageStatisticsList,
  },
  {
    path: "/company/leave/entitlement",
    name: "managementEntitle",
    component: ManagementEntitle,
  },
  {
    path: "/company/leave/application",
    name: "managementApplication",
    component: ManagementApplication,
  },
  {
    path: "/company/leave/statistics",
    name: "managementStatistics",
    component: ManagementStatistics,
  },
];
