export default {
  COMPANYFORM: {
    COMPANYFORM1: "Company Profile Form",
    COMPANYFULLNAME1: "Company Full Name:",
    COMPANYFULLNAME2: "Enter Company Full Name",
    COMPANYFULLNAME3: "Company Full Name Based on Registration Form.",
    COMPANYTYPE: "Company Type:",
    COMPANYREGNUM: "Company Registration Number:",
    COMPANYREGNUM1: "Enter Registration Number",
    COMPANYNAME: "Company Name:",
    COMPANYNAME1: "Enter Name",
    COMPANYDESC: "Company Description:",
    COMPANYDESC1: "Enter Description",
    COMPANYEMAIL: "Company Email:",
    COMPANYEMAIL1: "Enter Email",

    COMPANYPHONE: "Company Phone Number:",
    COMPANYPHONE1: "Enter Phone Number:",
    COMPANYADDRESS1: "Company Address 1:",
    COMPANYADDRESS12: "Enter Address",
    COMPANYADDRESS13: "Required.",
    COMPANYADDRESS2: "Company Address 2:",
    COMPANYADDRESS22: "Enter Address",
    COMPANYADDRESS23: "Optional.",
    COMPANYADDRESS3: "Company Address 3:",
    COMPANYADDRESS32: "Enter Address",
    COMPANYADDRESS33: "Optional.",
    COMPANYLOC1: "Company Locality:",
    COMPANYLOC2: "Enter Locality",
    COMPANYLOC3: "Optional.",
    COMPANYCITY1: "Company City:",
    COMPANYCITY2: "Enter City",
    COMPANYCITY3: "Required.",
    POSCODE1: "Company Postal Code:",
    POSCODE2: "Enter Postal Code",
    POSCODE3: "Required.",
    STATE: "Company State:",
  },
};
