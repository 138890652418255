export default {
  /**
   * Set the FD Teacher List items
   *
   * @param state
   * @param items
   */
  setFDTeacherList(state, items) {
    state.items = items;
  },

  /**
   * Set FD Teacher List pagination meta
   *
   * @param state
   * @param pagination
   */
  setFDTeacherListPagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   * Set FD Teacher List current page
   *
   * @param state
   * @param currentPage
   */
  setFDTeacherListPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   *
   * @param state
   * @param currentState
   * @param index
   */
  setTeacherSelectedState(state, { currentState, index }) {
    state.items[index].selected = currentState;
  },
};
