export default {
  COMPANYFORM: {
    COMPANYFORM1: "Butiran Syarikat",
    COMPANYFULLNAME1: "Nama Penuh Syarikat:",
    COMPANYFULLNAME2: "Masukkan Nama Penuh Syarikat",
    COMPANYFULLNAME3: "Nama Penuh Syarikat Berdasarkan Borang Pendaftaran.",
    COMPANYTYPE: "Jenis Syarikat:",
    COMPANYREGNUM: "Nombor Pendaftaran Syarikat:",
    COMPANYREGNUM1: "Masukkan Nombor Pendaftaran Syarikat",
    COMPANYNAME: "Nama Syarikat:",
    COMPANYNAME1: "Masukkan Nama Syarikat ",
    COMPANYDESC: "Latar Belakang Syarikat:",
    COMPANYDESC1: "Masukkan Latar Belakang",
    COMPANYEMAIL: "Emel Syarikat:",
    COMPANYEMAIL1: "Masukkan Emel",

    COMPANYPHONE: "No. Tel Syarikat:",
    COMPANYPHONE1: "Masukkan No. Tel:",
    COMPANYADDRESS1: "Alamat Syarikat 1:",
    COMPANYADDRESS12: "Masukkan Alamat",
    COMPANYADDRESS13: "Diperlukan.",
    COMPANYADDRESS2: "Alamat Syarikat 2:",
    COMPANYADDRESS22: "Masukkan Alamat",
    COMPANYADDRESS23: "Tidak diwajibkan.",
    COMPANYADDRESS3: "Alamat Syarikat 3:",
    COMPANYADDRESS32: "Masukkan Alamat",
    COMPANYADDRESS33: "Tidak diwajibkan.",
    COMPANYLOC1: "Lokaliti Syarikat:",
    COMPANYLOC2: "Masukkan Lokaliti",
    COMPANYLOC3: "Tidak diwajibkan.",
    COMPANYCITY1: "Bandar:",
    COMPANYCITY2: "Masukkan Bandar",
    COMPANYCITY3: "Diperlukan.",
    POSCODE1: "Poskod:",
    POSCODE2: "Masukkan Poskod",
    POSCODE3: "Diperlukan.",
    STATE: "Negeri:",
  },
};
