export default {
  LEAVE: {
    LEAVE: "Leave Details",
    DETAILS: "Details",
    ENTITLE: "Entitlement",
    APPROVAL: "Application Approval",
    STAT: "Statistics",
    TYPE: "Leave Type",
    ENTITLEMENT: "Entitlement",
    APPLICATION: "Application Approval",
    STATISTICS: "Statistics",
    LEAVEDETAILS: "Create Leave Type",
    LEAVETYPE: "Leave Type Details",
    LEAVEAPPLICATION: "Leave Application",
    EMPLEAVE: "Employee Leave",
    APP: "Application",
    NEW: "Create New Application",
    INDIVIDUAL: {
      TITLE: "Individual Leave",
      STAFF_TITLE: "Staff Leave",
      TEACHER: "Teacher Guru",
    },
  },
  DETAILSTABLE: {
    TYPE: "LEAVE TYPE",
    DESC: "DESCRIPTION",
    UNIT: "LEAVE UNIT",
    COUNT: "DAY COUNT",
    PAID: "PAID LEAVE",
    ACT: "ACTIONS",
  },
  DETAILSFORM: {
    TYPE: "Leave Type",
    TYPE1: "Enter Leave Type",
    DESC: "Description",
    DESC1: "(200 characters max)",
    UNIT: "Leave Unit",
    COUNT: "Day Count",
    PAID: "Paid Leave?",
  },
  ENTITLEMENT: {
    CREATE: "Create Entilement",
    DETAILS: "Entitlement Details",
    TYPE: "Leave Type",
    DAYS: "Entitled Days",
    OVER: "Carried Over",
    VALID: "Valid Until",
    BALANCE: "Balance",
    BALANCE1: "Leave balance will automatically calculated",
    REASON: "Reason",
    REASON1: "Enter Reason",
    DAY: "Days",
    SESSION: "Session",
    ATTACHMENT: "Attachment",
    PURPOSE: "For Management Purpose",
    APPLICATION: "Application Date",
    SESSION1: "Enter Session",
    APPROVE: "Approve",
    PENDING: " Applications",
    ATTACHMENT1: "Choose File",
  },
  STATISTICS: {
    WEEK: "Week",
    PENDING: "Pending",
    APPROVED: "Approved",
    TOTAL: "Total",
    WEEK1: "Week :",
    NEW: "New Application",
    MONTH: "Month",
    YEAR: "Year",
  },
  AWAY: {
    INDIVIDUAL: "Individual Away",
    PERSONAL: {
      TITLE: "Your Away Information"
    }
  },
};
