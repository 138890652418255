export default {
    EDUUSERFORM: {
        USERDETAILS: "User Details",
        NAME: "Enter First Name",
        LASTNAME: "Enter Last Name",
        EMAIL: "Enter Email",
        UNIQUECODE: "Enter Employee Code",
        STARTDATE: "Enter Membership Date",
        ADD: "Enter Address",
        LOCALITY: "Enter Locality",
        TOWN: "Enter Town",
        POSTALCODE: "Enter Postal Code",
        EMERGENCYNUMBER: "Enter Emergency Contact Number",
        EMERGENCYCONTACT: "Enter Emergency Contact Name",
        PERSONAL: "Personal",
        CREATE: "Create User",
    },
};
