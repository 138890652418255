const SchoolLeaveDetailPage = () =>
  import(
    "@/modules/school/components/management/leave/detail/SchoolLeaveDetailPage"
  );

export default [
  {
    path: "school/leave/detail",
    component: SchoolLeaveDetailPage,
    name: "schoolLeaveDetailPage",
  },
];
