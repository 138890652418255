export default {
  HOLIDAY: {
    HOLIDAY: "Cuti",
    HOLIDAY1: "Masukkan Cuti",
    DAY: "Hari",
    DATE: "Tarikh",
    CREATE: "Tambah Cuti",
    START: "Bermula dari",
    END: "Berakhir pada",

    TOOLTIP: {
      Edit: "Kemaskini cuti umum",
      Delete: "Hapuskan cuti",
    },

    Delete: {
      Title: "",
      Subtitle: "{holiday} akan dihapuskan",
      Success: "{holiday} berjaya dihapuskan",
    },

    Empty: {
      Title: "Tiada cuti umum",
      Subtitle_Primary:
        "Tiada rekod cuti umum. Sila tambah menggunakan butang TAMBAH",
    },

    Edit: {
      Success: "Berjaya kemaskini maklumat cuti umum",
      Failure: "Gagal kemaskini cuti umum",
    },

    Filter: {
      Name: "Carian cuti",
    },
  },
};
