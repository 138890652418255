import ApiService from "@/core/services/api.service";
import state from "./state";

export default {
  /**
   * Fetch individual entitlement list
   *
   * @param context
   * @param payload
   */
  fetchTeacherIndividualEntitlementList(context, payload) {
    const id = payload.id;
    console.log("Teacher id", id);

    ApiService.setHeader();
    return ApiService.query("school/leave/teacher/entitlement", {
      params: {
        id: id,
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": state.size,
      },
    }).then((res) => {
      context.commit("setTeacherIndividualEntitlementList", res.data.data);
      context.commit(
        "setTeacherIndividualEntitlementListPagination",
        res.data.meta.pagination
      );
    });
  },

  /**
   * Get the individual teacher entitlement form.
   *
   * @param context
   * @param payload
   */
  fetchTeacherIndividualEntitlementForm(context, payload) {
    ApiService.setHeader();
    return ApiService.query("school/leave/teacher/entitlement/form", {
      params: {
        id: payload.id,
      },
    }).then((res) => {
      context.commit("setTeacherIndividualEntitlementForm", res.data);
    });
  },

  /**
   * Submit form in payload to API.
   *
   * @param context
   * @param payload
   */
  submitTeacherIndividualEntitlementForm(context, payload) {
    ApiService.setHeader();
    return ApiService.post("school/leave/teacher/entitlement", payload.form);
  },

  /**
   * Fetch individual entitlement list
   *
   * @param context
   * @param payload
   */
  fetchStaffIndividualEntitlementList(context, payload) {
    const id = payload.id;
    console.log("Teacher id", id);

    ApiService.setHeader();
    return ApiService.query("school/leave/staff/entitlement", {
      params: {
        id: id,
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": state.size,
      },
    }).then((res) => {
      context.commit("setStaffIndividualEntitlementList", res.data.data);
      context.commit(
        "setStaffIndividualEntitlementListPagination",
        res.data.meta.pagination
      );
    });
  },

  /**
   * Get the individual staff entitlement form.
   *
   * @param context
   * @param payload
   */
  fetchStaffIndividualEntitlementForm(context, payload) {
    ApiService.setHeader();
    return ApiService.query("school/leave/staff/entitlement/form", {
      params: {
        id: payload.id,
      },
    }).then((res) => {
      context.commit("setStaffIndividualEntitlementForm", res.data);
    });
  },

  /**
   * Submit staff form in payload to API.
   *
   * @param context
   * @param payload
   */
  submitStaffIndividualEntitlementForm(context, payload) {
    ApiService.setHeader();
    return ApiService.post("school/leave/staff/entitlement", payload.form);
  },

};
