import JOB from "./details/job.en";
import SALARY from "./details/salary.en";
import FAMILY from "./details/family.en";
import EDUCATION from "./details/education.en";

export default {
  ...JOB,
  ...SALARY,
  ...FAMILY,
  ...EDUCATION,
  EMPLOYEESGENERAL: {
    MESSAGE: {
      FIRSTNAME: "Nama",
      LASTNAME: "Nama Keluarga",
      EMAIL: "Emel",
      UNIQUE: "Kod Unik",
      UNIQUE_VALIDATION:
        "Kod Unik tidak boleh digunakan. Pekerja lain telah menggunakan kod tersebut",
      EMPLOYEMENT: "Tarikh Mula Bekerja",
      MANAGE: "Kemaskini {employee}",
      TITLE_STAFF: "Tambah Kakitangan",
      TITLE_TEACHER: "Tambah pekerja - Guru",
      SUBTITLE: "Maklumat pekerja",
    },
  },

  EMPLOYEESCONTACT: {
    MESSAGE: {
      CONTACT: "Nombor Telefon Kecemasan",
      EMERGENCY: "Nama Kenalan",
      RELATIONSHIP: "Hubungan Dengan Pekerja",
      ADDRESS1: "Alamat 1",
      ADDRESS2: "Alamat 2",
      ADDRESS3: "Alamat 3",
      LOCALITY: "Kerakyatan",
      CITY: "Bandar",
      POSTALCODE: "Poskod",
      STATE: "Negeri",
    },
  },

  EMPLOYEESPERSONAL: {
    MESSAGE: {
      DOB: "Tarikh Lahir",
      GENDER: "Jantina",
      MARITALID: "Status Perkahwinan",
      ADDRESS1: "Alamat 1",
      ADDRESS2: "Alamat 2",
      ADDRESS3: "Alamat 3",
      LOCALITY: "Kerakyatan",
      CITY: "Bandar",
      POSTALCODE: "Poskod",
      STATE: "Negeri",
    },
  },
};
