import ApiService from "@/core/services/api.service";

// action types
export const GET_TYPE = "getType";
export const REGISTER_COMPANY = "registerCompany";

// mutation types
export const SET_ERROR = "setError";
export const SET_TYPE = "setType";
export const SET_MESSAGE = "setMessage";

const state = {
  companyType: [],
  states: [],
  message: "",
  errors: null,
};

const getters = {
  getCompanyType(state) {
    return state.companyType;
  },
  getStates(state) {
    return state.states;
  },
};

const actions = {
  [GET_TYPE](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("ref/company/type")
        .then(({ data }) => {
          console.log(data);
          context.commit(SET_TYPE, data);
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  fetchCompanyProfileForm() {
    ApiService.setHeader();
    return ApiService.get("company/profile")
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  submitCompanyProfileForm(context, form) {
    ApiService.setHeader();
    return ApiService.post("company/profile/update", form)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  [REGISTER_COMPANY](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("company/register", credentials)
        .then(({ data }) => {
          context.commit(SET_MESSAGE, data);
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          context.commit(SET_ERROR, error.response.data);
          reject(error);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    console.log(error);
    state.errors = error;
  },
  [SET_TYPE](state, data) {
    console.log(data);
    state.companyType = data.companyType;
    state.states = data.states;
  },
  [SET_MESSAGE](state, data) {
    console.log(data);
    state.message = data.message;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
