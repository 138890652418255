export default {
  /**
   *
   * @param state
   * @param items
   */
  setTeacherDashboardDailyAttendanceList(state, items) {
    console.log(items);
    state.items = items;
  },

  /**
   *
   * @param state
   * @param pagination
   */
  setTeacherDashboardDailyAttendancePagination(state, pagination) {
    console.log("Pagination", pagination);
    state.pagination = pagination;
  },

  /**
   *
   * @param state
   * @param currentPage
   */
  setTeacherDashboardDailyAttendancePage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   * Teacher filters.
   *
   * @param state
   * @param filters
   */
  setTeacherDashboardDailyAttendanceFilters(state, filters) {
    state.filters = filters;
  },

  /**
   * Set is busy
   *
   * @param state
   * @param isBusy
   */
  setTeacherDashboardDailyAttendanceBusy(state, isBusy) {
    state.isBusy = isBusy;
  },
};
