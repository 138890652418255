const SchoolReportDownloadService = {
  /**
   * Process the file download
   *
   * @param responseData
   * @param filename
   */
  processDownload(responseData, filename) {
    const url = window.URL.createObjectURL(new Blob([responseData]));
    const link = document.createElement("a");

    const type = responseData.type;
    let extension = "xlsx";
    if (type === "application/pdf") extension = "pdf";

    link.href = url;
    link.setAttribute("download", filename + "." + extension);
    document.body.appendChild(link);
    link.click();
  },
};

export default SchoolReportDownloadService;
