import UserDatabaseService from "@/modules/auth/services/user_database.service";

/**
 * @author Amsyar
 * @param loginDetails
 *
 * Save Auth Login details
 */
export const saveAuth = (loginDetails) => {
  console.log("Saving User Auth information.", loginDetails);
  return new Promise((resolve, reject) => {
    try {
      UserDatabaseService.save(loginDetails);
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const clearAuth = () => {
  console.log("Clearing User Auth Information");
  return new Promise((resolve, reject) => {
    try {
      UserDatabaseService.destroy();
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const getAuth = () => {
  return new Promise((resolve, reject) => {
    try {
      UserDatabaseService.getUserAuth().then((res) => {
        console.log(res);
        resolve(res);
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const getPermissions = () => {
  return UserDatabaseService.getUserPermissions();
};

export default {
  saveAuth,
  clearAuth,
  getAuth,
  getPermissions,
};
