export default {
  /**
   * Set Clock Record items
   *
   * @param state
   * @param items
   */
  setStaffClockRecordList(state, items) {
    console.log("Tabular items", items);
    state.items = items;
  },

  /**
   * Set clock record pagination
   *
   * @param state
   * @param pagination
   */
  setStaffClockRecordPagination(state, pagination) {
    console.log("Pagination", pagination);
    state.pagination = pagination;
  },

  /**
   * Set clock record current page
   *
   * @param state
   * @param currentPage
   */
  setStaffClockRecordPage(state, currentPage) {
    console.log("Page to change", currentPage);
    state.pagination.current_page = currentPage;
  },
};
