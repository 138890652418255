import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

const getters = {
  getStaffMonthlyAttendanceExportList: (state) => state.items,
  getStaffMonthlyAttendanceExportListPagination: (state) => state.pagination,
  getStaffMonthlyAttendanceExportListPage: (state) =>
    state.pagination.current_page,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
