import ApiService from "@/core/services/api.service";
import state from "./state";

export default {
  /**
   *
   * @param context
   * @param payload
   */
  assignEntitledLeaveApplication(context, payload) {
    console.log(payload.name);
    context.commit("setEntitledLeaveApplicationModal", payload);
  },

  /**
   * Fetch Personal Leave Application List.
   *
   * @param context
   * @returns {*}
   */
  fetchPersonalLeaveApplicationList(context) {
    ApiService.setHeader();

    return ApiService.query("school/personal/leave/application", {
      params: {
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": 20,
      },
    }).then((res) => {
      console.log(res);
      context.commit("setPersonalLeaveApplicationList", res.data.data);
      context.commit(
        "setPersonalLeaveApplicationListPagination",
        res.data.meta.pagination
      );
    });
  },
};
