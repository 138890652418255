import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getTeacherArchivedList: (state) => state.items,
  getTeacherArchivedPagination: (state) => state.pagination,
  getTeacherArchivedListPage: (state) => state.pagination.current_page,
  getTeacherArchivedListFilters: (state) => state.filters,
  getTeacherArchivedListBusy: (state) => state.isBusy,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
