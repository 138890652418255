import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getTeacherYearlyAttendanceList: (state) => state.items,
  getTeacherYearlyAttendancePagination: (state) => state.pagination,
  getTeacherYearlyAttendanceListDate: (state) => state.filter.date,
  getTeacherYearlyAttendanceListBusy: (state) => state.isBusy,
};

export default {
  actions,
  state,
  mutations,
  getters,
};
