import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getTeacherIndividualEmployeeYearlyAttendanceList: (state) => state.items,
  getTeacherIndividualEmployeeYearlyAttendanceListDate: (state) =>
    state.filter.date,
};

export default {
  actions,
  state,
  mutations,
  getters,
};
