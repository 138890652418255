export default {
    EDUUSERFORM:
    {
        USERDETAILS: "Maklumat Pengguna",
        NAME: "Masukkan Nama Pertama",
        LASTNAME: "Masukkan Nama Terakhir",
        EMAIL: "Masukkan E-mel",
        UNIQUECODE: "Masukkan Kod Pekerja",
        STARTDATE: "Masukkan Tarikh Menyertai",
        ADD: "Masukkan Alamat",
        LOCALITY: "Masukkan Lokaliti",
        TOWN: "Masukkan Bandar",
        POSTALCODE: "Masukkan Poskod",
        EMERGENCYNUMBER: "Masukkan Nombor Telefon Pewaris",
        EMERGENCYCONTACT: "Masukkan Nama Pewaris",
        PERSONAL: "Peribadi",
        CREATE: "Daftar Pengguna",

    },
    USERFORM:
        {
            USERDETAILS: "Maklumat Pengguna",
            NAME: "Masukkan Nama Pertama",
            LASTNAME: "Masukkan Nama Terakhir",
            EMAIL: "Masukkan E-mel",
            UNIQUECODE: "Masukkan Kod Pekerja",
            STARTDATE: "Masukkan Tarikh Menyertai",
            ADD: "Masukkan Alamat",
            LOCALITY: "Masukkan Lokaliti",
            TOWN: "Masukkan Bandar",
            POSTALCODE: "Masukkan Poskod",
            EMERGENCYNUMBER: "Masukkan Nombor Telefon Pewaris",
            EMERGENCYCONTACT: "Masukkan Nama Pewaris",
            PERSONAL: "Peribadi",
            CREATE: "Daftar Pengguna",

        },

};
