export default {
  /**
   *
   * @param state
   * @param counts
   */
  setTeacherDashboardDailyAttendanceCount(state, counts) {
    state.counts = counts;
  },
};
