import ApiService from "@/core/services/api.service";
import Vue from "vue";

const state = {
  list: [],
  meta: {},
  pagination: {
    current_page: 1,
  },
  currentPage: 2,
  isBusy: false,
  isSuccess: true,
  group: {},
};

function extracted() {
  console.log("List", state.list);

  let school_employee = [];
  state.list.forEach((em) => {
    console.log(em);
    if (em.selected) {
      school_employee.push(em.id);
    }
  });
  console.log(school_employee);
  return school_employee;
}

const actions = {
  /**
   * Fetch the current group members.
   * @param context
   * @param payload
   * @returns {Promise<boolean>}
   */
  fetchSchoolEmployeeGroupMembers(context, payload) {
    ApiService.setHeader();
    state.isBusy = true;
    return Vue.axios
      .get("school/group/employees", {
        params: {
          "page[number]": state.meta.currentPage ?? 1,
          id: state.group.id,
          employee_type: payload.type,
        },
      })
      .then((res) => {
        context.commit("commitGroupMembers", res);
      })
      .finally(() => (state.isBusy = false));
  },

  /**
   * Get the available staff not yet saved in a group.
   * @param context
   * @param payload
   * @returns {Promise<boolean>}
   */
  fetchSchoolEmployeeAvailableGroupMembers(context, payload) {
    ApiService.setHeader();
    state.isBusy = true;
    console.log("current page", state.pagination.current_page);
    return Vue.axios
      .get("school/group/employees/not", {
        params: {
          "page[number]": state.pagination.current_page ?? 1,
          id: state.group.id,
          employee_type: payload.type,
        },
      })
      .then((res) => {
        context.commit("commitAvailableGroupMembers", res);
      })
      .finally(() => (state.isBusy = false));
  },

  /**
   * Save the selected group members.
   * @returns {*}
   */
  saveSchoolEmployeeGroupMembers(context) {
    const school_employee = extracted();
    ApiService.setHeader();

    return ApiService.post("school/group/employees", {
      school_employee,
      id: state.group.id,
    }).then(() => context.commit("setGroupMembershipSuccess"));
  },

  /**
   * Delete the selected staff members
   * @returns {*}
   */
  deleteSchoolEmployeeGroupMembers(context) {
    const school_employee = extracted();

    ApiService.setHeader();
    return ApiService.delete("school/group/employees", {
      params: {
        school_employee: school_employee,
        id: state.group.id,
      },
    }).then(() => context.commit("setGroupMembershipSuccess"));
  },

  /**
   * Set staff item to be selected (delete, add).
   *
   * @param context
   * @param payload
   */
  selectSchoolEmployeeGroupMemberModification(context, payload) {
    console.log("Info : ", context, payload);

    context.commit("switchGroupMemberSelection", payload.id);
  },
};

const mutations = {
  /**
   * Commit.
   *
   * @param state
   * @param res
   */
  commitGroupMembers(state, res) {
    const members = res.data.data;
    console.log("Commit", members);
    state.list = members;
  },

  /**
   * Commit available.
   *
   * @param state
   * @param res
   */
  commitAvailableGroupMembers(state, res) {
    const members = res.data.data;
    console.log("Commit", members);
    state.list = members;
    state.pagination = res.data.meta.pagination;
    console.log(state.pagination);
  },

  /**
   * Set group.
   *
   * @param state
   * @param group
   */
  setGroup(state, group) {
    state.group = group;
    console.log(state.group);
  },

  /**
   * Set membership success.
   *
   * @param state
   */
  setGroupMembershipSuccess(state) {
    state.isSuccess = true;
  },

  /**
   * Flip switch.
   *
   * @param state
   * @param employeeId
   */
  switchGroupMemberSelection(state, employeeId) {
    console.log("Info: ", state, employeeId);

    const index = state.list.findIndex((e) => e.id === employeeId);
    console.log("Matched row: ", state.list[index]);

    state.list[index].selected = !state.list[index].selected;
    console.log("Changed row: ", state.list[index]);
  },
};

const getters = {
  getSchoolEmployeeMembershipListBusy: (state) => {
    console.log("BUSY TAK?", state.isBusy);
    return state.isBusy;
  },
  getSchoolEmployeeMembershipList: (state) => state.list,
  getSchoolEmployeeMembershipPagination: (state) => state.pagination,
  getSchoolEmployeeMembershipEmpty: (state) => {
    return state.list.length <= 0;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
