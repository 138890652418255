import Daily from "./daily/staff_daily_attendance.module";
import Monthly from "./monthly/staff_attendance_monthly.module";
import Clock from "./clock/staff_clock_record.module";
import Manage from "./manage/staff_attendance_manage.module";
import Export from "./export/staff_export.store";
import Yearly from "./yearly/staff_attendance_yearly.store";

export default {
  modules: {
    Daily,
    Clock,
    Manage,
    Monthly,
    Export,
    Yearly
  },
};
