import state from "./state";
import mutations from "./mutations";
import actions from "./action";

const getters = {
  getSchoolManagementBusy: (state) => state.isBusy,
  getSchoolManagementForm: (state) => state.form,
};

export default { state, actions, mutations, getters };
