export default {
  /**
   *
   * @param state
   * @param data
   */
  setTeacherList(state, data) {
    state.items = data;
  },

  /**
   * Set Teacher's List Pagination Meta
   *
   * @param state
   * @param pagination
   */
  setTeacherListPagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   * Set Teacher's List Current Page
   *
   * @param state
   * @param currentPage
   */
  setTeacherListPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   * Change filter
   *
   * @param state
   * @param filters
   */
  setTeacherListFilter(state, filters) {
    console.log(state);

    state.filters = filters;
  },

  /**
   *
   * @param state
   * @param isBusy
   */
  setTeacherListBusy(state, isBusy) {
    state.isBusy = isBusy;
  },
};
