import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getStaffAwayApplicationApprovalList: (state) => state.items,
  getStaffAwayApplicationApprovalPagination: (state) => state.pagination,
  getStaffAwayApplicationApprovalPage: (state) => state.pagination.current_page,
  getStaffAwayApplicationApprovalBusy: (state) => state.isBusy,
};

export default {
  actions,
  mutations,
  state,
  getters,
};
