import i18n from "@/core/plugins/vue-i18n";

export default [
  {
    text: i18n.t("SIDEBAR.EDU.REGISTRATION"),
    type: "menu-section",
    url: {},
    guard: {
      permissions: ["view.manage.school.employee"],
    },
  },

  {
    text: i18n.t("SIDEBAR.TEACHER"),
    type: "menu-nav-parent",
    url: {
      path: "school/teacher",
    },
    icon: "/media/svg/icons/Communication/Add-user.svg",
    guard: {
      permissions: ["view.manage.school.employee"],
    },
    children: [
      {
        text: i18n.t("SIDEBAR.LIST"),
        type: "menu-nav",
        url: {
          name: "teacherActiveList",
        },
        guard: {
          permissions: ["view.manage.school.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },

      {
        text: i18n.t("SIDEBAR.GROUP"),
        type: "menu-nav",
        url: {
          name: "teacherGroup",
        },
        guard: {
          permissions: ["view.manage.school.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
      {
        text: i18n.t("SIDEBAR.ROLE"),
        type: "menu-nav",
        url: {
          name: "teacherAdmin",
        },
        guard: {
          permissions: ["view.manage.school.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
    ],
  },

  {
    text: i18n.t("SIDEBAR.STAFF"),
    type: "menu-nav-parent",
    url: {
      path: "school/staff",
    },
    icon: "/media/svg/icons/Communication/Add-user.svg",
    guard: {
      permissions: ["view.manage.school.employee"],
    },
    children: [
      {
        text: i18n.t("SIDEBAR.LIST"),
        type: "menu-nav",
        url: {
          name: "staffActiveList",
        },
        guard: {
          permissions: ["view.manage.school.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },

      {
        text: i18n.t("SIDEBAR.GROUP"),
        type: "menu-nav",
        url: {
          name: "staffGroup",
        },
        guard: {
          permissions: ["view.manage.school.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },

      {
        text: i18n.t("SIDEBAR.ROLE"),
        type: "menu-nav",
        url: {
          name: "staffAdmin",
        },
        guard: {
          permissions: ["view.manage.school.employee"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
    ],
  },
];
