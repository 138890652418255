import ApiService from "@/core/services/api.service";

const state = {};

const actions = {
  setEmployeeAsAdmin(context, data) {
    data.role_code = "admin";
    ApiService.setHeader();
    return ApiService.post("employee/privilege/assign", data);
  },
};

const mutations = {};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
