export default {
  /**
   *
   * @param state
   * @param away
   */
  setEntitledAwayApplicationModal(state, away) {
    console.log("Hmm", away);
    state.away = away;
  },

  /**
   *
   * @param state
   * @param items
   */
  setPersonalAwayApplicationList(state, items) {
    console.log(items);

    state.items = items;
  },

  /**
   * Set personal away application list pagination.
   *
   * @param state
   * @param pagination
   */
  setPersonalAwayApplicationListPagination(state, pagination) {
    console.log(pagination);

    state.pagination = pagination;
  },

  /**
   * Set personal away application list current page.
   *
   * @param state
   * @param page
   */
  setPersonalAwayApplicationListPage(state, page) {
    console.log(page);

    state.pagination.current_page = page;
  },
};
