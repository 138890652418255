export default {
  REGISTER: {
    HAVE: "Sudah Mempunyai Akaun?",
    ENTER: "Masukkan Butiran Untuk Mencipta Akaun",
    REQUIRED: "Nama Diperlukan",
    REQUIRED2: "Emel Yang Sah Diperlukan",
    REQUIRED3: "Emel Tempat Kerja Diperlukan",
    NUMBER: "Masukkan Nombor Sahaja",
    SIZE: "Saiz Syarikat",
    WORK: "Emel Syarikat",
    EMPLOYER: "Majikan",
  },
};
