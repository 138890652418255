import ApiService from "@/core/services/api.service";
import state from "@/modules/school/store/attendance/teacher/export/state";
import dayjs from "dayjs";

export default {
  /**
   * Fetch staff monthly export list.
   *
   * @param context
   */
  fetchStaffYearlyAttendanceExportList(context) {
    ApiService.setHeader();

    return ApiService.query("school/attendance/staff/yearly/report/detailed", {
      params: {
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": 150,
        date: state.filter.date ?? dayjs().format(),
      },
    }).then((res) => {
      console.log(res);
      context.commit("setStaffYearlyAttendanceExportList", res.data.data);
      context.commit(
        "setStaffYearlyAttendanceExportListPagination",
        res.data.meta.pagination
      );

      return res;
    });
  },
};
