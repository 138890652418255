import Form from "@/modules/school/store/staff/group/schedule/form/staff_group_schedule_form.store";
import List from "@/modules/school/store/staff/group/schedule/list/staff_group_schedule_list.store";
import Delete from "./delete/delete.store";

export default {
    modules: {
        Form,
        List,
        Delete,
    },
};
