export default {
  id: 1,
  items: [],
  pagination: {},
  size: 30,
  isBusy: false,
  filters: {
    name: null,
    serial: null,
    purchase_date: null,
  },
};
