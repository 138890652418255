import ApiService from "@/core/services/api.service";

const state = {
  list: [],
  pagination: {},
};

const actions = {
  /**
   * @param context
   */
  fetchCompanyPositionList(context) {
    ApiService.setHeader();
    ApiService.query("company/settings/positions", {
      params: {
        "page[size]": 30,
        "page[number]": state.pagination.current_page ?? 1,
      },
    }).then((res) => {
      context.commit("setCompanyPositionList", res.data.data);
      context.commit("setCompanyPositionListPagination", res.data.meta);
    });
  },
};

const mutations = {
  /**
   * @param state
   * @param data
   */
  setCompanyPositionList(state, data) {
    state.list = data;
  },
  /**
   *
   * @param state
   * @param meta
   */
  setCompanyPositionListPagination(state, meta) {
    state.pagination = meta;
  },
};

const getters = {
  getCompanyPositionList: (state) => state.list,
  getCompanyPositionListPagination: (state) => state.pagination,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
