import ApiService from "@/core/services/api.service";

const state = {};

const actions = {
  async resendVerificationEmail(context, employeeId) {
    ApiService.setHeader();
    return ApiService.post("employee/verify/renew", {
      employee_id: employeeId,
    })
      .then((res) => {
        console.log(res.data);
        context.commit("setEmployeeEmail");
      })
      .catch((reason) => {
        console.error(reason);
      });
  },
};

const mutations = {
  setEmployeeEmail(state) {
    console.log(state);
  },
};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
