import Daily from "./daily/teacher_daily_attendance.module";
import Manage from "./manage/teacher_attendance__manage.store";
import Clock from "./clock/teacher_clock_record.module";
import Monthly from "./monthly/teacher_attendance_monthly.module";
import Export from "./export/teacher_export.store";
import Yearly from "./yearly/teacher_attendance_yearly.store";

export default {
  modules: {
    Daily,
    Monthly,
    Manage,
    Clock,
    Export,
    Yearly,
  },
};
