import ApiService from "@/core/services/api.service";

export default {
  /**
   * Attempt to delete group schedule using GROUP ID.
   *
   * @param context
   * @param payload
   */
  deleteStaffGroupSchedule(context, payload) {
    console.log("Payload is: ", payload);

    ApiService.setHeader();
    return ApiService.delete("school/group/schedule", {
      params: {
        id: payload.id,
      },
    });
  },
};
