import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getTeacherGroupMemberInList: (state) => state.items,
  getTeacherGroupMemberInListPagination: (state) => state.pagination,
  getTeacherGroupMemberInListPage: (state) => state.pagination.current_page,
  getTeacherGroupMemberInListFilters: (state) => state.filters,
  getTeacherGroupMemberInListEmpty: (state) => {
    return state.list.length <= 0;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
