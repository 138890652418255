import FORM from "./form.en";
import HOLIDAY from "./holiday.en";

export default {
  ...FORM,
  COMPANY: {
    REGISTRATION: {
      GENERAL: "General",
      CONTACT: "Contacts",
      RESET: "Reset",
      SUBMIT: "Submit",
    },
  },
  ...HOLIDAY,
};
