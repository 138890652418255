export default {
  /**
   *
   * @param state
   * @param items
   */
  setTeacherDailyAttendanceList(state, items) {
    console.log(items);
    state.items = items;
  },

  /**
   *
   * @param state
   * @param pagination
   */
  setTeacherDailyAttendancePagination(state, pagination) {
    console.log("Pagination", pagination);
    state.pagination = pagination;
  },

  /**
   *
   * @param state
   * @param currentPage
   */
  setTeacherDailyAttendancePage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   * Teacher filters.
   *
   * @param state
   * @param filters
   */
  setTeacherDailyAttendanceFilters(state, filters) {
    state.filters = filters;
  },

  /**
   *
   * @param state
   * @param busy
   */
  setTeacherDailyAttendanceBusy(state, busy) {
    state.isBusy = busy;
  },
};
