import ApiService from "@/core/services/api.service";

export default {
  /**
   * Fetch school staff count.
   *
   * @param context
   */
  fetchStaffDashboardCount(context) {
    ApiService.setHeader();

    return ApiService.query("school/dashboard/staff").then((res) => {
      console.log(res);
      context.commit("setStaffDashboardCount", res.data.count);
    });
  },
};
