import state from "./state";
import ApiService from "@/core/services/api.service";

export default {
  /**
   * Fetch holiday list.
   *
   * @param context
   * @returns {*}
   */
  async fetchSchoolHolidayList(context) {
    ApiService.setHeader();
    const res = await ApiService.query("school/holiday/management", {
      params: {
        "page[size]": 10,
        "page[number]": state.pagination.current_page ?? 1,
        "filter[holidayPeriod.name]": state.filters?.name,
        "filter[from_date]": state.filters?.from_date,
        "filter[to_date]": state.filters?.to_date,
      },
    });

    context.commit("setSchoolHolidayList", res.data.data);
    context.commit("setSchoolHolidayListPagination", res.data.meta.pagination);
    return res;
  },

  /**
   * Filter change.
   *
   * @param context
   * @param payload has the payload property.
   */
  changeSchoolHolidayListFilters(context, payload) {
    context.commit("setSchoolHolidayListFilters", payload.filters);
  },
};
