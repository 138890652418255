export default [
  {
    path: "school/leave/staff/assignment",
    name: "staffAssignmentPage",
    component: () =>
      import(
        "../../../../components/management/leave/assignment/staff/StaffIndividualLeaveAssignmentPage"
      ),
  },
];
