import actions from "./actions";
import mutations from "./mutations";
import state from "./state";

const getters = {
  getIndividualStaffLeaveList: (state) => state.items,
  getIndividualStaffLeaveListPagination: (state) => state.pagination,
  getIndividualTeacherLeaveList: (state) => state.items,
  getIndividualTeacherLeaveListPagination: (state) => state.pagination,
};

export default {
  actions,
  mutations,
  state,
  getters,
};
