import ApiService from "@/core/services/api.service";
import state from "./state";
import dayjs from "dayjs";

export default {
  /**
   * Fetch individual staff away list.
   *
   * @param context
   * @returns {*}
   */
  fetchIndividualStaffAwayList(context) {
    ApiService.setHeader();

    return ApiService.query("school/away/application/staff/individual", {
      params: {
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": state.size,
        date: state.filters.date,
        id: state.filters.staffId,
      },
    }).then((res) => {
      console.log(res);
      context.commit("setIndividualStaffAwayList", res.data.data);
      context.commit(
        "setIndividualStaffAwayListPagination",
        res.data.meta.pagination
      );
    });
  },

  /**
   * Fetch individual staff away list.
   *
   * @param context
   * @returns {*}
   */
  fetchIndividualTeacherAwayList(context) {
    ApiService.setHeader();

    return ApiService.query("school/away/application/teacher/individual", {
      params: {
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": state.size,
        date: state.filters.date,
        id: state.filters.staffId,
      },
    }).then((res) => {
      console.log(res);
      context.commit("setIndividualStaffAwayList", res.data.data);
      context.commit(
        "setIndividualStaffAwayListPagination",
        res.data.meta.pagination
      );
    });
  },

  /**
   * Change the filter.
   *
   * @param context
   * @param payload
   */
  changeIndividualStaffAwayListFilter(context, payload) {
    console.log("Payload", payload);
    const filters = {
      date: payload.date ?? dayjs().format(),
      staffId: payload.staffId ?? null,
    };

    context.commit("setIndividualStaffAwayListFilters", filters);
  },
};
