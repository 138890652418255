import state from "./state";
import ApiService from "@/core/services/api.service";

export default {
  /**
   * For teacher list in selected device
   * @param context
   * @param payload
   * @returns {*}
   */
  fetchFDTeacherListIn(context, payload) {
    ApiService.setHeader();
    return ApiService.query("school/device/facial/teacher", {
      params: {
        id: payload.id,
        "page[size]": 10,
        "page[number]": state.pagination.current_page ?? 1,
      },
    }).then((res) => {
      context.commit("setFDTeacherList", res.data.data);
      context.commit("setFDTeacherListPagination", res.data.meta.pagination);
      return res;
    });
  },

  /**
   * For teacher list not in selected device
   * @param context
   * @param payload
   * @returns {*}
   */
  fetchFDTeacherListNotIn(context, payload) {
    ApiService.setHeader();
    return ApiService.query("school/device/facial/teacher/not", {
      params: {
        id: payload.id,
        "page[size]": 10,
        "page[number]": state.pagination.current_page ?? 1,
      },
    }).then((res) => {
      context.commit("setFDTeacherList", res.data.data);
      context.commit("setFDTeacherListPagination", res.data.meta.pagination);
      return res;
    });
  },

  /**
   * Change Selected State
   * @param context
   * @param payload
   */
  changeTeacherSelectedStatus(context, payload) {
    console.log("Was here");
    const index = state.items.findIndex((e) => e.id === payload.id);
    const currentState = payload.currentState;

    context.commit("setTeacherSelectedState", { currentState, index });

    console.log(
      "Was Changed: ",
      state.items[index].id,
      state.items[index].selected
    );
  },
};
