import FACIAL from "./facial.ms";
import BEACON from "./beacon.ms";

export default {
  DEVICE: {
    ...FACIAL,
  },
  ...BEACON,
  DEVICEFORM: {
    HEADER: "Tambah Peranti Muka Baru",
    FORM: "Butiran Peranti Muka",
    SERIALNUM: "Nombor Siri",
    NAME1: "Masukkan Nama Peranti Muka",
    SERIALNUM1: "Masukkan Nombor Siri",
    DATEPURCHASED: "Tarikh Daftar Peranti Muka",
  },
  DEVICEEMPLOYEE: {
    HEADER: "Senarai Pekerja",
    IN: "In",
    LIST: "Senarai",
    STATUS: "Tiada Senarai Pekerja",
    STATUS1: "Piada Senarai Peranti",
    EMPLOYEECODE: "Kod Pekerja",
    SYNC: "Selarikan",
    SAVE: "Simpan",
  },
};
