import ApiService from "@/core/services/api.service";
import state from "./state";
import dayjs from "dayjs";

function extracted(items) {
  const counts = state.counts;

  items.forEach((i) => {
    if (i.status === 1) {
      counts.presentCount = i.total;
    } else if (i.status === 2) {
      counts.absentCount = i.total;
    } else if (i.status === 3) {
      counts.leaveCount = i.total;
    } else if (i.status === 4) {
      counts.awayCount = i.total;
    } else if (i.status === 5) {
      counts.holidayCount = i.total;
    } else if (i.status === 6) {
      counts.offdaysCount = i.total;
    }
  });

  return counts;
}

export default {
  /**
   * Fetch school staff count.
   *
   * @param context
   */
  fetchStaffDashboardDailyAttendanceCount(context) {
    ApiService.setHeader();

    return ApiService.query("school/dashboard/staff/attendance/daily", {
      params: {
        date: dayjs().format(),
      },
    }).then((res) => {
      console.log(res);

      const items = res.data;
      const counts = extracted(items);

      console.log("Staff Daily Count ", counts);

      context.commit("setStaffDashboardDailyAttendanceCount", counts);
    });
  },
};
