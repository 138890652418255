import ApiService from "@/core/services/api.service";

const state = {
  list: [],
  employee: null,
  isBusy: {
    create: false,
    list: false,
  },
  date: new Date(),
};

const actions = {
  /**
   * @param context
   * @param employee
   */
  assignIndividualEmployeeAttendance(context, employee) {
    context.commit("setAssignedIndividualEmployeeAttendance", employee);
  },

  /**
   * @param context
   * @param date
   */
  assignIndividualEmployeeAttendanceDate(context, date) {
    context.commit("setAssignedIndividualEmployeeAttendanceDate", date);
  },

  /**
   * @param context
   */
  fetchIndividualEmployeeAttendance(context) {
    console.log("Fetching");
    ApiService.setHeader();
    return ApiService.get(
      "employee/attendance/monthly/individual",
      "?id=" + state.employee.id
    ).then((response) => {
      context.commit("setIndividualEmployeeAttendance", response.data.data);
    });
  },
};

const mutations = {
  /**
   * @param state
   * @param employee
   */
  setAssignedIndividualEmployeeAttendance(state, employee) {
    console.log("Selected employee is", employee);
    state.employee = employee;
  },

  setAssignedIndividualEmployeeAttendanceDate(state, date) {
    console.log(date);
    state.date = date;
  },

  /**
   * @param state
   * @param data
   */
  setIndividualEmployeeAttendance(state, data) {
    console.log("List of attendance", data);
    state.list = data;
  },
};

const getters = {
  getIndividualEmployeeAttendanceList: (state) => state.list,
  getAssignedIndividualEmployeeAttendance: (state) => state.employee,
  getAssignedIndividualEmployeeAttendanceDate: (state) => state.date,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
