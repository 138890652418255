import TeacherGroupForm from "@/modules/school/store/teacher/group/teacher_group_form.store";
import TeacherGroupList from "@/modules/school/store/teacher/group/teacher_group_list.store";
import TeacherGroupEdit from "@/modules/school/store/teacher/group/teacher_group_edit.store";
import TeacherGroupScheduleStore from "@/modules/school/store/teacher/group/schedule/teacher_group_schedule.store";
import TeacherGroupMembershipScheduleStore from "./membership/membership_store";

const state = {
  items: [],
};

const actions = {};

const getters = {};

export default {
  state,
  actions,
  getters,
  modules: {
    TeacherGroupForm,
    TeacherGroupList,
    TeacherGroupEdit,
    TeacherGroupScheduleStore,
    TeacherGroupMembershipScheduleStore,
  },
};
