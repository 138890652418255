import EmployeeVerification from "@/modules/auth/store/verification/employee_verification.store";
import AuthProfile from "@/modules/auth/store/profile/auth_profile.store";
import ResetPasswordStore from "@/modules/auth/store/password/reset_password.store";

export default {
  modules: {
    EmployeeVerification,
    AuthProfile,
    ResetPasswordStore,
  },
};
