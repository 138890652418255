import SIDEBAR from "./sidebar.ms";
import MENU from "./menu.ms";
import ECOMMERCE from "./ecommerce.ms";

export default {
  ...SIDEBAR,
  ...MENU,
  ...ECOMMERCE,
  BUTTON: {
    CANCEL: "Batal",
    OKAY: "Ya",
    YES: "Ya",
    APPLY: "Mohon",
    UNVERIFIED: "Belum Disahkan",
    UPLOAD: "Muat Naik",
    PHOTO: "Gambar Profil",
    REJECT: "Tolak Permohonan",
  },
  NOTIFICATION: {
    USER: "Notifikasi Pengguna",
    MESSAGE: "Mesej",
    EVENTS: "Aktiviti",
  },
  FOOTER: {
    ABOUT: "Perihal",
    CONTACT: "Hubungi Kami",
  },
  ALERT: {
    SURE: "Anda Pasti?",
    WARNING: "Butiran Akan Dipadam!",
    SUCCESS: "Butiran Berjaya Disimpan",
    ALERT: "Diperlukan!",
    EMAIL: "Hantar emel pengesahan kepada ",
  },
};
