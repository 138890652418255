import Vue from "vue";
import Router from "vue-router";
import AuthRoutes from "@/modules/auth/router/auth.router";
import CoreRoutes from "@/modules/core/router/core";

Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [...AuthRoutes, ...CoreRoutes],
});

// router.beforeEach((to, from, next) => {
//     if (to.matched.some((record) => record.meta.requiresAuth)) {
//         if (JwtService.getToken() == null) {
//             next('/login')
//         }
//     } else {
//         next()
//     }
// })

export default router;
