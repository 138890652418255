import dayjs from "dayjs";

export default {
  items: [],
  pagination: {},
  size: 15,
  isBusy: false,
  filters: {
    first_name: null,
    last_name: null,
    from_date: null,
    to_date: null,
    status: null,
    date: dayjs().format(),
  },
};
