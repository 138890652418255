import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getStaffDashboardDailyAttendanceCount: (state) => state.counts,
};

export default {
  actions,
  state,
  mutations,
  getters,
};
