export default {
  COMMON: {
    TOOLTIP: {
      REFRESH: "Refresh",
      Search: "Search using query",
      Config: "Advanced Table Settings",
    },

    Table: {
      Actions: {
        Base: "Actions",
        Reset_Filters: "Reset Filters",
        Export: "Export - {type}",
        Export_Excel: "Export - Excel",
        Export_PDF: "Export - Excel",
      },
    },
  },
};
