import ApiService from "@/core/services/api.service";

export default {
  /**
   * Submit Teacher manual attendance entry form
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  submitTeacherManualAttendance(context, payload) {
    ApiService.setHeader();
    return ApiService.post("school/attendance/teacher/manage", payload.form)
      .then(() => {
        console.log("Updated ", payload.form);
      })
      .finally(() => context.commit("holdEmpAttStatusEditBusy"));
  },
};
