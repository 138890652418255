const FacialDevicePage = () =>
  import("../../../components/device/facial/SchoolFacialDevicePage");

export default [
  {
    path: "school/management/device/facial",
    component: FacialDevicePage,
    name: "facialDeviceBase",
  },
];
