const state = {};

const actions = {};

const mutations = {};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
