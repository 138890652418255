import ApiService from "@/core/services/api.service";
import ImageUploadChunkService from "@/modules/core/services/file/image/image_upload_chunk.service";

export default {
  /**
   * Attempts to upload Staff Image
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  async uploadPersonalImage(context, payload) {
    try {
      ApiService.setHeader();

      const chunks = ImageUploadChunkService.generateChunks(payload.image);
      console.log("Chunks", chunks);

      let counter = 0;
      let response;
      const fileName = require("crypto").randomBytes(64).toString("hex");


      for (const chunk of chunks) {
        let formData = new FormData();

        formData.set(
          "is_last",
          counter === chunks.length - 1 ? "true" : "false"
        );
        formData.set("image", chunk, `${fileName}.part`);

        response = await ApiService.post(
          "school/personal/profile/image",
          formData,
          ImageUploadChunkService.getConfig()
        );

        counter++;
      }

      context.commit("setUploadEmployeeImage", response.data.url);
      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
};
