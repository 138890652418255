import state from "@/modules/school/store/staff/group/membership/not/state";
import ApiService from "@/core/services/api.service";

function extract() {
  console.log("List", state.items);

  let school_employee = [];
  state.items.forEach((em) => {
    console.log(em);
    if (em.selected) {
      school_employee.push(em.id);
    }
  });
  console.log(school_employee);
  return school_employee;
}

const actions = {
  /**
   *
   * @returns {*}
   */
  saveStaffGroupMembers(context, payload) {
    const school_employee = extract();

    ApiService.setHeader();
    return ApiService.post("school/group/employees", {
      school_employee: school_employee,
      id: payload.id,
    }).then((response) => response);
  },
};
export default {
  state,
  actions,
};
