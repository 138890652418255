const state = {
  items: [
    {
      id: 1,
      employee: "Siti Zulaikha",
      type: "Annual",
      from: "15/01/2021",
      to: "18/01/2021",
      day: "7",
      reason: "On vacation",
      status: "Approved",
    },
    {
      id: 1,
      employee: "Nur Athirah",
      type: "Annual",
      from: "18/01/2021",
      to: "24/01/2021",
      day: "7",
      reason: "On vacation",
      status: "Rejected",
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getCompanyLeaveApplication: (state) => state.items,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
