import ApiService from "@/core/services/api.service";
import state from "./state";
import dayjs from "dayjs";

export default {
  /**
   * Fetch teacher monthly attendance export list
   *
   * @param context
   * @returns {*}
   */
  fetchTeacherMonthlyAttendanceExportList(context) {
    ApiService.setHeader();
    return ApiService.query(
      "school/attendance/teacher/monthly/report/detailed",
      {
        params: {
          "page[number]": state.pagination.current_page ?? 1,
          "page[size]": 150,
          date: state.filter.date ?? dayjs().format(),
        },
      }
    ).then((res) => {
      console.log(res);
      context.commit("setTeacherMonthlyAttendanceExportList", res.data.data);
      context.commit(
        "setTeacherMonthlyAttendanceExportListPagination",
        res.data.meta.pagination
      );
    });
  },
};
