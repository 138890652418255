import ApiService from "@/core/services/api.service";

const state = {
  toSave: 0,
  isBusy: false,
  id: null,
  group: {
    id: null,
    name: null,
    description: null,
  },
};

const actions = {
  specifyEmpGroupEdit(context, group) {
    context.commit("setEmpGroupEdit", group);
  },

  updateEmployeeGroupForm(context, form) {
    context.commit("holdEmpGroupEditBusy");
    ApiService.setHeader();
    return ApiService.post("group/record/update", form)
      .then(() => console.log("Updated ", form))
      .finally(() => context.commit("holdEmpGroupEditBusy"));
  },

  engageUpdateEmployeeGroupForm(context) {
    let count = state.toSave;
    console.log(count);
    context.commit("setUpdateEmpGroupFormSubmission", ++count);
  },
};

const mutations = {
  setEmpGroupEdit(state, group) {
    state.group = group;
  },
  setUpdateEmpGroupFormSubmission(context, saveState) {
    state.toSave = saveState;
  },
  holdEmpGroupEditBusy(state) {
    state.isBusy = !state.isBusy;
  },
};

const getters = {
  getGroupInformation: (state) => state.group,
  getHeldEmpEditGroup: (state) => state.isBusy,
  checkUpdateEmpGroupScheduleEvent: (state) => state.toSave,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
