import ApiService from "@/core/services/api.service";

const state = {};

const actions = {
  sendResetPassword(context, email) {
    ApiService.setHeader();
    return ApiService.post("auth/password/school/reset", {
      email,
    });
  },
  sendResetNewPassword(context, form) {
    ApiService.setHeader();
    return ApiService.post("auth/password/reset/new", form);
  },
};

const mutations = {};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
