import TeacherEntitlementRouter from "./entitlement/teacher_entitlement.router";
import StaffEntitlementRouter from "./entitlement/staff_entitlement.router";

const LeaveEntitlement = () =>
  import(
    "../../../components/management/leave/entitlement/SchoolLeaveEntitlementPage"
  );

const TeacherEntitlement = () =>
  import(
    "../../../components/management/leave/entitlement/teacher/TeacherLeaveEntitlement"
  );

const StaffEntitlement = () =>
  import(
    "../../../components/management/leave/entitlement/staff/StaffLeaveEntitlement"
  );

export default [
  {
    path: "school/leave/entitlement",
    component: LeaveEntitlement,
    children: [
      {
        path: "teacher",
        name: "schoolLeaveEntitlement",
        component: TeacherEntitlement,
      },
      {
        path: "staff",
        name: "staffLeaveEntitlement",
        component: StaffEntitlement,
      },
    ],
  },
  ...TeacherEntitlementRouter,
  ...StaffEntitlementRouter,
];
