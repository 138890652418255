export default {
  FAMILY: {
    FAMILY: "Family",
    MARITAL: "Marital Status",
    MARITAL1: "Enter Marital Status",
    NUMBER: "Number of Children",
    NUMBER1: "Enter Number of Children",
    SPOUSE: "Spouse Details",
    NATIONALITY: "Nationality",
    NATIONALITY1: "Enter Nationality",
    NRIC: "NRIC",
    NRIC1: "Enter NRIC",
    PASSPORT: "Passport",
    PASSPORT1: "Enter Passport",
    CHILDREN: "Children Details",
    DETAILS: "Family Details",
  },
};
