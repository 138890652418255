const FacialDevice = () =>
  import("@/modules/device/components/facial/FacialDevicePage");
const DeviceBeacon = () =>
  import("@/modules/device/components/facial/DeviceBeaconPage");

export default [
  {
    path: "/device/facial",
    name: "facialDevice",
    component: FacialDevice,
  },
  {
    path: "/device/beacon",
    name: "deviceBeacon",
    component: DeviceBeacon,
  },
];
