const DailyAttendance = () =>
  import(
    "../../../components/management/attendance/staff/StaffDailyAttendance"
  );

const MonthlyAttendance = () =>
  import(
    "../../../components/management/attendance/staff/StaffMonthlyAttendance"
  );

const YearlyAttendance = () =>
  import(
    "../../../components/management/attendance/staff/StaffYearlyAttendance"
  );

export default [
  { path: "daily", component: DailyAttendance, name: "attendanceStaff" },
  {
    path: "monthly",
    component: MonthlyAttendance,
    name: "attendanceStaffMonthly",
  },
  {
    path: "yearly",
    component: YearlyAttendance,
    name: "attendanceStaffYearly",
  },
];
