import ApiService from "@/core/services/api.service";

const state = {
  meta: {},
  groups: [],
  response: {},
  isBusy: false,
};

const actions = {
  /**
   * @param context
   */
  getTeacherGroup(context) {
    state.isBusy = true;
    context.commit("clearTeacherGroup");
    ApiService.setHeader();

    console.log("State :", state);

    ApiService.query("/school/group/management", {
      params: {
        "page[number]": state.meta.current_page ?? 1,
        "page[size]": 20,
        id: 1,
      },
    })
      .then((response) => {
        context.commit("setTeacherGroup", response);
        context.commit(
          "setTeacherGroupPagination",
          response.data.meta.pagination
        );
      })
      .finally(() => (state.isBusy = false));
  },

  /**
   * Delete Teacher group using GROUP ID.
   *
   * @param context
   * @param payload
   */
  deleteTeacherGroup(context, payload) {
    ApiService.setHeader();

    const id = payload.id;
    console.log("ID", id);
    return ApiService.delete("school/group/management", {
      params: {
        id: id,
      },
    });
  },
};

const mutations = {
  /**
   * Set emp. group.
   *
   * @param state
   * @param response
   */
  setTeacherGroup(state, response) {
    state.response = response;
    state.groups = response.data.data;
  },

  /**
   * Set group pagination.
   *
   * @param state
   * @param pagination
   */
  setTeacherGroupPagination(state, pagination) {
    state.meta = pagination;
  },

  /**
   * Set page
   *
   * @param state
   * @param page
   */
  setTeacherGroupPage(state, page) {
    console.log("Page: ", page);
    state.meta.current_page = page;
  },

  /**
   * Clear the group.
   *
   * @param state
   */
  clearTeacherGroup(state) {
    state.groups = [];
  },
};

const getters = {
  getTeacherGroup: (state) => state.groups,
  getTeacherGroupPagination: (state) => state.meta,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
