export default {
  /**
   *
   * @param state
   * @param form
   */
  setSchoolManagementForm(state, form) {
    console.log("Form : ", form);

    state.form = form;
  },

  setSchoolManagementBusy(state, isBusy) {
    state.isBusy = isBusy;
  },
};
