export default {
  /**
   * @param state
   * @param items
   */
  setTeacherClockRecordList(state, items) {
    console.log("Items", items);
    state.items = items;

    console.log("State now", state);
  },

  /**
   * @param state
   * @param pagination
   */
  setTeacherClockRecordPagination(state, pagination) {
    console.log("Items", pagination);
    state.pagination = pagination;

    console.log("State now", state);
  },

  /**
   * @param state
   * @param page
   */
  setTeacherClockRecordPage(state, page) {
    console.log("Page ", page);
    state.page = page;

    console.log("State now", state);
  },
};
