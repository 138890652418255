import ApiService from "@/core/services/api.service";

const states = {};

const mutations = {};

const actions = {
  saveEmployee(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("employee/record/register", payload)
        .then((response) => {
          context.commit("setEmployeeResponse", response);
          resolve(response);
        })
        .catch((reason) => {
          context.commit("setEmployeeResError", reason);
          reject(reason);
        });
    });
  },
};

const getters = {};

export default {
  states,
  mutations,
  actions,
  getters,
};
