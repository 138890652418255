export default [
  {
    path: "school/leave/staff/entitlement",
    name: "staffEntitlementPage",
    component: () =>
      import(
        "../../../../components/management/leave/entitlement/staff/StaffIndividualLeaveEntitlementPage"
      ),
  },
];
