import DailyListStore from "./attendance_list_daily.store";
import MonthlyListStore from "./attendance_list_monthly.store";
import IndividualMonthlyListStore from "./emp_individual_attendance_list.store";
import ManageDailyAttendanceStore from "./attendance_manage_daily.store";

export default {
  modules: {
    DailyListStore,
    MonthlyListStore,
    IndividualMonthlyListStore,
    ManageDailyAttendanceStore,
  },
};
