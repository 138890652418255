export default {
  EDUCATION: {
    EDUCATION: "Pendidikan",
    EDUCATION1: "Latar Belakang Pendidikan",
    SCHOOL: "Institusi",
    SCHOOL1: "Masukkan Nama Institusi",
    DEGREE: "Ijazah",
    DEGREE1: "Masukkan Ijazah",
    FIELD: "Bidang Pengajian",
    FIELD1: "Masukkan Bidang Pengajian",
    GRADE: "Gred",
    GRADE1: "Masukkan Gred",
    FROM: "Dari (Tahun)",
    TO: "Ke (Tahun)",
  },
};
