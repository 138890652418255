const state = {
  items: [
    {
      id: 1,
      week: "1",
      pending: "10",
      approved: "2",
      total: "12",
    },
    {
      id: 1,
      week: "2",
      pending: "1",
      approved: "5",
      total: "6",
    },
    {
      id: 1,
      week: "3",
      pending: "3",
      approved: "10",
      total: "13",
    },
    {
      id: 1,
      week: "4",
      pending: "11",
      approved: "0",
      total: "11",
    },
    {
      id: 1,
      week: "5",
      pending: "9",
      approved: "14",
      total: "23",
    },
    {
      id: 1,
      week: "6",
      pending: "22",
      approved: "12",
      total: "34",
    },
    {
      id: 1,
      week: "7",
      pending: "25",
      approved: "12",
      total: "37",
    },
  ],
};

const actions = {};

const mutations = {};

const getters = {
  getCompanyLeaveStatistics: (state) => state.items,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
