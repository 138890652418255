import PersonalMenu from "@/modules/core/store/aside/menu/personal.menu";
import ManagementMenu from "@/modules/core/store/aside/menu/management.menu";
import RegistrationMenu from "./menu/registration.menu";
import UserService from "@/modules/auth/services/user.service";
import DeviceMenu from "@/modules/core/store/aside/menu/device.menu";

const state = {
  items: [
    ...PersonalMenu,
    ...RegistrationMenu,
    ...ManagementMenu,
    ...DeviceMenu,
  ],
  permissions: null,
  isBusy: true,
};

const actions = {
  async generateMenuItems(context) {
    return await UserService.getPermissions().then((value) => {
      console.log(value);
      context.commit("setGeneratedMenu", value);
      return value;
    });
  },
};

const mutations = {
  setGeneratedMenu(state, value) {
    value.onsuccess = () => {
      console.log("Setting permissions", value.result);
      state.permissions = value.result;
      state.isBusy = false;
      console.log(state.permissions);
    };
  },
};

const getters = {
  getUserPermissions: (state) => state.permissions,
  getSuperAdminPermission: (state) => {
    let isSuper = false;

    if (state.permissions === null) return isSuper;

    const similar = state.permissions.filter((item) => {
      return item.name === "add.manage.school";
    });
    console.log("Is Super", similar);
    if (similar.length > 0) isSuper = true;
    return isSuper;
  },
  getAdminPermission: (state) => {
    let isAdmin = false;

    if (state.permissions === null) return isAdmin;

    const similar = state.permissions.filter((item) => {
      return item.name === "add.manage.school.employee";
    });
    console.log("Is Admin", similar);
    if (similar.length > 0) isAdmin = true;
    return isAdmin;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
