import List from "./list/list.store";
import Add from "./add/add.store";
import Delete from "./delete/delete.store";

export default {
  modules: {
    List,
    Add,
    Delete,
  },
};
