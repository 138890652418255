export default {
  DEPARTMENT: {
    HEADER: "Department",
    SUBTITLE: "List of departments",
    BUTTON: "Create",
    DAILY: "Daily",
    MONTHLY: "Monthly",
  },
  DEPARTMENTFORM: {
    HEADER: "Create Department",
    FORM: "Department Form",
    NAME: "Name",
    NAMES: "Enter Name",
    DESCRIPTION: "Description",
    DATECREATED: "Date Created",
    NAME1: "Enter Department Name",
    DESCRIPTION1: "Enter Descriptions",
  },
};
