import ApiService from "@/core/services/api.service";
import state from "./state";
import dayjs from "dayjs";

export default {
  /**
   * Fetch individual staff leave list.
   *
   * @param context
   * @returns {*}
   */
  fetchIndividualStaffLeaveList(context) {
    ApiService.setHeader();

    return ApiService.query("school/leave/application/staff/individual", {
      params: {
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": state.size,
        date: state.filter.date,
        id: state.filter.staffId,
      },
    }).then((res) => {
      console.log(res);
      context.commit("setIndividualStaffLeaveList", res.data.data);
      context.commit(
        "setIndividualStaffLeaveListPagination",
        res.data.meta.pagination
      );
    });
  },

  /**
   * Fetch individual staff leave list.
   *
   * @param context
   * @returns {*}
   */
  fetchIndividualTeacherLeaveList(context) {
    ApiService.setHeader();

    return ApiService.query("school/leave/application/teacher/individual", {
      params: {
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": state.size,
        date: state.filter.date,
        id: state.filter.staffId,
      },
    }).then((res) => {
      console.log(res);
      context.commit("setIndividualStaffLeaveList", res.data.data);
      context.commit(
        "setIndividualStaffLeaveListPagination",
        res.data.meta.pagination
      );
    });
  },

  /**
   * Change the filter.
   *
   * @param context
   * @param payload
   */
  changeIndividualStaffLeaveListFilter(context, payload) {
    const filters = {
      date: payload.date ?? dayjs().format(),
      staffId: payload.staffId ?? null,
    };

    context.commit("setIndividualStaffLeaveListFilters", filters);
  },
};
