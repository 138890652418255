import state from "./state";
import ApiService from "@/core/services/api.service";

export default {
  /**
   *
   * @param context
   * @returns {*}
   */
  fetchSchoolLeaveDetailList(context) {
    ApiService.setHeader();
    return ApiService.query("school/leave/management", {
      params: {
        "page[size]": 10,
        "page[number]": state.pagination.current_page ?? 1,
        "filter[name]": state.filters?.name,
      },
    }).then((res) => {
      context.commit("setSchoolLeaveDetailList", res.data.data);
      context.commit(
        "setSchoolLeaveDetailListPagination",
        res.data.meta.pagination
      );
      return res;
    });
  },

  /**
   * Change filters.
   *
   * @param context
   * @param payload
   */
  changeSchoolLeaveDetailListBaseFilter(context, payload) {
    const filters = state.filters;

    filters.name = payload.query;

    context.commit("setSchoolLeaveListFilters", filters);
  },
};
