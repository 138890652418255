import FORM from "./form.ms";
import HOLIDAY from "./holiday.ms";

export default {
  ...FORM,
  COMPANY: {
    REGISTRATION: {
      GENERAL: "Umum",
      CONTACT: "Kenalan",
      RESET: "Tetap Semula",
      SUBMIT: "Simpan",
    },
  },
  ...HOLIDAY,
};
