export default {
  id: 1,
  items: [],
  pagination: {},
  size: 30,
  isBusy: false,
  filters: {
    from_date: null,
    to_date: null,
    name: "",
  },
};
