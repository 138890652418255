import ApiService from "@/core/services/api.service";

const state = {};

const actions = {
  fetchGeneralDetailForm() {
    console.log("Fetching employee general detail", state.employeeId);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(
        "employee/detail/general",
        "?employee_id=" + state.employeeId
      )
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  submitEmployeeGeneralDetails(context, form) {
    form.employee_id = state.employeeId;
    console.log("Employee Id", state.employeeId);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("employee/detail/general", form)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setEmployeeId(state, employeeId) {
    state.employeeId = employeeId;
  },
};

export default {
  state,
  actions,
  mutations,
};
