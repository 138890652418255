import actions from "./actions";
import state from "./state";

const getters = {
    getUploadedTeacherImage: (state) => state.image,
};

export default {
    state,
    getters,
    actions,
};
