import List from "./list/list.store";
import CreateForm from "./form/create_form.store";
import Delete from "./delete/delete.store";
import Details from "./details/teacher_details.store";

export default {
  modules: {
    List,
    CreateForm,
    Delete,
    Details,
  },
};
