import ApiService from "@/core/services/api.service";

export default {
  /**
   * Submit staff individual assignment form.
   *
   * @param context
   * @param payload
   */
  submitStaffIndividualAssignmentForm(context, payload) {
    ApiService.setHeader();
    return ApiService.post(
      "school/leave/assignment/staff",
      payload.form
    ).then((res) => res);
  },
};
