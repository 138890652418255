const ManagementPage = () =>
  import("../../../components/management/base/SchoolManagementPage");

export default [
  {
    path: "school/management",
    name: "managementSchool",
    component: ManagementPage,
  },
];
