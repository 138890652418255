import ApiService from "@/core/services/api.service";
import { default as groupService } from "@/modules/company/services/group/emp_group.service";

const state = {
  toSave: 0,
  group: {},
  isBusy: false,
};

const actions = {
  engageEmpGroupFormSubmission(context) {
    let count = state.toSave;
    console.log(count);
    context.commit("setEmpGroupFormSubmission", ++count);
  },

  saveEmpGroupSchedule(context, form) {
    context.commit("holdEmpGroupSchedule");
    ApiService.setHeader();

    const days = groupService.convertGroupForm(form);
    console.log(days);

    const payload = {
      id: state.group.id,
      days,
    };
    return ApiService.post("group/schedule", payload).finally(() =>
      context.commit("holdEmpGroupSchedule")
    );
  },
};

const mutations = {
  setEmpGroupFormSubmission(state, saveState) {
    state.toSave = saveState;
  },
  setEmpGroupEdit(state, group) {
    console.log("Selected group", group);
    state.group = group;
  },
  holdEmpGroupSchedule(state) {
    state.isBusy = !state.isBusy;
    console.log("Hold Emp Group State is", state.isBusy);
  },
};

const getters = {
  checkEmpGroupScheduleEvent: (state) => state.toSave,
  getHeldEmpGroupSchedule: (state) => state.isBusy,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
