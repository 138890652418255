import i18n from "@/core/plugins/vue-i18n";

export default [
  {
    text: i18n.t("PERSONAL.PERSONAL"),
    type: "menu-section",
    url: {},
    guard: {
      permissions: [],
    },
  },

  // Profile
  {
    text: i18n.t("PROFILE.PROFILE"),
    type: "menu-nav",
    url: {
      name: "personalProfile",
    },
    guard: {
      permissions: [],
    },
    icon: "/media/svg/icons/Communication/Adress-book2.svg",
  },

  // Account
  {
    text: i18n.t("PERSONAL.ACCOUNT"),
    type: "menu-nav",
    url: {
      name: "accountInformation",
    },
    guard: {
      permissions: [],
    },
    icon: "/media/svg/icons/Clothes/Briefcase.svg",
  },

  // Attendance
  {
    text: i18n.t("SIDEBAR.ATTENDANCE"),
    type: "menu-nav",
    url: {
      name: "personalAttendance",
    },
    guard: {
      permissions: [],
    },
    icon: "/media/svg/icons/Code/Time-schedule.svg",
  },

  // Leave
  {
    text: i18n.t("SIDEBAR.LEAVE"),
    type: "menu-nav",
    url: {
      name: "personalLeave",
    },
    icon: "/media/svg/icons/General/Clipboard.svg",
    guard: {
      permissions: [],
    },
  },

  {
    text: i18n.t("SIDEBAR.AWAY"),
    type: "menu-nav",
    url: {
      name: "personalAway",
    },
    icon: "/media/svg/icons/General/Clipboard.svg",
    guard: {
      permissions: [],
    },
  },
];
