import form from "@/modules/company/stores/employees/employee_form.store";
import list from "@/modules/company/stores/employees/employee_list.store";
import EmployeeDetails from "@/modules/company/stores/employees/details/employee_details.store";
import EmployeeEmail from "./employee_email.store";
import EmployeeRolesStore from "@/modules/company/stores/employees/roles/employee_roles.store";

// company.employee.*
export default {
  modules: { form, list, EmployeeDetails, EmployeeEmail, EmployeeRolesStore },
};
