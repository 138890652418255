export default {
    EDUGENERAL:
    {
        ROLE:
            {
                NAME: "Nama",
                ROLE: "Peranan",
                ADMIN: "Admin",
                TRANSFER: "Pindah",
            },
        MESSAGE :
            {
                TEACHERROLE : "Senarai Peranan Guru",
                STAFFROLE : "Senarai Peranan Staff",

            },
    },
    EDUATTENDANCE :
        {
          GENERAL :
              {
                DATE : "Tarikh",
                DAY : "Hari",
                DETAIL : "Butiran",
                DAILY : "Harian",
                MONTHLY : "Bulanan",
                YEARLY : "Tahunan",
                  PRESENT : "Hadir",
                  ABSENT : "T/Hadir",
                  ONLEAVE : "Cuti",
                  AWAY : "Tugasan Luar",
                  HOLIDAY : "Cuti Umum",
                  OFFDAY : "Hujung Minggu",
                  ACTION : "Tindakan",
              },
          TEACHER :
              {
                  TEACHER : "Guru",
                  MES1 : "Kehadiran Guru",
              },
          STAFF :
              {
                  STAFF : "Staf",
                  MES1 : "Kehadiran Staf",
              },
        },

};
