import ApiService from "@/core/services/api.service";

const state = {
  items: [],
  pagination: {},
};

const actions = {
  fetchEmployeeListForLeave(context) {
    ApiService.setHeader();
    return ApiService.query("employee/record", {
      params: {
        "page[size]": 30,
        "page[number]": state.pagination.current_page ?? 1,
        type_id: 1,
      },
    }).then((response) => {
      context.commit("setEmployeeListForLeave", response.data.data);
      context.commit(
        "setEmployeeListForLeavePagination",
        response.data.meta.pagination
      );
    });
  },
};

const mutations = {
  /**
   *
   * @param state
   * @param data
   */
  setEmployeeListForLeave(state, data) {
    state.items = data;
  },

  /**
   *
   * @param state
   * @param pagination
   */
  setEmployeeListForLeavePagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   *
   * @param state
   * @param currentPage
   */
  setEmployeeListForLeavePage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },
};

const getters = {
  getCompanyLeaveEntitlementList: (state) => state.items,
  getCompanyLeaveEntitlementListPagination: (state) => state.pagination,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
