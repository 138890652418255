import ApiService from "@/core/services/api.service";

const state = {
  employee: {},
};

const actions = {
  /**
   * Get school employee personal profile.
   *
   * @param context
   */
  getEmployeePersonalProfile(context) {
    ApiService.setHeader();
    ApiService.get("school/personal/profile/summary")
      .then((response) => {
        console.log("Employee Profile", response.data.data);
        context.commit("setEmployeePersonalProfile", response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const mutations = {
  setEmployeePersonalProfile(state, response) {
    state.employee = response;
  },
};

const getters = {
  getEmployeePersonalProfile: (state) => state.employee,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
