export default {
  LOGIN: {
    WELCOME: "Welcome to SmartID Education Login",
    NEW: "New Here?",
    REGISTER: "Register!",
    USERNAME: "Username",
    REQUIRED: "Username in the form of email is required",
    PASSWORD: "Password",
    REQUIRED2: "Password is required.",
    FORGOT: "Forgot Password ?",
    LOGIN: "Login",
  },
};
