import Teacher from "./assignment/teacher_assignment.router";
import Staff from "./assignment/staff_assignment.router";

export default [
  {
    path: "school/leave/assignment",
    component: () =>
      import(
        "../../../components/management/leave/assignment/SchoolLeaveAssignmentPage"
      ),
    children: [
      {
        path: "teacher",
        name: "schoolLeaveAssignment",
        component: () =>
          import(
            "../../../components/management/leave/assignment/teacher/TeacherLeaveAssignment"
          ),
      },
      {
        path: "staff",
        name: "staffLeaveAssignment",
        component: () =>
          import(
            "../../../components/management/leave/assignment/staff/StaffLeaveAssignment"
          ),
      },
    ],
  },
  ...Teacher,
  ...Staff,
];
