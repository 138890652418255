import ApiService from "@/core/services/api.service";
import ImageUploadChunkService from "@/modules/core/services/file/image/image_upload_chunk.service";

export default {
  /**
   * Attempts to uploadd Teacher Image
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  async uploadTeacherImage(context, payload) {
    try {
      ApiService.setHeader();

      const chunks = ImageUploadChunkService.generateChunks(payload.image);
      console.log(chunks);

      let counter = 0;
      let response;
      const fileName = require("crypto").randomBytes(64).toString("hex");

      for (const chunk of chunks) {
        ApiService.setHeader();

        let formData = new FormData();
        console.log(counter);
        console.log(chunks.length);

        formData.set(
          "is_last",
          counter === chunks.length - 1 ? "true" : "false"
        );
        formData.set("image", chunk, `${fileName}.part`);
        formData.append("id", payload.id);

        response = await ApiService.post(
          "school/employee/teacher/image",
          formData,
          ImageUploadChunkService.getConfig()
        );

        counter++;
      }

      return response;
    } catch (e) {
      console.error(e);
    }
  },
};
