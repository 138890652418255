export default {
  /**
   * @param state
   * @param items
   */
  setStaffIndividualEmployeeYearlyAttendanceList(state, items) {
    console.log("Items", items);

    state.items = items;
    console.log("Current State ", state);
  },

  /**
   * Change date filter
   *
   * @param state
   * @param currentDate
   */
  setStaffIndividualEmployeeYearlyAttendanceDate(state, currentDate) {
    console.log("Current Date", currentDate);
    state.filter.date = currentDate;
  },
};
