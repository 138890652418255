import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

const getters = {
  getFDList: (state) => state.items,
  getFDListPagination: (state) => state.pagination,
  getFDListPage: (state) => state.pagination.current_page,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
