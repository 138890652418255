import ApiService from "@/core/services/api.service";

const state = {
  items: [],
  pagination: {},
};

const actions = {
  /**
   * Fetch the employee leave entitlement
   *
   * @param context
   * @param payload is the Employee ID
   */
  fetchCompanyLeaveEntitlementList(context, payload) {
    ApiService.setHeader();
    return ApiService.query("company/leave/employee/entitlement", {
      params: {
        id: payload,
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": 30,
      },
    }).then((response) => {
      context.commit("setCompanyLeaveEntitlementList", response.data.data);
      context.commit(
        "setCompanyLeaveEntitlementListPagination",
        response.data.meta.pagination
      );
    });
  },
};

const mutations = {
  /**
   *
   * @param state
   * @param data
   */
  setCompanyLeaveEntitlementList(state, data) {
    state.items = data;
  },

  /**
   *
   * @param state
   * @param pagination
   */
  setCompanyLeaveEntitlementListPagination(state, pagination) {
    console.log("Pagination before and after", state.pagination, pagination);
    state.pagination = pagination;
  },

  /**
   *
   * @param state
   * @param currentPage
   */
  setCompanyLeaveEntitlementListPage(state, currentPage) {
    console.log("Pagination before and after", state.pagination, currentPage);
    state.pagination.current_page = currentPage;
  },
};

const getters = {
  getCompanyLeaveEntitlement: (state) => state.items,
  getCompanyLeaveEntitlementPagination: (state) => state.pagination,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
