import ApiService from "@/core/services/api.service";

const state = {};
const actions = {
  /**
   * Create New Teacher Form
   * @param context
   * @param payload
   * @returns {*}
   */
  createNewTeacher(context, payload) {
    ApiService.setHeader();
    return ApiService.post("school/employee/teacher", payload).then(
      (response) => response
    );
  },
  /**
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  checkExistingTeacherCode(context, payload) {
    ApiService.setHeader();
    return ApiService.query("school/employee/teacher/code", {
      params: {
        code: payload.code,
        id: payload.id,
      },
    }).then((response) => response);
  },
};
const mutations = {};

export default {
  state,
  actions,
  mutations,
};
