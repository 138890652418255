import AppDatabaseService from "@/modules/core/services/app_database.service";

const key = {
  roles: "roles",
  permissions: "permissions",
  details: "details",
};

/**
 * @author Amsyar
 * @param loginDetails
 * @link https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Using_IndexedDB
 *
 * Save Auth login details
 */
const save = (loginDetails) => {
  let request = AppDatabaseService.init();

  request.onupgradeneeded = function (e) {
    // Grab a reference to the opened database
    const db = e.target.result;

    // Create an objectStore to store our notes in (basically like a single table)
    // including a auto-incrementing key

    // Roles Store
    db.createObjectStore(key.roles, {
      keyPath: "id",
      autoIncrement: true,
    });
    // Permissions Store
    db.createObjectStore(key.permissions, {
      keyPath: "id",
      autoIncrement: true,
    });
    // Details Store
    db.createObjectStore(key.details, {
      keyPath: "user_details",
    });
    console.log("Database setup complete");
  };

  // Save starts here
  request.onsuccess = function () {
    console.log("Database opened successfully");
    store(request, loginDetails);
  };
};

/**
 * @author Amsyar
 * @param request of IndexDB
 * @param loginDetails from API
 * @link https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API/Using_IndexedDB
 *
 * Do the actual storing of login details into roles, permissions and user details object.
 */
const store = (request, loginDetails) => {
  // Store the opened database object in the db variable. This is used a lot below
  let db = request.result;
  const transaction = db.transaction(
    [key.permissions, key.roles, key.details],
    "readwrite"
  );

  // Roles
  loginDetails.roles.forEach((role) => {
    console.log("Role :", role);
    transaction.objectStore(key.roles).put({ name: role });
  });
  console.log(transaction.objectStore(key.roles).getAll());

  // Permissions
  loginDetails.permissions.forEach((permission) => {
    transaction.objectStore(key.permissions).put({ name: permission });
  });
  console.log(transaction.objectStore(key.permissions).getAll());

  // General Details
  transaction.objectStore(key.details).put({
    user_details: "Main",
    name: loginDetails.name,
    email: loginDetails.email,
    organisation: loginDetails.org,
  });

  console.log(transaction.objectStore(key.details).getAll());
};

/**
 * @author Amsyar
 */
const destroy = () => {
  const request = AppDatabaseService.init();

  request.onsuccess = () => {
    const db = request.result;
    console.log("Database :", db);
    const transaction = db.transaction(
      [key.permissions, key.roles, key.details],
      "readwrite"
    );
    transaction.objectStore(key.permissions).clear();
    transaction.objectStore(key.roles).clear();
    transaction.objectStore(key.details).clear();

    console.log("OS cleared.");
  };
};

/**
 * @author Amsyar
 * Get User authentication details from indexDB
 */
const getUserAuth = () => {
  const request = AppDatabaseService.init();

  return new Promise((resolve) => {
    let userData = null;
    request.onsuccess = () => {
      const db = request.result;
      console.log("Database :", db);
      const transaction = db.transaction(
        [key.permissions, key.roles, key.details],
        "readwrite"
      );
      console.log("Store:", transaction.objectStore(key.details).getAll());
      userData = transaction.objectStore(key.details).getAll();
      resolve(userData);
    };
  });
};

const getUserPermissions = () => {
  const request = AppDatabaseService.init();

  return new Promise((resolve) => {
    request.onsuccess = () => {
      const db = request.result;

      console.log("Fetching user permissions from :", db);
      const transaction = db.transaction([key.permissions], "readwrite");

      console.log("Store:", transaction.objectStore(key.permissions).getAll());
      const permissions = transaction.objectStore(key.permissions).getAll();
      resolve(permissions);
    };
  });
};

export default {
  save,
  store,
  destroy,
  getUserAuth,
  getUserPermissions,
};
