export default {
  counts: {
    presentCount: 0,
    absentCount: 0,
    leaveCount: 0,
    awayCount: 0,
    holidayCount: 0,
    offdaysCount: 0,
  },
};
