import ApiService from "@/core/services/api.service";

const actions = {
  /**
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  assignStaffAdminRole(context, payload) {
    ApiService.setHeader();
    return ApiService.post(
      "school/employee/staff/privilege/assign",
      payload
    ).then((response) => response);
  },
};

export default {
  actions,
};
