import ApiService from "@/core/services/api.service";
import state from "./state";
import dayjs from "dayjs";

export default {
  /**
   * Fetch teacher per-month attendance
   *
   * @param context
   * @param payload
   */
  fetchTeacherIndividualAttendance(context, payload) {
    ApiService.setHeader();
    context.commit("setTeacherIndividualAttendanceBusy", true);
    let dateType;
    if (payload.date_type === 1) {
      dateType = {
        date: dayjs(payload.date).format(),
      };
    } else {
      dateType = {
        from_date: payload.from_date,
        to_date: payload.to_date,
      };
    }
    return ApiService.query("school/attendance/teacher/monthly/single", {
      params: {
        "page[size]": state.size,
        "page[number]": state.pagination.current_page ?? 1,
        id: payload.id,
        date_type: payload.date_type,
        ...dateType,
      },
    })
      .then((res) => {
        console.log(res);
        context.commit("setTeacherIndividualAttendanceList", res.data.data);
        context.commit(
          "setTeacherIndividualAttendancePagination",
          res.data.meta.pagination
        );
      })
      .finally(() =>
        context.commit("setTeacherIndividualAttendanceBusy", false)
      );
  },
};
