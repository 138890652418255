import state from "./state";
import mutations from "./mutations";
import actions from "./actions";

const getters = {
  getAssignEntitledAwayApplication: (state) => state.away,
  getPersonalAwayApplicationList: (state) => state.items,
  getPersonalAwayApplicationListPagination: (state) => state.pagination,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
