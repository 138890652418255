import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getStaffLeaveApplicationApprovalList: (state) => state.items,
  getStaffLeaveApplicationApprovalPagination: (state) => state.pagination,
  getStaffLeaveApplicationApprovalPage: (state) =>
    state.pagination.current_page,
  getStaffLeaveApplicationApprovalBusy: (state) => state.isBusy,
};

export default {
  actions,
  mutations,
  state,
  getters,
};
