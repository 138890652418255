import List from "./list.store";

const state = {};

const actions = {};

const mutations = {};

const getters = {};

export default {
  modules: {
    List,
  },
  state,
  actions,
  mutations,
  getters,
};
