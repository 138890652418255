import ApiService from "@/core/services/api.service";
import Vue from "vue";

const state = {
  items: [],
  errors: null,
  size: 20,
  pagination: {},
  isBusy: {
    fetchForm: true,
  },
};

const actions = {
  getFacialDevices(context) {
    ApiService.setHeader();
    return Vue.axios
      .get("device/facial", {
        params: {
          "page[number]": state.pagination.current_page ?? 1,
          "page[size]": state.size ?? 10,
        },
      })
      .then((response) => {
        console.log(response);
        context.commit("setFacialDevices", response.data.data);
        context.commit(
          "setFacialDevicesPagination",
          response.data.meta.pagination
        );
        return response;
      });
  },

  emptyFacialDevices(context) {
    context.commit("setFacialDevices", []);
  },
};

const mutations = {
  /**
   * Set Facial Devices Response
   *
   * @param state
   * @param response
   */
  setFacialDevices(state, response) {
    console.log("Facial Devices:", response);
    state.items = response;
  },

  /**
   *
   * @param state
   * @param pagination
   */
  setFacialDevicesPagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   *
   * @param state
   * @param currentPage
   */
  setFacialDevicesPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   * Set Error
   *
   * @param state
   * @param error
   */
  setFacialDevicesError(state, error) {
    console.error(error);
    state.errors = error;
  },
};

const getters = {
  getFacialDevices: (state) => state.items,
  getFacialDevicesPagination: (state) => state.pagination,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
