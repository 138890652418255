export default {
  GROUP: {
    EMPLOYEEGROUP: "Group",
    CREATEGROUP: "Create Group",
    CONF: "Attendance Configuration",
    TIMEIN: "Time-In",
    TIMECUTIN: "Time In Cut-In",
    TIMEOUT: "Time-Out",
    TIMECUTOUT: "Time Out Cut-Off",
  },
};
