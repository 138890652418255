import FacialListStore from "@/modules/device/store/facial/facial_list.store";
import FacialFormStore from "@/modules/device/store/facial/facial_form.store";
import FacialEmployeeList from "@/modules/device/store/facial/facial_employee_list.store";
import Select from "./select/select.store";
import Edit from "./edit/edit_form.store";
import Delete from "./delete/delete.store";

export default {
  modules: {
    Select,
    Edit,
    Delete,
    FacialListStore,
    FacialFormStore,
    FacialEmployeeList,
  },
};
