export default {
    EDUGENERAL:
        {
            ROLE:
                {
                    NAME: "Name",
                    ROLE: "Role",
                    ADMIN: "Admin",
                    TRANSFER: "Transfer",
                },
            MESSAGE :
                {
                    TEACHERROLE : "Teacher Role List",
                    STAFFROLE : "Staff Role List",

                },
        },
    EDUATTENDANCE :
        {
            GENERAL :
                {
                    DATE : "Date",
                    DAY : "Day",
                    DETAIL : "Details",
                    DAILY : "Daily",
                    MONTHLY : "Monthly",
                    YEARLY : "Yearly",
                    PRESENT : "PRESENT",
                    ABSENT : "ABSENT",
                    ONLEAVE : "ON LEAVE",
                    AWAY : "AWAY",
                    HOLIDAY : "HOLIDAY",
                    OFFDAY : "WEEKEND",
                    ACTION : "ACTIONS",
                },
            TEACHER :
                {
                    TEACHER : "Teacher",
                    MES1 : "Teacher Attendance",
                },
            STAFF :
                {
                    STAFF : "Staff",
                    MES1 : "Staff Attendance",
                },
        },

};