import DEPARTMENT from "./department.en";
import DETAILS from "./details.en";
import FORM from "./form.en";
import GROUP from "./group.en";

export default {
  EMPLOYEE: {
    HEADER: "Employees",
    SUBTITLE: "List of your company employees",
    BUTTON: "Create",
    EMP: "Employee Name",
  },

  EMPLOYEES: {
    DETAILS: {
      CONTACT: "Contact",
      GENERAL: "General",
      ADDRESS: "Address",
      RESET: "Reset",
      SUBMIT: "Submit",
    },
  },

  EMPLOYEETABLE: {
    FULLNAME: "Full Name",
    DEPARTMENTNAME: "Name",
    ACTIONS: "Actions",
    Empty: {
      Title: "Empty List",
      Subtitle:
        "Either there are no employees from using filters or you have no employees added",
    },
  },

  SUMMARY: {
    SUMMARY: "Summary",
    SUMMARY1: "Enter Employee Summary",
  },

  POSITIONS: {
    NAME: "Enter Position Name",
    CODE: "Enter Code",
    DETAILS: "Position Details",
  },

  ...DEPARTMENT,
  ...DETAILS,
  ...FORM,
  ...GROUP,
};
