import VerificationForm from "@/modules/auth/store/verification/employee_verification_form.store";
import VerificationInit from "@/modules/auth/store/verification/employee_verification_init.store";
import VerificationLoginForm from "@/modules/auth/store/verification/employee_verification_login_form.store";

export default {
  modules: {
    VerificationInit,
    VerificationForm,
    VerificationLoginForm,
  },
};
