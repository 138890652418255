export default {
  /**
   *
   * @param state
   * @param items
   */
  setPersonalClockRecordList(state, items) {
    console.log("Items are: ", items);
    state.items = items;
  },

  /**
   *
   * @param state
   * @param pagination
   */
  setPersonalClockRecordListPagination(state, pagination) {
    console.log("Pagination is: ", pagination);
    state.pagination = pagination;
  },

  /**
   *
   * @param state
   * @param page
   */
  setPersonalClockRecordListPage(state, page) {
    console.log("Page :", page);
    state.pagination.current_page = page;
  },
};
