export default {
  MENU: {
    NEW: "baharu",
    ACTIONS: "Tindakan",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
  },
};
