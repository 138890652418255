import DEPARTMENT from "./department.ms";
import DETAILS from "./details.ms";
import FORM from "./form.ms";
import GROUP from "./group.ms";

export default {
  EMPLOYEE: {
    HEADER: "Pekerja",
    SUBTITLE: "Senarai Pekerja Syarikat Anda",
    BUTTON: "Tambah",
    EMP: "Nama Pekerja",
  },

  EMPLOYEES: {
    DETAILS: {
      CONTACT: "Kenalan",
      GENERAL: "Umum",
      ADDRESS: "Alamat",
      RESET: "Tetap Semula",
      SUBMIT: "Simpan",
    },
  },

  EMPLOYEETABLE: {
    FULLNAME: "Nama",
    DEPARTMENTNAME: "Nama Jabatan",
    ACTIONS: "Tindakan",
    Empty: {
      Title: "Tiada pekerja",
      Subtitle:
        "Tiada senarai dari penggunaan filter atau anda tidak tambah pekerja",
    },
  },

  SUMMARY: {
    SUMMARY: "Catatan",
    SUMMARY1: "Masukkan Catatan Pekerja",
  },

  POSITIONS: {
    NAME: "Masukkan Nama Jawatan",
    CODE: "Masukkan Kod",
    DETAILS: "Penerangan Jawatan",
  },

  ...DEPARTMENT,
  ...DETAILS,
  ...FORM,
  ...GROUP,
};
