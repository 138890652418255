export default [
  {
    path: "/verification/employee",
    name: "verifyEmployee",
    component: () =>
      import("@/modules/auth/components/verification/EmployeeVerificationPage"),
    children: [
      {
        path: "/",
        component: () =>
          import(
            "@/modules/auth/components/verification/EmployeeVerificationAccountCheck"
          ),
      },
      {
        path: "login",
        component: () =>
          import(
            "@/modules/auth/components/verification/EmployeeVerificationLoginForm"
          ),
      },
      {
        path: "register",
        component: () =>
          import(
            "@/modules/auth/components/verification/EmployeeVerificationRegisterForm"
          ),
      },
    ],
  },
  {
    path: "/verification/employee/unqualified",
    component: () =>
      import("@/modules/auth/components/verification/EmployeeCompanyExist"),
  },
];
