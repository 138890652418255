import DashboardRoutes from "@/modules/dashboard/router/dashboard";
import SettingRoutes from "@/modules/setting/router/setting";
import CompanyRoutes from "@/modules/company/routers/company.router";
import DeviceRoutes from "@/modules/device/routers/device.router";
import PersonalRoutes from "@/modules/personal/routers/personal.router";
import SchoolRoutes from "@/modules/school/routers/school.router";

export default [
  {
    path: "/",
    meta: {
      requiredAuth: true,
    },
    component: () => import("@/modules/core/layout/primary/MainLayout"),
    children: [
      ...DashboardRoutes,
      ...SettingRoutes,
      ...CompanyRoutes,
      ...DeviceRoutes,
      ...PersonalRoutes,
      ...SchoolRoutes,
    ],
  },
];
