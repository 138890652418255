import ApiService from "@/core/services/api.service";

export default {
  submitTeacherAwayIndividualAssignmentForm(context, payload) {
    ApiService.setHeader();
    return ApiService.post("school/away/assignment/teacher", payload.form).then(
      (res) => res
    );
  },
};
