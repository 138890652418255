import NotIn from "./not/list.store";
import Save from "./save/save";
import In from "./in/list.store";
import Delete from "./delete/delete";

export default {
  modules: {
    NotIn,
    Save,
    In,
    Delete,
  },
};
