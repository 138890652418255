export default {
  JOB: {
    JOB: "Pekerjaan",
    POSITION: "Jawatan",
    POSITION1: "Masukkan Jawatan",
    EMPTYPE: "Status Pekerjaan",
    EMPTYPE1: "Masukkan Status Pekerjaan",
    EFFDATE: "Tarikh Berkuatkuasa",
    EFFDATE1: "Masukkan Tarikh Berkuatkuasa",
    EMPGROUP: "Kumpulan Pekerja",
    EMPGROUP1: "Masukkan Kumpulan Pekerja",
    PROBATION: "Tempoh Tamat Percubaan",
    REMARK: "Catatan",
    REMARK1: "Maksimum 2000 Perkataan",
    EMPSTATUS: "Status Pekerja",
    STATUS: "Status",
    STATUS1: "Masukkan Status Pekerja",
    JOBSTATUS: "Status Jawatan",
    POSITIONS: "Jawatan",
    CREATE: "Daftar Jawatan",
    CODE: "Kod",
  },
};
