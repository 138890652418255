import actions from "./actions";
import mutations from "./mutations";
import state from "./state";

const getters = {
  getTeacherList: (state) => state.items,
  getTeacherListPagination: (state) => state.pagination,
  getTeacherListPage: (state) => state.pagination.current_page,
  getTeacherListBusy: (state) => state.isBusy,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
