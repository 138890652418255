import ApiService from "@/core/services/api.service";

const state = {
  items: [],
  pagination: {},
};

const actions = {
  /**
   * Fetch company leave list from server.
   */
  fetchCompanyLeaveList(context) {
    ApiService.setHeader();
    return ApiService.query("company/leave", {
      params: {
        "page[size]": 30,
        "page[number]": state.pagination.current_page ?? 1,
      },
    }).then((response) => {
      context.commit("setCompanyLeaveList", response.data.data);
      context.commit(
        "setCompanyLeaveListPagination",
        response.data.meta.pagination
      );
    });
  },
};

const mutations = {
  /**
   * Set List
   *
   * @param state
   * @param data
   */
  setCompanyLeaveList(state, data) {
    state.items = data;
  },

  /**
   * Set pagination object
   *
   * @param state
   * @param pagination
   */
  setCompanyLeaveListPagination(state, pagination) {
    console.log("Pagination: ", state, pagination);
    state.pagination = pagination;
  },

  /**
   * Set the current page
   *
   * @param state
   * @param page
   */
  setCompanyLeaveListPage(state, page) {
    console.log("Pagination: ", state, page);
    state.pagination.current_page = page;
  },
};

const getters = {
  getCompanyLeaveList: (state) => state.items,
  getCompanyLeaveListPagination: (state) => state.pagination,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
