import ApiService from "@/core/services/api.service";
import Vue from "vue";

const state = {
  meta: {},
  groups: [],
  response: {},
  isBusy: false,
};

const actions = {
  getEmployeeGroup(context) {
    state.isBusy = true;
    context.commit("clearEmployeeGroup");
    ApiService.setHeader();
    Vue.axios
      .get("/group/record", {
        "page[number]": state.meta.current_page ?? 1,
        "page[size]": 20,
      })
      .then((response) => {
        context.commit("setEmployeeGroup", response);
      })
      .finally(() => (state.isBusy = false));
  },
};

const mutations = {
  setEmployeeGroup(state, response) {
    state.response = response;
    state.groups = response.data.data;
  },
  clearEmployeeGroup(state) {
    state.groups = [];
  },
};

const getters = {
  getEmployeeGroup: (state) => state.groups,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
