export default [
  {
    path: "school/leave/teacher/entitlement",
    name: "teacherEntitlementPage",
    component: () =>
      import(
        "../../../../components/management/leave/entitlement/teacher/TeacherIndividualEntitlementPage"
      ),
  },
];
