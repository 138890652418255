import ApiService from "@/core/services/api.service";

const state = {};

const actions = {
  saveCompanyHoliday(context, form) {
    ApiService.setHeader();
    return ApiService.post("holiday/record", form);
  },
  deleteCompanyHoliday(context, id) {
    console.log("Holiday ID:", id);
    ApiService.setHeader();
    return ApiService.delete("holiday/record", {
      data: {
        holiday_id: id,
      },
    });
  },
};

const mutations = {};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
