import PersonalEmployeeAttendanceDaily from "@/modules/personal/stores/attendance/personal_employee_attendance_daily";
import PersonalEmployeeAttendanceMonthly from "@/modules/personal/stores/attendance/personal_employee_attendance_monthly";
import Clock from "./clock/personal_clock_record.store";

export default {
  modules: {
    PersonalEmployeeAttendanceDaily,
    PersonalEmployeeAttendanceMonthly,
    Clock,
  },
};
