import dayjs from "dayjs";

export default {
  size: 15,
  items: [],
  isBusy: false,
  pagination: {},
  filers: {
    first_name: null,
    last_name: null,
    date: dayjs().format(),
    status: null,
  },
};
