export default {
  PROFILE: {
    USERPROFILE: "Profil Pengguna",
    SIGNOUT: "Daftar Keluar",
    HI: "Selamat Datang, ",
    PROFILE: "Profil",
    ACCSETTINGS: "Tetapan Akaun",
    DEBUG: "Mod DEBUG",
  },

  PERSONAL: {
    PERSONAL: "Peribadi",
    ACCOUNT: "Akaun",
    PERSONALPROFILE: "Profil Peribadi",
    PERSONALDETAILS: "Butiran Peribadi",
    ACCOUNTDETAILS: "Butiran Akaun",
    EMERGENCYCONT: "Nombor Ketika Kecemasan",
    EDUDETAILS: "Butiran Pendidikan",
    GENERALDETAILS: "Butiran Am",
    PERSONALACC: "Akaun Peribadi",
  },
};
