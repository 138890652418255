import state from "./state";
import ApiService from "@/core/services/api.service";

export default {
  /**
   *
   * @param context
   * @returns {*}
   */
  async fetchStaffAdminList(context) {
    try {
      ApiService.setHeader();
      context.commit("setStaffAdminListBusy", true);
      const res = await ApiService.query("school/employee/staff/privilege", {
        params: {
          "page[size]": 30,
          "page[number]": state.pagination.current_page ?? 1,
          "filter[first_name]": state.filters?.name,
          role: state.filters.role ?? 1,
        },
      });
      context.commit("setStaffAdminList", res.data.data);
      context.commit("setStaffAdminListPagination", res.data.meta.pagination);
      return res;
    } catch (error) {
      console.error(error);

      throw Error("Failed.");
    } finally {
      context.commit("setStaffAdminListBusy", false);
    }
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeStaffAdminListNameFilters(context, payload) {
    console.log("Name", payload.name);

    let curFilters = state.filters;
    curFilters.name = payload.name;

    context.commit("setStaffAdminListFilters", curFilters);
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeStaffAdminListRoleFilters(context, payload) {
    console.log("Role", payload.role);

    let curFilters = state.filters;
    curFilters.role = payload.role;

    context.commit("setStaffAdminListFilters", curFilters);
  },
};
