import SIDEBAR from "./sidebar.en";

export default {
  ...SIDEBAR,
  BUTTON: {
    CANCEL: "Cancel",
    OKAY: "OK",
    YES: "Yes",
    SEARCH: "Search",
    PRINT: "Print",
    APPLY: "Apply",
    UNVERIFIED: "Unverified",
    UPLOAD: "Upload",
    PHOTO: "Profile Picture",
    REJECT: "Reject",
    ADD: "Add",
  },
  NOTIFICATION: {
    USER: "User Notifications",
    MESSAGE: "Messages",
    EVENTS: "Events",
  },
  FOOTER: {
    ABOUT: "About",
    CONTACT: "Contact",
  },
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
  },
  ALERT: {
    SURE: "Are You Sure?",
    WARNING: "Details will Be Permanently Deleted!",
    CONFIRM: "Details Deleted!",
    SUCCESS: "Details Successfully Saved",
    ALERT: "Required!",
    EMAIL: "Resend verification email to ",
  },
};
