import dayjs from "dayjs";

export default {
  items: [],
  pagination: {},
  isBusy: false,
  filter: {
    date: dayjs().format(),
    staffId: 0,
  },
};
