export default {
  /**
   * Set the List items
   *
   * @param state
   * @param items
   */
  setStaffAdminList(state, items) {
    state.items = items;
  },

  /**
   * Set List pagination meta
   *
   * @param state
   * @param pagination
   */
  setStaffAdminListPagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   * Set List current page
   *
   * @param state
   * @param currentPage
   */
  setStaffAdminListPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   *
   * @param state
   * @param filters
   */
  setStaffAdminListFilters(state, filters) {
    console.log("Filters : ", filters);

    state.filters = filters;
  },

  /**
   *
   * @param state
   * @param isBusy
   */
  setStaffAdminListBusy(state, isBusy) {
    console.log("Busy : ", isBusy);
    state.isBusy = isBusy;
  },
};
