const PersonalProfilePage = () =>
  import("@/modules/personal/components/profile/PersonalProfilePage");
const PersonalAccountPage = () =>
  import("@/modules/personal/components/account/PersonalAccountPage");
const PersonalAttendancePage = () =>
  import("@/modules/personal/components/attendance/PersonalAttendancePage");
const PersonalLeavePage = () => import("../components/leave/PersonalLeavePage");
const PersonalAwayPage = () => import("../components/away/PersonalAwayPage");

export default [
  {
    path: "/personal/profile",
    name: "personalProfile",
    component: PersonalProfilePage,
  },
  {
    path: "/personal/account",
    name: "accountInformation",
    component: PersonalAccountPage,
  },
  {
    path: "/personal/attendance",
    name: "personalAttendance",
    component: PersonalAttendancePage,
  },
  {
    path: "/personal/leave",
    name: "personalLeave",
    component: PersonalLeavePage,
    children: [
      {
        path: "entitlement",
        name: "personalLeave",
        component: () =>
          import(
            "../components/leave/entitlement/PersonalLeaveEntitlementTable"
          ),
      },
      {
        path: "application",
        name: "personalLeaveApplication",
        component: () =>
          import(
            "../components/leave/application/PersonalLeaveApplicationTable"
          ),
      },
    ],
  },
  {
    path: "/personal/away",
    name: "personalAway",
    component: PersonalAwayPage,
    children: [
      {
        path: "entitlement",
        name: "personalAway",
        component: () =>
          import("../components/away/entitlement/PersonalAwayEntitlementTable"),
      },
      {
        path: "application",
        name: "personalAwayApplication",
        component: () =>
          import("../components/away/application/PersonalAwayApplicationTable"),
      },
    ],
  },
];
