export default {
  /**
   *
   * @param state
   * @param items
   */
  setStaffDailyAttendance(state, items) {
    console.log("Items", items);
    state.items = items;
  },

  /**
   *
   * @param state
   * @param pagination
   */
  setStaffDailyAttendancePagination(state, pagination) {
    console.log("Pagination", pagination);
    state.pagination = pagination;
  },

  /**
   *
   * @param state
   * @param currentPage
   */
  setStaffDailyAttendancePage(state, currentPage) {
    console.log("Current Page", currentPage);
    state.pagination.current_page = currentPage;
  },

  /**
   * Set filters.
   *
   * @param state
   * @param filters
   */
  setStaffDailyAttendanceFilters(state, filters) {
    state.filters = filters;
  },

  /**
   * Set is busy
   *
   * @param state
   * @param isBusy
   */
  setStaffDailyAttendanceBusy(state, isBusy) {
    state.isBusy = isBusy;
  },
};
