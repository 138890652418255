export default {
  SALARY: {
    SALARY: "Gaji",
    SALARY1: "Butiran Gaji",
    SALARY2: "Masukkan Gaji",
    SALARY3: "Gaji Pokok",
    SALARY4: "Masukkan Gaji Pokok",
    CURRENCY: "Matawang",
    CURRENCY1: "Masukkan Matawang",
    PAYMENT: "Butiran Pembayaran",
    PAYMENT1: "Pembayaran",
    PAYMENT2: "Masukkan Jenis Pembayaran",
    BANK: "Bank",
    BANK1: "Masukkan Nama Bank",
    ACCOUNT: "Akaun Bank",
    ACCOUNT1: "Masukkan Nombor Akaun Bank",
    METHOD: "Kaedah",
    METHOD1: "Masukkan Kaedah Bayaran",
    STATUTORY: "Butiran Statutori",
    REMARK: "Catatan",
    EPF: "Kadar KWSP Majikan",
    EPF1: "Masukkan Kadar KWSP Majikan",
    EPF2: "Nombor Keahlian KWSP",
    EPF3: "Masukkan Nombor Keahlian KWSP",
    EPF4: "Kadar KWSP Pekerja",
    EPF5: "Masukkan Kadar KWSP Pekerja",
    TAX: "Nombor Rujukan Cukai",
    TAX1: "Masukkan Nombor Rujukan Cukai",
    CHILD: "Pelepasan Anak",
    CHILD1: "Masukkan Pelepasan Anak",
    EIS: "Sumbangan EIS",
    EIS1: "Masukkan Sumbangan EIS",
    SOCSO: "Kategori KWSP",
    SOCSO1: "Masukkan Kategori KWSP",
    TAX2: "Status Zakat",
    TAX3: "Masukkan Status Cukai",
    ZAKAT: "Zakat",
    ZAKAT1: "Masukkan Jumlah Zakat",
  },
};
