import ApiService from "@/core/services/api.service";
import state from "./state";
import dayjs from "dayjs";

export default {
  /**
   * Fetch Staff individual Attendance
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  fetchStaffIndividualAttendance(context, payload) {
    ApiService.setHeader();

    context.commit("setStaffIndividualAttendanceBusy", true);

    let dateType;
    if (payload.date_type === 1) {
      dateType = {
        date: dayjs(payload.date).format(),
      };
    } else {
      dateType = {
        from_date: payload.from_date,
        to_date: payload.to_date,
      };
    }

    return ApiService.query("school/attendance/staff/monthly/single", {
      params: {
        "page[number]": state.pagination.current_page ?? 1,
        "page[size]": state.size,
        id: payload.id,
        date_type: payload.date_type,
        ...dateType,
      },
    })
      .then((res) => {
        context.commit("setStaffIndividualAttendance", res.data.data);
        context.commit(
          "setStaffIndividualAttendancePagination",
          res.data.meta.pagination
        );
      })
      .finally(() => context.commit("setStaffIndividualAttendanceBusy", false));
  },
};
