import ApiService from "@/core/services/api.service";
import state from "./states";
import dayjs from "dayjs";

export default {
  /**
   *
   * @param context
   * @param payload
   * @returns {Promise<void>}
   */
  async fetchStaffDashboardDailyAttendance(context, payload) {
    try {
      ApiService.setHeader();
      context.commit("setStaffDashboardDailyAttendanceBusy", true);

      console.log("Daily Date ", dayjs().format());

      const response = await ApiService.query(
        "school/dashboard/staff/attendance/daily/detailed",
        {
          params: {
            "page[size]": state.size,
            "page[number]": state.pagination.current_page ?? 1,
            "filter[first_name]": state.filers?.first_name,
            date: dayjs().format(),
            id: payload.id,
          },
        }
      );

      context.commit("setStaffDashboardDailyAttendance", response.data.data);
      context.commit(
        "setStaffDashboardDailyAttendancePagination",
        response.data.meta.pagination
      );
    } catch (e) {
      console.error(e);
      throw Error("Failed");
    } finally {
      context.commit("setStaffDashboardDailyAttendanceBusy", false);
    }
  },

  /**
   * Change daily attendance name filter.
   *
   * @param context
   * @param payload
   */
  changeStaffDashboardDailyAttendanceNameFilter(context, payload) {
    const filters = state.filers;

    filters.first_name = payload.name;

    context.commit("setStaffDashboardDailyAttendanceFilters", filters);
  },
};
