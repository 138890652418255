import PersonalAccountStore from "@/modules/personal/stores/account/personal_account.store";
import PersonalEmployeeProfileStore from "@/modules/personal/stores/profile/personal_employee_profile";
import PersonalEmployeeAttendanceStore from "@/modules/personal/stores/attendance/personal_employee_attendance";
import PersonalEmployeeLeaveStore from "@/modules/personal/stores/leave/personal_employee_leave";
import PersonalEmployeeAwayStore from "./away/personal_employee_away";

export default {
  modules: {
    PersonalAccountStore,
    PersonalEmployeeProfileStore,
    PersonalEmployeeAttendanceStore,
    PersonalEmployeeLeaveStore,
    PersonalEmployeeAwayStore,
  },
};
