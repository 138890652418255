import ApiService from "@/core/services/api.service";

const mutations = {
  /**
   * Set is busy
   *
   * @param state
   * @param isBusy
   */
  setStaffAttendanceTimeBusy(state, isBusy) {
    state.isBusy = isBusy;
  },
};

const state = {
  isBusy: false,
};

const getters = {
  getStaffAttendanceTimeBusy: (state) => state.isBusy,
};

const actions = {
  updateStaffAttendanceTime(context, payload) {
    ApiService.setHeader();

    context.commit("setStaffAttendanceTimeBusy", true);

    return ApiService.post(
      "school/attendance/staff/manage/time/update",
      payload.form
    )
      .then(() => {
        console.log("Updated ", payload.form);
      })
      .finally(() => context.commit("setStaffAttendanceTimeBusy", false));
  },
};

export default {
  actions,
  mutations,
  state,
  getters,
};
