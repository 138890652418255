import StaffRoutes from "@/modules/school/routers/staff/staff.router";
import TeacherRoutes from "@/modules/school/routers/teacher/teacher.router";
import ManagementRoutes from "./management/school_management.router";
import DeviceRoutes from "./device/device.router";
import Holiday from "./holiday/holiday.router";

export default [
  ...StaffRoutes,
  ...TeacherRoutes,
  ...ManagementRoutes,
  ...DeviceRoutes,
  ...Holiday,
];
