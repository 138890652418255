import actions from "./actions";
import mutations from "./mutations";
import state from "./state";

const getters = {
  getTeacherLeaveApplicationApprovalList: (state) => state.items,
  getTeacherLeaveApplicationApprovalPagination: (state) => state.pagination,
  setTeacherLeaveApplicationApprovalPage: (state) =>
    state.pagination.current_page,
  setTeacherLeaveApplicationApprovalBusy: (state) =>
      state.isBusy,
};

export default {
  actions,
  mutations,
  getters,
  state,
};
