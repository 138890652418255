import actions from "./actions";
import mutations from "./mutations";
import state from "./state";

const getters = {
  getTeacherDashboardDailyAttendanceList: (state) => state.items,
  getTeacherDashboardDailyAttendancePagination: (state) => state.pagination,
  getTeacherDashboardDailyAttendanceBusy: (state) => state.isBusy,
};

export default {
  getters,
  actions,
  mutations,
  state,
};
