export default {
    USERGENERAL: {
        MESSAGE: {
            FIRSTNAME: "Nama Pertama",
            LASTNAME: "Nama Terakhir",
            EMAIL: "E-mel",
            UNIQUE: "Kod Pekerja",
            JOIN: "Tarikh Menyertai",
            START : "Mula",
            END : "Akhir",
        },
    },
    USERCONTACT: {
        MESSAGE: {
            CONTACT: "Nombor Telefon",
            EMERGENCY: "Talian Kecemasan",
            RELATIONSHIP: "Hubungan",
            ADDRESS1: "Alamat 1",
            ADDRESS2: "Alamat 2",
            ADDRESS3: "Alamat 3",
            LOCALITY: "Lokaliti",
            CITY: "Bandar",
            POSTALCODE: "Poskod",
            STATE: "Negeri",
        },
    },
    USERPERSONAL: {
        MESSAGE: {
            DOB: "Tarikh Lahir",
            DOB1: "Masukkan Tarikh Lahir",
            GENDER: "Jantina",
            MARITALID: "Status Perkahwinan",
            ADDRESS1: "Alamat 1",
            ADDRESS2: "Alamat 2",
            ADDRESS3: "Alamat 3",
            LOCALITY: "Lokaliti",
            CITY: "Bandar",
            POSTALCODE: "Poskod",
            STATE: "Negeri",
        },
    },
};
