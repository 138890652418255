const StaffActive = () =>
  import("../../../components/staff/list/StaffListPage");

const StaffArchived = () =>
  import("../../../components/staff/archived/StaffArchivedPage");

export default [
  {
    path: "active",
    component: StaffActive,
    name: "staffActiveList",
  },
  {
    path: "archived",
    component: StaffArchived,
    name: "staffArchivedList",
  },
];
