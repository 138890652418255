import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {};

export default {
  actions,
  state,
  mutations,
  getters,
};
