import FACIAL from "./facial.en";
import BEACON from "./beacon.en";

export default {
  DEVICE: {
    ...FACIAL,
  },
  ...BEACON,
  DEVICEFORM: {
    HEADER: "Register Facial Device",
    FORM: "Facial Device Details",
    SERIALNUM: "Serial Number",
    NAME1: "Enter Facial Device Name",
    SERIALNUM1: "Enter Serial Number",
    DATEPURCHASED: "Date Of Purchase",
  },
  DEVICEEMPLOYEE: {
    HEADER: "Employee List",
    IN: "In",
    LIST: "List",
    STATUS: "No available employees",
    STATUS1: "No devices added",
    EMPLOYEECODE: "Employee Code",
    SYNC: "Sync",
    SAVE: "Save",
  },
};
