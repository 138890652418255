import ApiService from "@/core/services/api.service";

const state = {
  monthlyList: [],
  pagination: {},
};

const actions = {
  /**
   * @param context
   * @returns {*}
   */
  fetchEmployeeMonthlyAttendanceList(context) {
    context.commit("clearEmployeeMonthlyAttendanceList");
    ApiService.setHeader();
    return ApiService.query("employee/attendance/monthly", {
      params: {
        "page[size]": 30,
        "page[number]": state.pagination.current_page ?? 1,
      },
    })
      .then((response) => {
        context.commit("setEmployeeMonthlyAttendanceList", response.data);
        context.commit(
          "setEmployeeMonthlyAttendancePagination",
          response.data.meta.pagination
        );
      })
      .catch((reason) => console.log(reason));
  },
  /**
   *
   * @returns {Promise<unknown>}
   */
  downloadMonthlyReport() {
    ApiService.setHeader();
    return ApiService.query("employee/attendance/admin/report/monthly", {
      responseType: "blob",
    })
      .then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Company Monthly Attendance.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const mutations = {
  /**
   * @param state
   * @param data
   */
  setEmployeeMonthlyAttendanceList(state, data) {
    state.monthlyList = data.data;
  },

  /**
   * @param state
   */
  clearEmployeeMonthlyAttendanceList(state) {
    state.monthlyList = [];
  },

  /**
   * Set the pagination
   *
   * @param state
   * @param pagination
   */
  setEmployeeMonthlyAttendancePagination(state, pagination) {
    console.log("Set Page", pagination);
    state.pagination = pagination;
  },

  /**
   * Set the current page
   *
   * @param state
   * @param currentPage
   */
  setEmployeeMonthlyAttendancePage(state, currentPage) {
    console.log("Set Page", currentPage);
    state.pagination.current_page = currentPage;
  },
};

const getters = {
  getEmployeeMonthlyAttendanceList: (state) => state.monthlyList,
  getEmployeeMonthlyAttendanceMeta: (state) => state.pagination,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
