import ApiService from "@/core/services/api.service";
import AppReportDownloadService from "@/modules/core/services/file/file_download.service";
import state from "./state";
import dayjs from "dayjs";

export default {
  /**
   * Fetch staff monthly attendance.
   *
   * @param context
   * @returns {*}
   */
  async fetchSchoolStaffMonthlyAttendance(context) {
    try {
      ApiService.setHeader();
      context.commit("setSchoolStaffMonthlyAttendanceBusy", true);

      const response = await ApiService.query(
        "school/attendance/staff/monthly",
        {
          params: {
            "page[size]": state.size,
            "page[number]": state.pagination.current_page ?? 1,
            "filter[base]": state.filter?.first_name,
            date: state.filter.date,
          },
        }
      );

      console.log("Received ", response);
      context.commit("setSchoolStaffMonthlyAttendanceList", response.data.data);
      context.commit(
        "setSchoolStaffMonthlyAttendancePagination",
        response.data.meta.pagination
      );

      return response;
    } catch (error) {
      console.error(error);

      throw Error("Failed.");
    } finally {
      context.commit("setSchoolStaffMonthlyAttendanceBusy", false);
    }
  },

  async downloadStaffMonthlyAttendanceReport(context, payload) {
    try {
      ApiService.setHeader();

      let dateType;
      if (state.date_type === 1) {
        dateType = {
          date: dayjs(state.filter.date).format(),
        };
      } else {
        dateType = {
          from_date: state.filter?.from_date,
          to_date: state.filter?.to_date,
        };
      }

      const response = await ApiService.query(
        "school/attendance/staff/monthly/report",
        {
          responseType: "blob",
          params: {
            type: payload.type ?? 1,
            date_type: state.date_type ?? 1,
            ...dateType,
          },
        }
      );

      AppReportDownloadService.processDownload(
        response.data,
        "Staff Monthly Report - Specific"
      );

      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },

  /**
   * Fetch using date range
   *
   * @param context
   * @returns {Promise<*>}
   */
  async fetchSchoolStaffMonthlyRangeAttendance(context) {
    try {
      ApiService.setHeader();
      context.commit("setSchoolStaffMonthlyAttendanceBusy", true);

      const response = await ApiService.query(
        "school/attendance/staff/monthly/range",
        {
          params: {
            "page[size]": state.size,
            "page[number]": state.pagination.current_page ?? 1,
            "filter[base]": state.filter?.first_name,
            from_date: state.filter?.from_date,
            to_date: state.filter?.to_date,
          },
        }
      );

      console.log("Received ", response);
      context.commit("setSchoolStaffMonthlyAttendanceList", response.data.data);
      context.commit(
        "setSchoolStaffMonthlyAttendancePagination",
        response.data.meta.pagination
      );

      return response;
    } catch (error) {
      console.error(error);

      throw Error("Failed.");
    } finally {
      context.commit("setSchoolStaffMonthlyAttendanceBusy", false);
    }
  },

  /**
   * Change date filter
   *
   * @param context
   * @param payload has the date key
   */
  changeSchoolStaffMonthlyAttendanceDate(context, payload) {
    const date = payload.date;
    if (date !== undefined || date !== null) {
      console.log("Selected date is ", date);
      context.commit("setSchoolStaffMonthlyAttendanceDate", date);
    }
  },

  /**
   * Change range filters.
   *
   * @param context
   * @param payload
   */
  changeSchoolStaffMonthlyRangeFilters(context, payload) {
    const filters = state.filter;

    filters.from_date = payload.from_date;
    filters.to_date = payload.to_date;

    context.commit("setSchoolStaffMonthlyAttendanceFilters", filters);
  },

  /**
   * Change name filter.
   *
   * @param context
   * @param payload
   */
  changeSchoolStaffMonthlyNameFilter(context, payload) {
    const filters = state.filter;

    filters.first_name = payload.name;

    context.commit("setSchoolStaffMonthlyAttendanceFilters", filters);
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeSchoolStaffMonthlyDateType(context, payload) {
    console.log(payload);
    context.commit("setSchoolStaffMonthlyAttendanceDateType", payload.type);
  },
};
