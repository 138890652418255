import ApiService from "@/core/services/api.service";

const state = {
  toSave: 0,
  isBusy: false,
  id: null,
  group: {
    id: null,
    name: null,
    description: null,
    mode: null,
  },
};

const actions = {
  /**
   * Specify.
   *
   * @param context
   * @param group
   */
  specifyTeacherGroupEdit(context, group) {
    context.commit("setTeacherGroupEdit", group);
  },

  /**
   * Update.
   *
   * @param context
   * @param form
   * @returns {*}
   */
  updateTeacherGroupForm(context, form) {
    context.commit("holdTeacherGroupEditBusy");
    ApiService.setHeader();
    return ApiService.post("school/group/management/update", form)
      .then(() => {
        console.log("Updated ", form);

        const group = {
          id: form.id,
          name: form.name,
          description: form.description,
        };

        context.commit("setTeacherGroupEdit", group);
        context.dispatch("getTeacherGroup").then(console.log);
      })
      .finally(() => context.commit("holdTeacherGroupEditBusy"));
  },

  /**
   * Tell the form to submit.
   *
   * @param context
   */
  engageUpdateTeacherGroupForm(context) {
    let count = state.toSave;
    console.log(count);
    context.commit("setUpdateTeacherGroupFormSubmission", ++count);
  },
};

const mutations = {
  setTeacherGroupEdit(state, group) {
    state.group = group;
  },
  setUpdateTeacherGroupFormSubmission(context, saveState) {
    state.toSave = saveState;
  },
  holdTeacherGroupEditBusy(state) {
    state.isBusy = !state.isBusy;
  },
};

const getters = {
  getTeacherGroupInformation: (state) => state.group,
  getHeldTeacherEditGroup: (state) => state.isBusy,
  checkUpdateTeacherGroupScheduleEvent: (state) => state.toSave,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
