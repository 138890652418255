import ApiService from "@/core/services/api.service";

export default {
  /**
   * Fetch clock record list by the given Date
   *
   * @param context
   * @param payload
   */
  fetchStaffClockRecord(context, payload) {
    console.log("Payload", payload);

    ApiService.setHeader();
    return ApiService.query("school/attendance/clock/staff", {
      params: {
        "page[size]": 30,
        "page[number]": 1,
        id: payload.id,
        date: payload.date,
      },
    }).then((res) => {
      console.log("Response", res);
      context.commit("setStaffClockRecordList", res.data.data);
      context.commit("setStaffClockRecordPagination", res.data.meta.pagination);

      return res;
    });
  },
};
