const DailyAttendance = () =>
  import(
    "@/modules/school/components/management/attendance/teacher/TeacherDailyAttendance"
  );

const MonthlyAttendance = () =>
  import(
    "@/modules/school/components/management/attendance/teacher/TeacherMonthlyAttendance"
  );

const YearlyAttendance = () =>
  import(
    "@/modules/school/components/management/attendance/teacher/TeacherYearlyAttendance"
  );

export default [
  { path: "daily", component: DailyAttendance, name: "attendanceTeacher" },
  {
    path: "monthly",
    component: MonthlyAttendance,
    name: "attendanceTeacherMonthly",
  },
  {
    path: "yearly",
    name: "attendanceTeacherYearly",
    component: YearlyAttendance,
  },
];
