import Teacher from "./teacher/teacher.store";
import Staff from "./staff/staff.store";
import Attendance from "./attendance/school_attendance.module";
import Device from "./device/device.store";
import Leave from "./leave/leave.module";
import Holiday from "./holiday/holiday.store";
import Away from "./away/away.module";
import School from "./school/school.store";

export default {
  modules: {
    Teacher,
    Staff,
    Attendance,
    Device,
    Leave,
    Holiday,
    Away,
    School,
  },
};
