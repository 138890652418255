import ApiService from "@/core/services/api.service";

const actions = {
  /**
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  updateTeacherGeneralDetails(context, payload) {
    ApiService.setHeader();
    return ApiService.post(
      "school/employee/teacher/details/general",
      payload
    ).then((res) => {
      return res;
    });
  },
};

export default {
  actions,
};
