import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

const getters = {
  getTeacherMonthlyAttendanceExportList: (state) => state.items,
  getTeacherMonthlyAttendanceExportListPagination: (state) => state.pagination,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
