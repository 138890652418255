export default {
  LOGIN: {
    WELCOME: "Selamat Datang Ke SmartID Education",
    NEW: "Pengguna Baru?",
    REGISTER: "Daftar Akaun!",
    USERNAME: "Nama Pengguna",
    REQUIRED: "Nama pengguna diperlukan",
    PASSWORD: "Kata Laluan",
    REQUIRED2: "Kata Laluan diperlukan.",
    FORGOT: "Lupa Kata Laluan ?",
    LOGIN: "Daftar Masuk",
  },
};
