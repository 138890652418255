export default {
  items: [],
  pagination: {},
  size: 15,
  isBusy: false,
  filters: {
    first_name: null,
    last_name: null,
  },
};
