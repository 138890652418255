import EMPLOYEE from "./en/employees/employees.en";
import EDU from "./en/school/school.en";
import AUTH from "./en/auth/auth.en";
import DEVICE from "./en/device/device.en";
import ECOMMERCE from "./en/core/ecommerce.en";
import ATTENDANCE from "./en/attendance/attendance.en";
import COMPANY from "./en/company/company.en";
import USER from "./en/user/user.en";
import CORE from "./en/core/core.en";
import DASHBOARD from "./en/dashboard/dashboard.en";
import COMMON from "./en/common/common.en";

// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language",
  },
  ...AUTH,
  ...ECOMMERCE,
  ...USER,
  ...EMPLOYEE,
  ...DEVICE,
  ...ATTENDANCE,
  ...COMPANY,
  ...CORE,
  ...EDU,
  ...DASHBOARD,
  ...COMMON
};
