export default {
  FACIAL: {
    PAGE: {
      HEADER: "Peranti Muka",
      SUBTITLE: "Senarai Peranti Muka",
      BUTTON: "Daftar",
      DEVICE: "Peranti",
      MODEL: "Model",
      SERIALNUMBER: "Nombor Siri",
      ACTIONS: "Tindakan",
      HEADERFORM: "Daftar Peranti Muka",
      DEVICENAME: "Nama Peranti",
      DEVICENAME1: "Masukkan Nama Peranti",
    },
  },
};
