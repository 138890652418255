import ApiService from "@/core/services/api.service";

const state = {};

const actions = {
  /**
   * Delete the device
   *
   * @param context
   * @param payload is the Facial Device ID
   */
  deleteFacialDevice(context, payload) {
    ApiService.setHeader();
    return ApiService.delete("device/facial", {
      params: {
        id: payload,
      },
    }).then((response) => response);
  },
};

const mutations = {};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
