import state from "./employee/state";
import actions from "@/modules/device/store/facial/employee/actions";
import mutations from "@/modules/device/store/facial/employee/mutations";
import getters from "@/modules/device/store/facial/employee/getters";

export default {
  state,
  actions,
  mutations,
  getters,
};
