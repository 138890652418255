import state from "./state";
import mutations from "./mutations";
import actions from "./actions";

const getters = {
  getPersonalAwayEntitlementList: (state) => state.items,
  getPersonalAwayEntitlementListPagination: (state) => state.pagination,
  getPersonalAwayEntitlementListBusy: (state) => state.isBusy,
  getPersonalAwayEntitlementForm: (state) => state.form,
};

export default { state, actions, mutations, getters };
