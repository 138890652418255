import dayjs from "dayjs";

export default {
  items: [],
  pagination: {},
  size: 15,
  isBusy: false,
  filter: {
    date: dayjs().format(),
  },
};
