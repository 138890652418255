import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

const getters = {
  getPersonalLeaveEntitlementList: (state) => state.items,
  getPersonalLeaveEntitlementListPagination: (state) => state.pagination,
  getPersonalLeaveEntitlementListBusy: (state) => state.isBusy,
  getPersonalLeaveEntitlementForm: (state) => state.form,
};

export default { state, actions, mutations, getters };
