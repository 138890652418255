import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

const getters = {
  getTeacherAwayIndividualEntitlementList: (state) => state.items,
  getTeacherAwayIndividualEntitlementListPagination: (state) =>
    state.pagination,
  getTeacherAwayIndividualEntitlementForm: (state) => state.form,

  getStaffAwayIndividualEntitlementList: (state) => state.items,
  getStaffAwayIndividualEntitlementListPagination: (state) => state.pagination,
  getStaffAwayIndividualEntitlementForm: (state) => state.form,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
