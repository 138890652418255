export default {
  /**
   *
   * @param state
   * @param count
   */
  setTeacherDashboardCount(state, count) {
    console.log(count);

    state.teacherCount = count;
  },
};
