import department from "@/modules/company/stores/department/department.store";
import employee from "@/modules/company/stores/employees/employee.store";
import AttendanceStore from "@/modules/company/stores/attendance/attendance.store";
import EmployeeGroupStore from "@/modules/company/stores/group/employee_group.store";
import CompanyLeaveStore from "@/modules/company/stores/leave/company_leave.store";

/**
 * Company Modular stores.
 *
 * @author Amsyar
 * @version 1.0.0
 */
export default {
  // company.
  modules: {
    department,
    employee,
    AttendanceStore,
    EmployeeGroupStore,
    CompanyLeaveStore,
  },
};
