import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

const getters = {
  getTeacherIndividualEntitlementList: (state) => state.items,
  getTeacherIndividualEntitlementListPagination: (state) => state.pagination,
  getTeacherIndividualEntitlementForm: (state) => state.form,

  getStaffIndividualEntitlementList: (state) => state.items,
  getStaffIndividualEntitlementListPagination: (state) => state.pagination,
  getStaffIndividualEntitlementForm: (state) => state.form,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
