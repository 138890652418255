import ApiService from "@/core/services/api.service";
import Vue from "vue";

const state = {
  employees: [],
  meta: {},
  errors: null,
};

const actions = {
  /**
   * @param context
   * @param typeId
   * @returns {Promise<unknown>}
   */
  getEmployeeList(context, typeId = 1) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      console.log("Meta", state.meta);

      Vue.axios
        .get("employee/record", {
          params: {
            "page[number]": state.meta.current_page ?? 1,
            type_id: typeId,
          },
        })
        .then((response) => {
          context.commit("setEmployeeList", response.data);
          context.commit(
            "setEmployeeListPagination",
            response.data.meta.pagination
          );
          resolve(response);
        })
        .catch((reason) => {
          context.commit("setEmployeeListError", reason);
          reject(reason);
        });
    });
  },

  /**
   * @param context
   */
  emptyEmployeeList(context) {
    context.commit("clearEmployeeList");
  },
  /**
   * testing delete
   * @param context
   * @param employeeId
   */
  deleteEmployee(context, employeeId) {
    console.log("Id is", employeeId);
    ApiService.setHeader();
    return ApiService.delete("employee/record", {
      params: {
        employee_id: employeeId,
      },
    }).then((response) => {
      console.log(response);
    });
  },
};

const mutations = {
  /**
   * Set Employee List.
   *
   * @param state
   * @param response
   */
  setEmployeeList(state, response) {
    console.log("Response data", response);
    state.employees = response.data;
  },
  /**
   * Set List Error.
   *
   * @param state
   * @param error
   */
  setEmployeeListError(state, error) {
    console.error(error);
    state.errors = error;
  },

  /**
   * Set List Pagination
   *
   * @param state
   * @param pagination
   */
  setEmployeeListPagination(state, pagination) {
    console.log(pagination);
    state.meta = pagination;
  },

  /**
   * Clear Employee List.
   *
   * @param state
   */
  clearEmployeeList(state) {
    state.items = [];
    state.response = {};
  },
};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
