import ApiService from "@/core/services/api.service";

const state = {
  items: [],
  response: {},
  meta: {},
  clockRecord: {},
};

const actions = {
  /**
   * Fetch Company (Employee) Daily Attendance
   *
   * @param context
   * @returns {Promise<unknown>}
   */
  getEmployeeAttendanceList(context) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.query("employee/attendance", {
        params: {
          "page[number]": state.meta.current_page ?? 1,
        },
      })
        .then((response) => {
          console.log(response);
          context.commit("setEmployeeAttendanceList", response.data);
          context.commit(
            "setDailyEmployeeAttendancePagination",
            response.data.meta.pagination
          );
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  /**
   * Produce a list of clock records in Employee Attendance.
   *
   * @param context
   * @param attendanceId
   * @returns {Promise<unknown>}
   */
  generateEmployeeClockRecord(context, attendanceId) {
    // Clear Clock Records first
    context.commit("setEmployeeClockRecord", {});
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(
        "employee/attendance/clock/today",
        "?attendance_id=" + attendanceId
      )
        .then((response) => {
          console.log(response);
          context.commit("setEmployeeClockRecord", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  /**
   * Clear existing Clock Records
   *
   * @param context
   */
  clearEmployeeClockRecord(context) {
    context.commit("setEmployeeClockRecord", {});
  },
  downloadDailyReport() {
    ApiService.setHeader();
    return ApiService.query("employee/attendance/admin/report/daily", {
      responseType: "blob",
    })
      .then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Company Daily Attendance.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const mutations = {
  /**
   * Set the Attendance List using the given response data.
   *
   * @param state
   * @param response
   */
  setEmployeeAttendanceList(state, response) {
    state.response = response;
    state.items = response.data;
  },

  /**
   *
   * @param state
   * @param pagination
   */
  setDailyEmployeeAttendancePagination(state, pagination) {
    console.log(pagination);
    state.meta = pagination;
  },

  /**
   *
   * @param state
   * @param currentPage
   */
  setDailyEmployeeAttendancePaginationPage(state, currentPage) {
    state.meta.current_page = currentPage;
  },

  /**
   * Set Employee Clock records using the given data,
   *
   * @param state
   * @param data
   */
  setEmployeeClockRecord(state, data) {
    console.log("Clock Records set", data);
    state.clockRecord = data;
  },
};

const getters = {
  getDailyEmployeeAttendance: (state) => state.items,
  getDailyEmployeeAttendancePagination: (state) => state.meta,
  getEmployeeClockRecord: (state) => state.clockRecord,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
