import ApiService from "@/core/services/api.service";
import state from "./state";

export default {
  /**
   * Fetch Teacher Leave application list.
   *
   * @param context
   */
  async fetchStaffAwayApplicationApprovalList(context) {
    try {
      context.commit("setStaffAwayApplicationApprovalListBusy", true);
      ApiService.setHeader();

      const response = await ApiService.query("school/away/application/staff", {
        params: {
          "page[number]": state.pagination.current_page ?? 1,
          "page[size]": state.size,
        },
      });

      context.commit("setStaffAwayApplicationApprovalList", response.data.data);
      context.commit(
        "setStaffAwayApplicationApprovalListPagination",
        response.data.meta.pagination
      );

      return response;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      context.commit("setStaffAwayApplicationApprovalListBusy", false);
    }
  },
};
