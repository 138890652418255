import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getStaffAdminList: (state) => state.items,
  getStaffAdminPagination: (state) => state.pagination,
  getStaffAdminListPage: (state) => state.pagination.current_page,
  getStaffAdminListFilters: (state) => state.filters,
  getStaffAdminListBusy: (state) => state.isBusy,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
