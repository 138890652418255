export default {
  SIDEBAR: {
    DASHBOARD: "Halaman Utama",
    COMPANY: "Syarikat",
    SETTINGS: "Tetapan",
    HOME: "Utama",
    EMPLOYEE: "Pekerja",
    DEPARTMENT: "Jabatan",
    ATTENDANCE: "Kehadiran",
    LEAVE: "Pelepasan Cuti",
    DEVICE: "Peranti",
    REGISTRATION: "Butiran",
    FACIAL: "Peranti Muka",
    MANAGEMENT: "Pengurusan",
    HOLIDAY: "Cuti",
    COMPREGISTRATION: "Daftar Syarikat",
    TEACHER: "Guru",
    STAFF: "Staf",
    SCHOOL: "Sekolah",
    EDU: { REGISTRATION: "Pendaftaran" },
    AWAY: "Ketiadaan",
    GROUP : "Kumpulan",
    LIST : "Senarai",
    ROLE : "Peranan",
    DETAIL : "Butiran",
    ENTITLEMENT : "Kelayakan",
    ASSIGNMENT : "Pengagihan",
    APPROVAL : "Pengesahan Permohonan",
  },
};
