export default {
  SIDEBAR: {
    DASHBOARD: "Dashboard",
    COMPANY: "Company",
    SETTINGS: "Settings",
    HOME: "Home",
    EMPLOYEE: "Employee",
    DEPARTMENT: "Department",
    ATTENDANCE: "Attendance",
    LEAVE: "Leave",
    DEVICE: "Device",
    REGISTRATION: "Profile",
    FACIAL: "Facial",
    MANAGEMENT: "Management",
    HOLIDAY: "Holidays",
    COMPREGISTRATION: "Company Registration",
    TEACHER: "Teacher",
    STAFF: "Staff",
    SCHOOL: "School",
    EDU: { REGISTRATION: "Registration" },
    AWAY: "Away",
    GROUP : "Group",
    LIST:"List",
    ROLE : "Role",
    DETAIL : "Detail",
    ENTITLEMENT : "Entitlement",
    ASSIGNMENT : "Assignment",
    APPROVAL : "Approval",


  },
};
