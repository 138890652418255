export default {
  DEPARTMENT: {
    HEADER: "Jabatan",
    SUBTITLE: "Senarai Jabatan Syarikat",
    BUTTON: "Tambah",
    DAILY: "Harian",
    MONTHLY: "Bulanan",
  },

  DEPARTMENTFORM: {
    HEADER: "Tambah Jabatan Baru",
    FORM: "Butiran Jabatan",
    NAME: "Nama Jabatan",
    DESCRIPTION: "Penerangan",
    DATECREATED: "Tarikh Penubuhan Jabatan",
    NAME1: "Masukkan Nama Jabatan",
    DESCRIPTION1: "Penerangan Tentang Jabatan",
  },
};
