import List from "./company_leave_entitlement_list";
import Employee from "./employee/employee_entitlement.store";

const state = {};

const actions = {};

const mutations = {};

const getters = {};

export default {
  modules: {
    List,
    Employee,
  },
  state,
  actions,
  mutations,
  getters,
};
