export default {
  GROUP: {
    EMPLOYEEGROUP: "Kumpulan Pekerja",
    CREATEGROUP: "Cipta Kumpulan",
    CONF: "Konfigurasi Kehadiran",
    TIMEIN: "Waktu Masuk",
    TIMECUTIN: "Time In Cut-In",
    TIMEOUT: "Waktu Keluar",
    TIMECUTOUT: "Time Out Cut-Off",
  },
};
