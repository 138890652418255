export default {
  /**
   * Set the Staff List items
   *
   * @param state
   * @param items
   */
  setFDList(state, items) {
    state.items = items;
  },

  /**
   * Set Staff List pagination meta
   *
   * @param state
   * @param pagination
   */
  setFDListPagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   * Set Staff List current page
   *
   * @param state
   * @param currentPage
   */
  setFDListPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   * Set filters.
   *
   * @param state
   * @param filters
   */
  setFDListFilters(state, filters) {
    console.log(filters);

    state.filters = filters;
  },
};
