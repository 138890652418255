import ApiService from "@/core/services/api.service";

const actions = {
  /**
   * Delete teacher in facial device
   * @param context
   * @param payload
   * @returns {*}
   */
  deleteTeacherFacialDevice(context, payload) {
    console.log(payload);
    ApiService.setHeader();
    return ApiService.post("school/device/facial/teacher/delete", payload).then(
      (response) => response
    );
  },
};

export default {
  actions,
};
