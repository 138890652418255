export default {
  /**
   * Set the list.
   *
   * @param state
   * @param items
   */
  setTeacherIndividualEntitlementList(state, items) {
    console.log("Set items", items);
    state.items = items;
  },

  /**
   *
   * @param state
   * @param pagination
   */
  setTeacherIndividualEntitlementListPagination(state, pagination) {
    console.log("Pagination ", pagination);
    state.pagination = pagination;
  },

  /**
   * Set Teacher individual entitlement form.
   *
   * @param state
   * @param form
   */
  setTeacherIndividualEntitlementForm(state, form) {
    console.log("Form ", form);
    state.form = form;
    state.form.employee_id = form.teacher_id;
  },

  /**
   * Set the list.
   *
   * @param state
   * @param items
   */
  setStaffIndividualEntitlementList(state, items) {
    console.log("Set items", items);
    state.items = items;
  },

  /**
   *
   * @param state
   * @param pagination
   */
  setStaffIndividualEntitlementListPagination(state, pagination) {
    console.log("Pagination ", pagination);
    state.pagination = pagination;
  },

  /**
   *
   * @param state
   * @param page
   */
  setStaffIndividualEntitlementListPage(state, page) {
    console.log("Pagination ", page);
    state.pagination.current_page = page;
  },

  /**
   * Set Staff individual entitlement form.
   *
   * @param state
   * @param form
   */
  setStaffIndividualEntitlementForm(state, form) {
    console.log("Form ", form);
    state.form = form;
    state.form.employee_id = form.staff_id;
  },
};
