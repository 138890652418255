import actions from "./actions";
import mutations from "./mutations";
import state from "./state";

const getters = {
  getTeacherDailyAttendanceList: (state) => state.items,
  getTeacherDailyAttendancePagination: (state) => state.pagination,
  getTeacherDailyAttendanceBusy: (state) => state.isBusy,
  getTeacherDailyAttendanceDate: (state) => state.filters.date,
};

export default {
  getters,
  actions,
  mutations,
  state,
};
