import ApiService from "@/core/services/api.service";
import state from "./state";

export default {
  /**
   * Fetch Employee Yearly Attendance
   *
   * @param context
   * @param payload
   */
  fetchTeacherIndividualEmployeeYearlyAttendanceList(context, payload) {
    ApiService.setHeader();
    return ApiService.query("school/attendance/teacher/yearly/individual", {
      params: {
        id: payload.id,
        date: state.filter.date,
      },
    }).then((res) => {
      console.log("Received ", res);
      context.commit(
        "setTeacherIndividualEmployeeYearlyAttendanceList",
        res.data.data
      );

      return res;
    });
  },

  /**
   * Change list filter date
   *
   * @param context
   * @param payload
   */
  changeTeacherEmployeeYearlyAttendanceListDate(context, payload) {
    const date = payload.date;
    if (date !== null) {
      context.commit("setTeacherIndividualEmployeeYearlyAttendanceDate", date);
    }
  },
};
