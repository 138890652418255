import ApiService from "@/core/services/api.service";

const state = {
  list: [],
  group: {},
  isCleared: false,
  dayReferences: [
    {
      id: 1,
      name: "monday",
    },
    {
      id: 2,
      name: "tuesday",
    },
    {
      id: 3,
      name: "wednesday",
    },
    {
      id: 4,
      name: "thursday",
    },
    {
      id: 5,
      name: "friday",
    },
    {
      id: 6,
      name: "saturday",
    },
    {
      id: 7,
      name: "sunday",
    },
  ],
};

const actions = {
  /**
   * Fetch group schedule using GROUP ID.
   *
   * @param context
   * @returns {*}
   */
  fetchTeacherGroupSchedule(context) {
    ApiService.setHeader();
    return ApiService.get(
      "school/group/schedule",
      "?id=" + state.group.id
    ).then((res) =>
      context.commit("setTeacherGroupScheduleList", res.data.data.schedule)
    );
  },

  /**
   * Set the list to empty.
   *
   * @param context
   */
  clearTeacherGroupScheduleList(context) {
    context.commit("setTeacherGroupScheduleList", undefined);
    context.commit("setTeacherGroupIsCleared", true);
  },

  /**
   * Revert the list to empty.
   *
   * @param context
   */
  revertTeacherGroupScheduleList(context) {
    context.commit("setTeacherGroupIsCleared", false);
  },
};

const mutations = {
  /**
   * Set group.
   *
   * @param state
   * @param group
   */
  setTeacherGroupEdit(state, group) {
    console.log("Selected group", group);
    state.group = group;
  },

  /**
   * Set clear status.
   *
   * @param state
   * @param cleared
   */
  setTeacherGroupIsCleared(state, cleared) {
    console.log("Is Cleared ", cleared);
    state.isCleared = cleared;
  },

  /**
   * Set group schedule list.
   * @param state
   * @param data
   */
  setTeacherGroupScheduleList(state, data) {
    console.log("Received schedule data", data);
    state.list = data;
    console.log("Current schedule list", state.list);
  },
};

const getters = {
  getTeacherGroupSchedule: (state) => state.list,
  getTeacherGroupScheduleForm: (state) => {
    if (!state.list) return null;

    let form = {};

    if (!state.list.length) return null;

    // Set similar days
    state.list.forEach((list) => {
      state.dayReferences.some((day) => {
        if (day.id === list.id) {
          form[day.name] = {
            day_type: list.day_status_id,
            time_in: list.time_in,
            time_out: list.time_out,
          };
        }
      });
    });

    // Then set different days compared to fetched form
    state.dayReferences.forEach((day) => {
      state.list.some((list) => {
        console.log(day.id, list.id);
        if (day.id !== list.id && form[day.name] == null) {
          console.log("Form is empty for this day");
          form[day.name] = {
            day_type: null,
            time_in: "",
            time_out: "",
          };
        }
      });
    });

    console.log("Form return");
    return form;
  },
  getTeacherGroupIsCleared: (state) => state.isCleared,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
