export default {
  /**
   * Set the Staff List items
   *
   * @param state
   * @param items
   */
  setSchoolHolidayList(state, items) {
    state.items = items;
  },

  /**
   * Set Staff List pagination meta
   *
   * @param state
   * @param pagination
   */
  setSchoolHolidayListPagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   * Set Staff List current page
   *
   * @param state
   * @param currentPage
   */
  setSchoolHolidayListPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   * Set filters to state
   *
   * @param state
   * @param filters
   */
  setSchoolHolidayListFilters(state, filters) {
    console.log("Filters : ", filters);

    state.filters = filters;
  },
};
