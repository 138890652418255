import dayjs from "dayjs";

export default {
  items: [],
  pagination: {},
  size: 15,
  isBusy: false,
  date_type: 1,
  filter: {
    date: dayjs().format(),
    from_date: null,
    to_date: null,
    first_name: null,
  },
};
