import CompanyLeaveTypeStore from "./types/company_leave_type.store";
import CompanyLeaveEntitlementStore from "@/modules/company/stores/leave/entitlement/company_leave_entitlement.store";
import CompanyLeaveApplication from "@/modules/company/stores/leave/company_leave_application";
import getCompanyLeaveStatistics from "@/modules/company/stores/leave/statistics/company_leave_statistics";

export default {
  modules: {
    CompanyLeaveTypeStore,
    CompanyLeaveEntitlementStore,
    CompanyLeaveApplication,
    getCompanyLeaveStatistics,
  },
};
