import ApiService from "@/core/services/api.service";

const state = {};

const actions = {
  /**
   * @param context
   * @param payload
   * @returns {*}
   */
  saveCompanyPositionsForm(context, payload) {
    ApiService.setHeader();
    return ApiService.post("company/settings/positions", payload);
  },
  fetchPositionSettingsForm() {
    console.log("Fetching position settings detail", state.employeeId);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(
        "employee/detail/contact",
        "?employee_id=" + state.employeeId
      )
        .then((response) => {
          console.log(response);
          // context.commit("setEmployeeContactDetailsForm", response);
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
};

const mutations = {};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
