import i18n from "@/core/plugins/vue-i18n";

export default [
  {
    text: i18n.t("SIDEBAR.DEVICE"),
    type: "menu-nav-parent",
    guard: {
      permissions: ["view.manage.school"],
    },
    url: {
      path: "device/facial",
    },
    icon: "/media/svg/icons/Devices/Generator.svg",
    children: [
      {
        text: i18n.t("DEVICE.FACIAL.PAGE.HEADER"),
        type: "menu-nav-parent",
        url: {
          name: "facialDeviceBase",
        },
        guard: {
          permissions: ["view.manage.school"],
        },
        children: [],
        icon: "/media/svg/icons/Home/Building.svg",
      },
    ],
  },
];
