import ApiService from "@/core/services/api.service";

const actions = {
  /**
   *
   * @param context
   * @param payload
   * @returns {Promise<*>}
   */
  async syncSchoolDevice(context, payload) {
    ApiService.setHeader();
    return await ApiService.post("school/device/facial/synchronize", payload);
  },
};

export default {
  actions,
};
