export default {
  /**
   * Set the Staff List items
   *
   * @param state
   * @param items
   */
  setSchoolAwayDetailList(state, items) {
    state.items = items;
  },

  /**
   * Set Staff List pagination meta
   *
   * @param state
   * @param pagination
   */
  setSchoolAwayDetailListPagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   * Set Staff List current page
   *
   * @param state
   * @param currentPage
   */
  setSchoolAwayDetailListPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   * Set state's filters
   *
   * @param state
   * @param filters
   */
  setSchoolAwayListFilters(state, filters) {
    state.filters = filters;
  },
};
