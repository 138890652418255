const DepartmentListPage = () =>
  import("@/modules/company/components/department/DepartmentPage");

export default [
  {
    path: "company/department/list",
    name: "departmentList",
    component: DepartmentListPage,
  },
];
