import ApiService from "@/core/services/api.service";

const state = {
  attendance: {},
};

const actions = {
  fetchEmployeePersonalAttendanceDaily(context) {
    ApiService.setHeader();
    return ApiService.get("school/personal/attendance/daily")
      .then((response) => {
        console.log("Personal Att Daily", response.data.data);
        context.commit(
          "setEmployeePersonalAttendanceDaily",
          response.data.data
        );
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const mutations = {
  setEmployeePersonalAttendanceDaily(state, data) {
    console.log("Set personal attendance daily ...");
    state.attendance = data;
  },
};

const getters = {
  getEmployeePersonalAttendanceDaily: (state) => state.attendance,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
