import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getSchoolHolidayList: (state) => state.items,
  getSchoolHolidayListPagination: (state) => state.pagination,
  getSchoolHolidayListPage: (state) => state.pagination.current_page,
  getSchoolHolidayListFilters: (state) => state.filters,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
