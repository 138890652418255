import actions from "./actions";
import mutations from "./mutations";
import state from "./state";

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
