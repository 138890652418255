import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getStaffGroupMemberNotInList: (state) => state.items,
  getStaffGroupMemberNotInListPagination: (state) => state.pagination,
  getStaffGroupMemberNotInListPage: (state) => state.pagination.current_page,
  getStaffGroupMemberNotInListFilters: (state) => state.filters,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
