import MemberShipList from "./group_membership_list.store";

const state = {
  id: null,
  group: {
    id: null,
    name: null,
    description: null,
  },
};

const actions = {
  assignGroupMembershipId(context, id) {
    context.commit("setGroupMembershipId", id);
  },
  specifyGroup(context, group) {
    console.log("Assigning Group", group);
    context.commit("setGroup", group);
  },
};

const mutations = {
  setGroupMembershipId(state, id) {
    state.id = id;
  },
  setGroup(state, group) {
    state.group = group;
    console.log(state.group);
  },
};

const getters = {
  fetchGroup: (state) => state.group,
};

export default {
  state,
  actions,
  mutations,
  getters,
  modules: { MemberShipList },
};
