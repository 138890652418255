import ApiService from "@/core/services/api.service";

const state = {};

const actions = {
  changeEmailAddress(context, email) {
    ApiService.setHeader();
    return ApiService.post("auth/account/email", { email });
  },
  changeExistingPassword(context, form) {
    ApiService.setHeader();
    return ApiService.post("auth/account/password", form);
  },
};

const mutations = {};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
