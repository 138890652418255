export default {
  /**
   *
   * @param state
   * @param leave
   */
  setEntitledLeaveApplicationModal(state, leave) {
    console.log("Hmm", leave);
    state.leave = leave;
  },

  /**
   *
   * @param state
   * @param items
   */
  setPersonalLeaveApplicationList(state, items) {
    console.log(items);

    state.items = items;
  },

  /**
   * Set personal leave application list pagination.
   *
   * @param state
   * @param pagination
   */
  setPersonalLeaveApplicationListPagination(state, pagination) {
    console.log(pagination);

    state.pagination = pagination;
  },

  /**
   * Set personal leave application list current page.
   *
   * @param state
   * @param page
   */
  setPersonalLeaveApplicationListPage(state, page) {
    console.log(page);

    state.pagination.current_page = page;
  },
};
