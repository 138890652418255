export default {
  REGISTER: {
    HAVE: " Already have an account?",
    ENTER: "Enter your details to create your account.",
    REQUIRED: "Name is required.",
    REQUIRED2: "Email is required and a valid email address.",
    REQUIRED3: "Work Email is required.",
    NUMBER: "Numbers only.",
    SIZE: "Company Size",
    WORK: "Work Email",
    EMPLOYER: "Employer",
  },
};
