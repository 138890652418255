import ApiService from "@/core/services/api.service";

const state = {
  verificationCode: "",
  verify: null,
  busy: false,
  organisation: {},
};

const actions = {
  assignVerificationCode(context, code) {
    console.log(context);
    console.log(code);
    context.commit("setVerificationCode", code);
  },
  getEmployeeVerification(context, code) {
    return new Promise((resolve, reject) => {
      state.busy = true;
      ApiService.setHeader();
      ApiService.get("employee/check", "?verification_code=" + code)
        .then((response) => {
          context.commit("setEmployeeVerification", response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          console.error(error);
        })
        .finally(() => (state.busy = false));
    });
  },
  fetchUserOrganisation(context) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("employee/verify")
        .then((response) => {
          context.commit("setOrganisationVerification", response);
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
};

const mutations = {
  setVerificationCode(state, code) {
    state.verificationCode = code;
    console.log("Finish assigning verification %s code", code);
  },
  setEmployeeVerification(state, response) {
    console.log(response.data);
    state.verify = response.data;
  },
  setOrganisationVerification(state, response) {
    state.organisation = response.data;
  },
};

export default {
  state,
  actions,
  mutations,
};
