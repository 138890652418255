export default [
  {
    path: "school/leave/application",
    name: "schoolLeaveApplication",
    component: () =>
      import(
        "../../../components/management/leave/application/SchoolEmployeeApplicationPage"
      ),
    children: [
      {
        path: "teacher",
        name: "schoolLeaveApplication",
        component: () =>
          import(
            "../../../components/management/leave/application/teacher/SchoolTeacherEmployeeApplication"
          ),
      },
      {
        path: "staff",
        name: "staffLeaveApplication",
        component: () =>
          import(
            "../../../components/management/leave/application/staff/SchoolStaffApproval"
          ),
      },
    ],
  },
];
