import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

const getters = {
  getStaffArchivedList: (state) => state.items,
  getStaffArchivedPagination: (state) => state.pagination,
  getStaffArchivedListPage: (state) => state.pagination.current_page,
  getStaffArchivedListFilters: (state) => state.filters,
  getStaffArchivedListBusy: (state) => state.isBusy,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
