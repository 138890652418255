export default {
    EDULEAVE: {
        NAME : "Nama",
        LEAVELIST : "Senarai Jenis Cuti",
        DETAILS: "Butiran",
        ENTITLE: "Kelayakan Cuti",
        APPROVAL: "Pengesahanan Permohonan",
        STAT: "Statistik",
        LEAVEDETAILS: "Tambah Jenis Cuti",
        LEAVETYPE: "Butiran Cuti",
        TYPE: "Jenis Cuti",
        ENTITLEMENT: "Kelayakan Cuti",
        APPLICATION: "Pengesahan Permohonan",
        STATISTICS: "Statistik",
        LEAVEAPPLICATION: "Permohonan Cuti",
        EMPLEAVE: "Cuti Pekerja",
        APP: "Permohonan",
        APPSTATUS:"Status Permohonan",
        INDIVIDUAL: {
            TITLE: "Cuti Pekerja",
            STAFF_TITLE: "Cuti Pekerja",
            TEACHER: "Cuti Guru",
        },
    },
    DETAILSTABLE: {
        TYPE: "JENIS CUTI",
        DESC: "PENERANGAN",
        UNIT: "UNIT CUTI",
        COUNT: "KIRAAN HARI",
        PAID: "CUTI BERBAYAR",
        ACT: "TINDAKAN",
    },
    DETAILSFORM: {
        TYPE: "Jenis Cuti",
        TYPE1: "Masukkan Jenis Cuti",
        DESC: "Penerangan",
        DESC1: "(200 karakter maksimum)",
        UNIT: "Unit Cuti",
        COUNT: "Kiraan Hari",
        PAID: "Cuti Berbayar?",
    },
    ENTITLEMENT: {
        CREATE: "Tambah Kelayakan Cuti",
        DETAILS: "Butiran Kelayakan Cuti",
        TYPE: "Jenis Cuti",
        DAYS: "Bilangan Hari Kelayakan Cuti",
        OVER: "Carried Over (in malay)",
        VALID: "Sah Sehingga",
        BALANCE: "Baki",
        REASON: "Alasan",
        PENDING: "Belum Diproses",
        BALANCE1: "Baki cuti",
        REASON1: "Masukkan Alasan",
        DAY: "Hari",
        SESSION: "Sesi",
        ATTACHMENT: "Lampiran",
        PURPOSE: "Untuk Kegunaan Pengurusan",
        APPLICATION: "Tarikh Permohonan",
        SESSION1: "Masukkan Sesi",
        APPROVE: "Sahkan",
        ATTACHMENT1: "Pilih Fail",
    },
    STATISTICS: {
        WEEK: "Minggu",
        PENDING: "Belum Diproses",
        APPROVED: "Disahkan",
        TOTAL: "Jumlah",
        WEEK1: "Minggu :",
        NEW: "Permohonan Baru",
        MONTH: "Bulan",
        YEAR: "Tahun",
    },
    AWAY: {
        INDIVIDUAL: "Status Tiada di tempat kerja",
        PERSONAL: {
            TITLE: "Maklumat Ketiadaan Anda",
        },
    },
};
