export default {
  /**
   * Set Personal Leave entitlement List.
   *
   * @param state
   * @param data
   */
  setPersonalLeaveEntitlementList(state, data) {
    state.items = data;
  },

  /**
   * Set Personal Leave entitlement Pagination.
   *
   * @param state
   * @param pagination
   */
  setPersonalLeaveEntitlementListPagination(state, pagination) {
    state.pagination = pagination;
  },

  /**
   * Set Personal Leave entitlement Page.
   *
   * @param state
   * @param currentPage
   */
  setPersonalLeaveEntitlementListPage(state, currentPage) {
    state.pagination.current_page = currentPage;
  },

  /**
   * Set Personal Leave entitlement Page busy state.
   *
   * @param state
   * @param isBusy
   */
  setPersonalLeaveEntitlementListBusy(state, isBusy) {
    state.isBusy = isBusy;
  },

  /**
   * Set personal leave entitlement form.
   *
   * @param state
   * @param form
   */
  setPersonalLeaveEntitlementForm(state, form) {
    console.log("Form : ", form);

    state.form = form;
  },
};
