export default {
  GENERAL: {
    OR: "Or",
    SUBMIT_BUTTON: "Submit",
    NO_ACCOUNT: "Don't have an account?",
    SIGNUP_BUTTON: "Sign Up",
    FORGOT_BUTTON: "Forgot Password",
    BACK_BUTTON: "Back",
    PRIVACY: "Privacy",
    LEGAL: "Legal",
    CONTACT: "Contact",
  },
};
