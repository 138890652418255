import Edit from "./employee_entitlement_edit_form.store";
import List from "./company_leave_entitlement_list.store";
import ApiService from "@/core/services/api.service";

const state = {
  employee: {},
  leave: {},
  isBusy: false,
};

const actions = {
  /**
   *
   * @param context
   * @param employee
   */
  assignSelectedEntitlementForEmployee(context, employee) {
    context.commit("setSelectedEntitlementForEmployee", employee);
  },

  /**
   * Fetch a single company leave record
   *
   * @param context
   * @param payload is ID of Company's Leave
   */
  fetchSingleCompanyLeave(context, payload) {
    context.commit("setSingleCompanyLeaveBusy", true);
    ApiService.setHeader();
    return ApiService.query("company/leave/single", {
      params: {
        id: payload,
      },
    }).then((response) => {
      context.commit("setSingleCompanyLeave", response.data.data);
      context.commit("setSingleCompanyLeaveBusy", false);
    });
  },
};

const mutations = {
  /**
   *
   * @param state
   * @param employee
   */
  setSelectedEntitlementForEmployee(state, employee) {
    state.employee = employee;
  },

  /**
   * Set Company Leave State
   *
   * @param state
   * @param leave
   */
  setSingleCompanyLeave(state, leave) {
    console.log("Setting leave", leave);
    state.leave = leave;
  },

  /**
   *
   * @param state
   * @param busy
   */
  setSingleCompanyLeaveBusy(state, busy) {
    state.isBusy = busy;
  },
};

const getters = {
  getSelectedEntitlementForEmployee: (state) => state.employee,
  getSingleCompanyLeave: (state) => state.leave,
  getSingleCompanyLeaveBusy: (state) => state.isBusy,
};

export default {
  modules: {
    Edit,
    List,
  },
  state,
  actions,
  mutations,
  getters,
};
