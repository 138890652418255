import SchoolTeacherListRoute from "./list/teacher_list_router";

const TeacherList = () =>
  import("@/modules/school/components/teacher/SchoolTeacherPage");
const TeacherGroup = () =>
  import("@/modules/school/components/teacher/group/TeacherGroupPage");
const TeacherAdmin = () =>
  import("@/modules/school/components/teacher/admin/TeacherAdminPage");

export default [
  {
    path: "/school/teacher/list",
    component: TeacherList,
    children: [...SchoolTeacherListRoute],
  },
  {
    path: "/school/teacher/group",
    name: "teacherGroup",
    component: TeacherGroup,
  },
  {
    path: "/school/teacher/role",
    name: "teacherAdmin",
    component: TeacherAdmin,
  },
];
