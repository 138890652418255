const EmployeeAttendancePage = () =>
  import("@/modules/company/components/attendance/EmployeeAttendancePage");

export default [
  {
    path: "company/employee/attendance",
    name: "employeeAttendance",
    component: EmployeeAttendancePage,
  },
];
