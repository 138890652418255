import actions from "./actions";
import mutations from "./mutations";
import state from "./state";

const getters = {
  getStaffList: (state) => state.items,
  getStaffListPagination: (state) => state.pagination,
  getStaffListPage: (state) => state.pagination.current_page,
  getStaffListBusy: (state) => state.isBusy,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
