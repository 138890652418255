export default {
  EMPLOYEEFORM: {
    EMPLOYEEDETAILS: "Butiran Pekerja",
    NAME: "Masukkan Nama",
    LASTNAME: "Masukkan Nama Keluarga",
    EMAIL: "Masukkan Emel",
    UNIQUECODE: "Masukkan Kod Unik",
    STARTDATE: "Masukkan Tarikh Mula Bekerja",
    ADD: "Masukkan Alamat",
    LOCALITY: "Masukkan Kerakyatan",
    TOWN: "Masukkan Bandar",
    POSTALCODE: "Masukkan Poskod",
    EMERGENCYNUMBER: "Masukkan Nombor Telefon Kecemasan",
    EMERGENCYCONTACT: "Masukkan Nama Kenalan",
    PERSONAL: "Peribadi",
    CREATE: "Tambah Pekerja Baru",
  },

  EMPLOYEE_CREATE_FORM: {
    FIRSt_NAME: "AA",
  },
};
