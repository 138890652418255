import GROUP from "./group.ms";
import DETAILS from "./details.ms"
import LEAVE from "./leave.ms";
import FORM from "./form.ms";
import GENERAL from "./general.ms";

export default {
  TEACHER: {
    HEADER: "Guru",
    SUBTITLE: "Senarai Guru",
    BUTTON: "Tambah",
    EMP: "Nama Guru",
    TOOLTIP: {
      EDIT: "Kemaskini maklumat Guru",
      DELETE: "Padam {teacher}",
    },
  },

  STAFF: {
    HEADER: "Kakitangan",
    SUBTITLE: "Senarai Kakitangan",
    BUTTON: "Tambah",
    EMP: "Nama Kakitangan",
    TOOLTIP: {
      EDIT: "Kemaskini maklumat Kakitangan",
      DELETE: "Padam {teacher}",
    },
  },

  USERS: {
    DETAILS: {
      CONTACT: "Kenalan",
      GENERAL: "Umum",
      ADDRESS: "Alamat",
      RESET: "Tetap Semula",
      SUBMIT: "Simpan",
    },
  },

  EDUTABLE: {
    FULLNAME: "Nama",
    DEPARTMENTNAME: "Nama Jabatan",
    ACTIONS: "Tindakan",
  },

  SUMMARY: {
    SUMMARY: "Catatan",
    SUMMARY1: "Masukkan Catatan Pekerja",
  },

  POSITIONS: {
    NAME: "Masukkan Nama Jawatan",
    CODE: "Masukkan Kod",
    DETAILS: "Penerangan Jawatan",
  },
  SCHOOLDETAILS:
      {
        DETAILS:
            {
              CODE : "Kod",
              NAME : "Nama Sekolah",
              EMAIL : "E-mel",
              CONTACTNO : "Nombor Telefon",
              RESET: "Tetapkan Semula",
              SUBMIT: "Simpan",
            },
        MESSAGE:
            {
              SCHOOLDETAILS : "Maklumat Sekolah",
              MES1: "Maklumat Sekolah Boleh diubah disini",
              MES2: "Perubahan mungkin memerlukan sedikit masa untuk diproses. Terima kasih atas kesabaran anda.",

            },
      },

  ...GROUP,
  ...DETAILS,
  ...LEAVE,
  ...FORM,
  ...GENERAL,
};
