import CompanyLeaveRoute from "@/modules/company/routers/leave/company_leave.router";

const EmployeeList = () =>
  import("@/modules/company/components/employees/EmployeePage");
const EmployeeGroup = () =>
  import("@/modules/company/components/group/EmployeeGroupPage");

export default [
  {
    path: "/company/employee/list",
    name: "employeeList",
    component: EmployeeList,
  },
  {
    path: "/company/employee/group",
    name: "employeeGroup",
    component: EmployeeGroup,
  },
  ...CompanyLeaveRoute,
];
