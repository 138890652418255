import UserService from "@/modules/auth/services/user.service";

const state = {
  profile: {},
};

const actions = {
  fetchAuthProfile(context) {
    return new Promise((resolve) => {
      UserService.getAuth().then((profile) => {
        console.log("Info is", profile);
        context.commit("setAuthProfile", profile);
        resolve(profile);
      });
    });
  },
};

const mutations = {
  setAuthProfile(state, profile) {
    profile.onsuccess = () => {
      console.log("Profile is", profile.result[0]);
      state.profile = profile.result[0];
    };
  },
};

const getters = {
  getUserAuthProfile() {
    console.log("Retrieving user auth profile");

    return new Promise((resolve) => {
      UserService.getAuth().then((r) => {
        console.log("Info is", r);
        resolve(r);
      });
    });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
