import ApiService from "@/core/services/api.service";

const state = {
  employee: {},
  date: "",
  toSave: 0,
  isBusy: false,
  isSaved: false,
};

const actions = {
  assignDailyEmployeeAttendance(context, payload) {
    console.log(payload.date);
    context.commit("setDailyEmployeeAttendanceModal", payload.data);
    context.commit("setDateEmployeeAttendance", payload.date);
  },
  updateEmployeeAttManageForm(context, form) {
    context.commit("holdEmpAttStatusEditBusy");
    ApiService.setHeader();
    return ApiService.post("employee/attendance/admin/status", form)
      .then(() => {
        console.log("Updated ", form);
        context.commit("setDailyEmployeeAttendanceFormState", true);
      })
      .finally(() => context.commit("holdEmpAttStatusEditBusy"));
  },
  engageUpdateEmployeeAttManageForm(context) {
    let count = state.toSave;
    console.log(count);
    context.commit("setUpdateEmpAttManageFormSubmission", ++count);
  },
};

const mutations = {
  setDailyEmployeeAttendanceModal(state, data) {
    state.employee = data;
    console.log("Current State is", state.employee);
  },
  setUpdateEmpAttManageFormSubmission(context, saveState) {
    state.toSave = saveState;
  },
  holdEmpAttStatusEditBusy(state) {
    state.isBusy = !state.isBusy;
  },
  setDateEmployeeAttendance(state, data) {
    state.date = data;
  },
  /**
   * Set the state of the form.
   *
   * @param state | Boolean
   * @param value
   */
  setDailyEmployeeAttendanceFormState(state, value) {
    state.isSaved = value;
    console.log("Is Saved State", state.isSaved, value);
  },
};

const getters = {
  getAssignedDailyEmployeeAttendance: (state) => state.employee,
  getHeldEmpEditAttStatus: (state) => state.isBusy,
  checkUpdateEmpAttStatusEvent: (state) => state.toSave,
  getDateEmployeeAttendance: (state) => state.date,
  getDailyEmployeeAttendanceFormState: (state) => state.isSaved,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
