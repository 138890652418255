export default {
  ACCOUNT: {
    CHANGEEMAIL: "Change Email",
    CHANGEEMAIL1: "New Email Address",
    CHANGEEMAIL2: "Enter New Email Address",
    CHANGEPASS: "Change Password",
    ENTERPASS1: "Enter Current Password",
    ENTERPASS2: "Enter New Password",
    ENTERPASS3: "Enter To Verify New Password",
    PASS1: "Current Password",
    PASS2: "Verify Password",
    PASS3: "New Password",
    ALERT:
      "To change current password, enter your desired new password along with existing password and click ",
    ALERT2: "To change current email, enter your desired new email and click ",
  },
};
