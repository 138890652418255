import state from "./state";
import actions from "./actions";
import mutations from "./mutations";

const getters = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
