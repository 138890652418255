import state from "./state";
import ApiService from "@/core/services/api.service";

export default {
  /**
   * For staff list in selected device
   * @param context
   * @param payload
   * @returns {*}
   */
  fetchFDStaffListIn(context, payload) {
    ApiService.setHeader();
    return ApiService.query("school/device/facial/staff", {
      params: {
        id: payload.id,
        "page[size]": 10,
        "page[number]": state.pagination.current_page ?? 1,
      },
    }).then((res) => {
      context.commit("setFDStaffList", res.data.data);
      context.commit("setFDStaffListPagination", res.data.meta.pagination);
      return res;
    });
  },

  /**
   * For staff list not in selected device
   * @param context
   * @param payload
   * @returns {*}
   */
  fetchFDStaffListNotIn(context, payload) {
    ApiService.setHeader();
    return ApiService.query("school/device/facial/staff/not", {
      params: {
        id: payload.id,
        "page[size]": 10,
        "page[number]": state.pagination.current_page ?? 1,
      },
    }).then((res) => {
      context.commit("setFDStaffList", res.data.data);
      context.commit("setFDStaffListPagination", res.data.meta.pagination);
      return res;
    });
  },

  /**
   *
   * @param context
   * @param payload
   */
  changeStaffSelectedStatus(context, payload) {
    const index = state.items.findIndex((e) => e.id === payload.id);
    const currentState = payload.currentState;

    context.commit("setStaffSelectedState", { currentState, index });

    console.log(
      "Was Changed: ",
      state.items[index].id,
      state.items[index].selected
    );
  },
};
