export default {
  /**
   * Set Teacher application approval list.
   *
   * @param state
   * @param items
   */
  setStaffAwayApplicationApprovalList(state, items) {
    console.log("Items: ", items);
    state.items = items;
  },

  /**
   * Pagination.
   *
   * @param state
   * @param pagination
   */
  setStaffAwayApplicationApprovalListPagination(state, pagination) {
    console.log("Pagination: ", pagination);
    state.pagination = pagination;
  },

  /**
   * Set current page.
   *
   * @param state
   * @param page
   */
  setStaffAwayApplicationApprovalListPage(state, page) {
    state.pagination.current_page = page;
  },

  /**
   *
   * @param state
   * @param isBusy
   */
  setStaffAwayApplicationApprovalListBusy(state, isBusy) {
    state.isBusy = isBusy;
  },
};
