import ApiService from "@/core/services/api.service";

const state = {
  form: {},
  isBusy: false,
};

const actions = {
  /**
   * Update the selected facial device.
   *
   * @param context
   * @param form is the Payload
   */
  updateFacialDeviceForm(context, form) {
    ApiService.setHeader();
    return ApiService.post("device/facial/update", form).then((response) => {
      console.log("Received:", response);
      return response;
    });
  },

  /**
   * Fetch Facial Device form.
   *
   * @param context
   * @param payload is The
   */
  fetchFacialDeviceForm(context, payload) {
    ApiService.setHeader();
    context.commit("setFacialDeviceFormBusy", true);
    return ApiService.query("device/facial/form", {
      params: {
        id: payload,
      },
    })
      .then((response) => {
        context.commit("setFacialDeviceForm", response.data.data);
      })
      .finally(() => context.commit("setFacialDeviceFormBusy", false));
  },
};

const mutations = {
  setFacialDeviceForm(state, data) {
    state.form = data;
  },

  setFacialDeviceFormBusy(state, isBusy) {
    state.isBusy = isBusy;
  },
};

const getters = {
  getFacialDeviceForm: (state) => state.form,
  getFacialDeviceFormBusy: (state) => state.isBusy,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
