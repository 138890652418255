export default {
  FAMILY: {
    FAMILY: "Keluarga",
    MARITAL: "Status Perkahwinan",
    MARITAL1: "Masukkan Status Perkahwinan",
    NUMBER: "Bilangan Anak",
    NUMBER1: "Masukkan Bilangan Anak",
    SPOUSE: "Butiran Pasangan",
    NATIONALITY: "Kewarganegaraan",
    NATIONALITY1: "Masukkan Kewarganegaraan",
    NRIC: "Kad Pengenalan",
    NRIC1: "Masukkan Nombor Kad Pengenalan",
    PASSPORT: "Pasport",
    PASSPORT1: "Masukkan Pasport",
    CHILDREN: "Bilangan Anak",
    CHILDREN1: "Masukkan Bilangan Anak",
    CHILDREN2: "Butiran Anak",
    DETAILS: "Butiran Keluarga",
  },
};
