import ApiService from "@/core/services/api.service";

const state = {};
const actions = {
  /**
   * Create New Staff Form
   * @param context
   * @param payload
   * @returns {*}
   */
  createNewStaff(context, payload) {
    ApiService.setHeader();
    return ApiService.post("school/employee/staff", payload).then(
      (response) => response
    );
  },
  /**
   *
   * @param context
   * @param payload
   * @returns {*}
   */
  checkExistingStaffCode(context, payload) {
    ApiService.setHeader();
    return ApiService.query("school/employee/staff/code", {
      params: {
        code: payload.code,
        id: payload.id,
      },
    }).then((response) => response);
  },
};
const mutations = {};

export default {
  state,
  actions,
  mutations,
};
